import {
  ShippingMethodType,
  SubscriptionWithRenewal,
  billSubscriptionNow,
  useGetAllSubscriptionsForCustomer,
  useGetShippingMethods,
  useIsProcessing,
} from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';
import { Drawer, Loader } from 'rsuite';

import closeIcon from 'assets/svg/btn-close.svg';

import ProductBlock from './shipNow/ProductBlock';
import PromoCodeBlock from './shipNow/PromoCodeBlock';
import ShippingBlock from './shipNow/ShippingBlock';
import TotalsBlock from './shipNow/TotalsBlock';

import { showDashboardAlert } from 'components/core/Notification';

import { useAppSelector } from 'reducers/alloy_reducer';

interface Props {
  shipment: SubscriptionWithRenewal;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ShipNowDrawer({ shipment, open, setOpen }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedShippingType, setSelectedShippingType] = useState<ShippingMethodType>('STANDARD');

  const customer = useAppSelector((state) => state.alloy.customer!!);

  const { data: shippingMethods = [], isLoading: isLoadingShippingMethods } = useGetShippingMethods(
    customer.stateAbbr!!.toUpperCase()
  );

  const { mutate: mutateSubscriptions } = useGetAllSubscriptionsForCustomer();
  const { mutate: mutateIsProcessing } = useIsProcessing(shipment.stripeSubscriptionId);

  const mutate = async () => await Promise.all([mutateSubscriptions(), mutateIsProcessing()]);

  const foundSelectedShippingMethod = shippingMethods.find(
    (sm) => sm.method === selectedShippingType
  );

  useEffect(() => {
    const upcomingInvoice = shipment.upcomingInvoice;

    if (upcomingInvoice) {
      const foundShippingMethod = shippingMethods.find(
        (sm) => sm.priceInCents === upcomingInvoice.shipping
      );

      if (foundShippingMethod) {
        setSelectedShippingType(foundShippingMethod.method);
      }
    }
  }, [shippingMethods.length, isLoadingShippingMethods]);

  const onConfirm = async () => {
    try {
      setLoading(true);

      await billSubscriptionNow(shipment.stripeSubscriptionId, {
        shippingMethodId:
          selectedShippingType !== 'STANDARD' ? foundSelectedShippingMethod?.id : undefined,
      });

      await mutate();

      showDashboardAlert('Your next order has successfully been placed', 'success');

      setLoading(false);
      setOpen(false);
    } catch (error) {
      setLoading(false);
    }
  };

  if (!shipment.upcomingInvoice) return <></>;

  return (
    <Drawer
      open={open}
      size='xs'
      className='shipment-drawer ship-drawer'
      onClose={() => {
        if (!loading) setOpen(false);
      }}
    >
      <Drawer.Body>
        <div className='drawer-header'>
          <div className='header-item-left'></div>

          <p className='header-title'>Ship Now</p>

          <div className='header-item-right'>
            <button onClick={() => setOpen(false)} className='header-btn-close' disabled={loading}>
              <img src={closeIcon} alt='close icon' className='close-icon' />
            </button>
          </div>
        </div>

        {loading || isLoadingShippingMethods ? (
          <Loader center size='lg' />
        ) : (
          <>
            <div className='drawer-body'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    {shipment.products.map((pfAndRenewalAndPrescription, index) => (
                      <ProductBlock
                        pfAndRenewalAndPrescription={pfAndRenewalAndPrescription}
                        key={index}
                      />
                    ))}

                    <PromoCodeBlock
                      shipment={shipment}
                      onLoading={(isLoadingCoupon) => setLoading(isLoadingCoupon)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='drawer-footer'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <ShippingBlock
                      prevShippingMethodType={foundSelectedShippingMethod?.method}
                      shippingMethods={shippingMethods}
                      onSelectShipping={(sm) => setSelectedShippingType(sm.method)}
                    />

                    <TotalsBlock
                      upcomingInvoice={shipment.upcomingInvoice}
                      selectedShippingMethod={foundSelectedShippingMethod}
                    />

                    <button onClick={onConfirm} className='btn-confirm'>
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Drawer.Body>
    </Drawer>
  );
}
