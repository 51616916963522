import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Form } from 'rsuite';

import { login } from 'actions/auth/login_actions';

import arrowRight from 'assets/svg/core/arrows/arrow-right-white.svg';

import Layout from 'containers/Layout';

import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import Loader from 'components/core/Loader';
import { EmailField } from 'components/core/fields/EmailField';
import { PasswordField } from 'components/core/fields/PasswordField';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';

import { showErrorNotification } from 'components/core/Notification';
import { formatExperienceURL } from 'lib/core/url';

import { resendVerificationCode } from 'lib/core/awsAuth';

type LoginForm = {
  email: string;
  password: string;
};

export default function Login() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const INIT_FORM_VALUE = {
    email: '',
    password: '',
  };

  const [isLoading, setIsLoading] = useState(false);
  const [loginForm, setLoginForm] = useState<LoginForm>(INIT_FORM_VALUE);

  const dispatchLogin = bindActionCreators(login, dispatch);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const wasLoggedOut = searchParams.get('loggedOut');

    if (wasLoggedOut) {
      showErrorNotification("You've been logged out, please login again to continue");
    }
  }, [location.search]);

  // Submit the login and if needed to change password, submit the new password changed
  const onSubmit = async () => {
    window.scrollTo(0, 0);

    const { email, password } = loginForm;

    try {
      setIsLoading(true);

      /**
       * Reason for awaiting here is if any errors pop up in login then they will be added to the catch
       * even though await doesn't really have much of an effect except that...
       */
      await dispatchLogin(email, password, history, location);
    } catch (error: any) {
      setIsLoading(false);

      if (
        error.message &&
        (error.message.includes('PreAuthentication failed with error request failed.') ||
          error.message.includes('Bad credentials'))
      ) {
        showErrorNotification('The provided email address or password are incorrect.');
      } else if (error.message && error.message.includes('Password reset required for the user')) {
        history.push({
          pathname: 'reset',
          search: location.search,
          state: {
            email,
            resetReason: 'REQUIRED',
          },
        });
      } else if (error.message && error.message.includes('User is not confirmed')) {
        setIsLoading(true);
        showErrorNotification('Account verification required. Verification code resent.');

        await resendVerificationCode(email);

        history.push({
          pathname: 'standalone-verification',
          search: location.search,
          state: {
            email,
            password,
            context: 'UNCONFIRMED LOGIN',
          },
        });

        setIsLoading(false);
      } else {
        showErrorNotification(error.message);
      }
    }
  };

  return (
    <Layout title='Login - Alloy' desc='Log into your Alloy account' noBars>
      <TopBannerWithProgress />

      {isLoading ? (
        <Loader />
      ) : (
        <Form>
          <CheckoutExperienceSection>
            <h1 className='content-title'>Login</h1>

            <p className='content-text'>
              New here?{' '}
              <Link
                to={formatExperienceURL('/checkout-experience', location)}
                className='content-link'
              >
                Get started
              </Link>
            </p>

            <EmailField
              label='Email'
              name='email'
              value={loginForm.email}
              placeholder='Enter your email address'
              onChange={(value) => setLoginForm({ ...loginForm, email: value })}
            />

            <PasswordField
              label='Password'
              name='password'
              value={loginForm.password}
              placeholder='Enter your password'
              hideChecksSucceeded
              hideVisibleIcon
              onChange={(_, password) => setLoginForm({ ...loginForm, password })}
            />

            <p className='content-text'>
              <Link to={{ pathname: '/forgot', search: location.search }} className='content-link'>
                Forgot password?
              </Link>
            </p>

            <button
              className='primary-button full-width-button'
              type='submit'
              disabled={loginForm.email === '' || loginForm.password === ''}
              onClick={onSubmit}
            >
              Login
              <img src={arrowRight} alt='arrow right' className='btn-arrow' />
            </button>
          </CheckoutExperienceSection>
        </Form>
      )}
    </Layout>
  );
}
