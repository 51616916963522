import { EXPERIENCE_ADD_EXPECTATIONS_TESTING } from 'actions/types';
import { shouldAddExpectationsStep } from 'lib/core/abTests';
import { Dispatch } from 'redux';

const initABTesting = () => {
  return async (dispatch: Dispatch) => {
    // need this no matter what if user is logged in or not
    // MARK: test fetching from apps/dashboard/src/lib/core/abTests.ts
    // goes below along with dispatches for said tests

    const addExpectationsStep = shouldAddExpectationsStep();
    dispatch({ type: EXPERIENCE_ADD_EXPECTATIONS_TESTING, payload: addExpectationsStep });
  };
};

export { initABTesting };
