import classNames from 'classnames';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { useState } from 'react';

import checkedBox from 'assets/svg/core/icons/checked-box-icon.svg';
import uncheckedBox from 'assets/svg/core/icons/unchecked-box-icon.svg';

import ProductNameDosage from 'components/checkout-experience/products/ProductNameDosage';
import ProductInfoDrawer from 'components/core/drawers/ProductInfoDrawer';

import { getMhtShortName } from 'lib/dashboard/treatmentPlan';
import { getPriceForCombinedProducts } from 'lib/shared/product';
import { getProductNameWithDosage } from 'lib/shared/product/productNameWithDosage';

interface Props {
  groupedProducts: GroupedContentfulProduct[];
  prevSelectedProduct: GroupedContentfulProduct;
  onSelect: (gcp: GroupedContentfulProduct) => void;
  onSwitch: (selected: GroupedContentfulProduct, gcp: GroupedContentfulProduct) => void;
  isSelected: boolean;
}

// we need to either select one WHOLE mht product or select a specific one, deselecting outward removes it, selecting outward adds it, select inward adds it
export default function SelectableOptionProduct({
  groupedProducts,
  prevSelectedProduct,
  onSelect,
  onSwitch,
  isSelected,
}: Props) {
  const [showProdInfo, setShowProdInfo] = useState<boolean>(false);

  const productsCleanNameAndDosage = prevSelectedProduct
    ? getProductNameWithDosage(prevSelectedProduct)
    : [];
  const productPrice = prevSelectedProduct
    ? `$${getPriceForCombinedProducts(prevSelectedProduct) / 100}`
    : '';

  const onSelectMht = () => {
    onSelect(prevSelectedProduct);
  };

  const onSelectProduct = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    groupedProduct: GroupedContentfulProduct
  ) => {
    e.stopPropagation();

    if (
      isSelected &&
      prevSelectedProduct.contentfulProduct.sys.id === groupedProduct.contentfulProduct.sys.id
    )
      return;

    onSwitch(prevSelectedProduct, groupedProduct);
  };

  const onOpenProdInfo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setShowProdInfo(true);
  };

  return (
    <>
      <div
        className={classNames(
          'selectable-product selectable-option-product',
          isSelected ? 'selected' : ''
        )}
        onClick={onSelectMht}
      >
        <div className='product-option-header'>
          <div className='option-content-wrapper'>
            <p className='option-title'>MHT Treatment</p>
            <p className='option-text'>
              Your doctor has prescribed estradiol, which is available in multiple forms. Please
              select the form you prefer.
            </p>
          </div>

          <img
            src={isSelected ? checkedBox : uncheckedBox}
            alt='selectable box'
            className='header-box-btn'
          />
        </div>

        <div className='product-options-wrapper'>
          {groupedProducts.map((gcp, index) => (
            <button
              className={classNames(
                'option-btn',
                isSelected &&
                  prevSelectedProduct.contentfulProduct.sys.id === gcp.contentfulProduct.sys.id &&
                  'selected'
              )}
              onClick={(e) => onSelectProduct(e, gcp)}
              key={index}
            >
              {getMhtShortName(gcp)}
            </button>
          ))}
        </div>

        <div className='product-content-wrapper'>
          <span className='product-rx-tag'>Rx</span>

          <div className={classNames('product-header')}>
            <div className='header-content'>
              <img
                src={prevSelectedProduct.contentfulProduct.fields.photo.fields.file.url}
                alt={prevSelectedProduct.contentfulProduct.fields.photo.fields.title}
                className='header-img'
              />

              <div className='header-text-wrapper'>
                {productsCleanNameAndDosage.map(({ cleanName, dosage }, index) => (
                  <ProductNameDosage cleanName={cleanName} dosage={dosage} key={index} />
                ))}

                <p className='header-text'>{productPrice} (3-month supply)</p>
              </div>
            </div>
          </div>

          {prevSelectedProduct.contentfulProduct.fields.benefitDesc && (
            <span className='product-text-blurb'>
              {prevSelectedProduct.contentfulProduct.fields.benefitDesc}
            </span>
          )}

          {prevSelectedProduct.contentfulProduct.fields.detailsList && (
            <ul className='product-details-list'>
              {prevSelectedProduct.contentfulProduct.fields.detailsList.map((text, index) => (
                <li className='list-text' key={index}>
                  {text}
                </li>
              ))}
            </ul>
          )}

          <button onClick={onOpenProdInfo} className='product-info-btn-link'>
            Product information
          </button>
        </div>
      </div>

      {prevSelectedProduct.contentfulProduct && (
        <ProductInfoDrawer
          open={showProdInfo}
          setOpen={setShowProdInfo}
          product={prevSelectedProduct.contentfulProduct}
        />
      )}
    </>
  );
}

const SelectedOptionProduct = ({ gcp }: { gcp: GroupedContentfulProduct }) => {};
