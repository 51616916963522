import 'assets/scss/alloy.scss';

import Main from 'components/routes/Main';

import { PostHogProvider } from 'posthog-js/react';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  ui_host: 'https://app.posthog.com',
};

const App = () => {
  return (
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
      <Main />
    </PostHogProvider>
  );
};

export default App;
