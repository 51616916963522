import {
  ShippingMethodType,
  SubscriptionWithRenewal,
  billSubscriptionNow,
  setNextRecurrence,
  useGetAllSubscriptionsForCustomer,
  useGetShippingMethods,
  useIsProcessing,
} from 'client/dist/generated/alloy';
import { isToday, startOfDay } from 'date-fns';
import { useEffect, useState } from 'react';
import { Drawer, Loader } from 'rsuite';

import closeIcon from 'assets/svg/btn-close.svg';

import { getMillisWithRandomTime } from 'lib/shared/date';
import { showDashboardAlert } from 'components/core/Notification';

import { useAppSelector } from 'reducers/alloy_reducer';

import CalendarBlock from './reschedule/CalendarBlock';
import ShippingBlock from './shipNow/ShippingBlock';

interface Props {
  shipment: SubscriptionWithRenewal;
  open: boolean;
  setOpen: (open: boolean) => void;
  onPause: () => void;
}

export default function RescheduleDrawer({ shipment, open, setOpen, onPause }: Props) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const customer = useAppSelector((state) => state.alloy.customer!!);

  const { data: shippingMethods = [], isLoading: isLoadingShippingMethods } = useGetShippingMethods(
    customer.stateAbbr!!.toUpperCase()
  );

  const { mutate: mutateSubscriptions } = useGetAllSubscriptionsForCustomer();
  const { mutate: mutateIsProcessing } = useIsProcessing(shipment.stripeSubscriptionId);

  const mutate = async () => await Promise.all([mutateSubscriptions(), mutateIsProcessing()]);

  const [selectedShippingType, setSelectedShippingType] = useState<ShippingMethodType>('STANDARD');

  const foundSelectedShippingMethod = shippingMethods.find(
    (sm) => sm.method === selectedShippingType
  );

  useEffect(() => {
    const upcomingInvoice = shipment.upcomingInvoice;

    if (upcomingInvoice) {
      const foundShippingMethod = shippingMethods.find(
        (sm) => sm.priceInCents === upcomingInvoice.shipping
      );

      if (foundShippingMethod) {
        setSelectedShippingType(foundShippingMethod.method);
      }
    }
  }, [shippingMethods.length, isLoadingShippingMethods]);

  const onConfirm = async () => {
    try {
      if (selectedDate) {
        setLoading(true);

        if (isToday(selectedDate)) {
          await billSubscriptionNow(shipment.stripeSubscriptionId, {
            shippingMethodId:
              selectedShippingType !== 'STANDARD' ? foundSelectedShippingMethod?.id : undefined,
          });

          showDashboardAlert('Your next order has successfully been placed', 'success');
        } else {
          // Getting a random time allows us to mix up the future shipments so that they don't all get
          // hit at the same exact date in the future potentially creating problems for curexa
          const millis = getMillisWithRandomTime(startOfDay(selectedDate));
          await setNextRecurrence(shipment.stripeSubscriptionId, millis, {
            shippingMethodId:
              selectedShippingType !== 'STANDARD' ? foundSelectedShippingMethod?.id : undefined,
          });

          showDashboardAlert('Your shipment has successfully been rescheduled', 'success');
        }

        await mutate();

        setLoading(false);
        setOpen(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Drawer
      open={open}
      size='xs'
      className='shipment-drawer reschedule-drawer'
      onClose={() => {
        if (!loading) setOpen(false);
      }}
    >
      <Drawer.Body>
        <div className='drawer-header'>
          <div className='header-item-left'></div>

          <p className='header-title'>Reschedule Shipment</p>

          <div className='header-item-right'>
            <button
              onClick={() => {
                setSelectedDate(null);
                setOpen(false);
              }}
              className='header-btn-close'
              disabled={loading}
            >
              <img src={closeIcon} alt='close icon' className='close-icon' />
            </button>
          </div>
        </div>

        {loading || isLoadingShippingMethods ? (
          <Loader center size='lg' />
        ) : (
          <>
            <div className='drawer-body'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <CalendarBlock
                      shipment={shipment}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      shippingMethod={foundSelectedShippingMethod}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='drawer-footer'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <ShippingBlock
                      prevShippingMethodType={foundSelectedShippingMethod?.method}
                      shippingMethods={shippingMethods}
                      onSelectShipping={(sm) => setSelectedShippingType(sm.method)}
                    />

                    <button onClick={onConfirm} className='btn-confirm' disabled={!selectedDate}>
                      Confirm
                    </button>

                    <p className='pause-text'>
                      Need a longer break?{' '}
                      <button className='pause-btn' onClick={onPause}>
                        Pause subscription
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Drawer.Body>
    </Drawer>
  );
}
