import { Elements } from '@stripe/react-stripe-js';
import { capitalize } from 'lodash';
import { useState } from 'react';

import chevronRight from 'assets/svg/core/chevron-right.svg';

import CardIcon from 'components/core/CardIcon';

import { useAppSelector } from 'reducers/alloy_reducer';

import PaymentDrawer from 'components/dashboard/account/drawers/PaymentDrawer';
import { stripePromise } from 'lib/shared/payment';
import { StripePaymentMethod } from 'client/dist/generated/alloy';

interface Props {
  paymentMethod: StripePaymentMethod;
  stripeClientSecret: string;
}

export default function PaymentBlock({ paymentMethod, stripeClientSecret }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const user = useAppSelector((state) => state.alloy.customer!!);

  const onOpenPayment = () => {
    setIsOpen(true);
  };

  return (
    <div className='shipment-payment-wrapper'>
      <div className='shipment-header'>
        <div className='row'>
          <div className='col-12 col-md-4'>
            <p className='header-section-title'>Payment Info</p>
          </div>
          <div className='col-12 col-md-4'>
            <p className='header-title'>Payment Method</p>
          </div>
          <div className='col-12 col-md-4'>
            <p className='header-title'>Billing Address</p>
          </div>
        </div>
      </div>

      <div className='payment-details'>
        <div className='row'>
          <div className='col-md-4'></div>

          {paymentMethod ? (
            <>
              <div className='col-md-4'>
                <div className='shipment-content-block is-openable' onClick={onOpenPayment}>
                  <div className='shipment-content'>
                    <p className='content-title'>
                      <CardIcon brand={paymentMethod.card!.brand} />
                      •••• •••• •••• {paymentMethod.card!.last4}
                    </p>

                    <p className='content-text'>{capitalize(paymentMethod.card!.brand)}</p>

                    <p className='content-text'>
                      Expires {paymentMethod.card!.exp_month}/{paymentMethod.card!.exp_year}
                    </p>
                  </div>

                  <div className='shipment-chevron-wrapper'>
                    <img src={chevronRight} alt='chevron right' />
                  </div>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='shipment-content-block bottom-corner-radius'>
                  <div className='shipment-content bottom-corner-radius'>
                    <p className='content-title'>
                      {user.firstName} {user.lastName}
                    </p>

                    <p className='content-text'>
                      {paymentMethod.billing_details.address.line1}{' '}
                      {paymentMethod.billing_details.address.line2}{' '}
                      {paymentMethod.billing_details.address.city}
                    </p>

                    <p className='content-text'>
                      {paymentMethod.billing_details.address.state}{' '}
                      {paymentMethod.billing_details.address.postal_code} United States
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className='col-md-4'>
              <div className='shipment-content-block is-openable' onClick={onOpenPayment}>
                <div className='shipment-content'>
                  <p className='content-text'>No default payment method found.</p>
                  <p className='content-text'>Click here to add one.</p>
                </div>

                <div className='shipment-chevron-wrapper'>
                  <img src={chevronRight} alt='chevron right' />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Elements stripe={stripePromise} options={{ clientSecret: stripeClientSecret }}>
        <PaymentDrawer open={isOpen} setOpen={setIsOpen} />
      </Elements>
    </div>
  );
}
