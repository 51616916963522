import { AppHistory } from 'components';
import { getBuiltFlowFromCategories } from 'lib/checkout-experience/flow';
import { ExperienceValidationKey, isStepValidated } from 'lib/checkout-experience/validation';
import { formatExperienceURL } from 'lib/core/url';

import { ExperienceCategory } from 'common/dist/models/experience';
import { ExperienceFlow } from 'models/alloy/experience';

import { RootState } from 'reducers';

import { submissionByCategoriesExists } from 'client/dist/generated/alloy';
import { localSubmissionExists } from 'lib/checkout-experience/authentication/localSubmission';
import { getIntakeCategories } from 'lib/shared/experience';
import { sendExceptionToSentry } from 'lib/tracking/sentry';
import { createCart } from './cart_actions';

/**
 *
 * @param url string
 * @param categories ExperienceCategory[]
 * @param history AppHistory
 * @returns void | redirects user to correct page within flow
 */
const buildCheckoutExperience = (
  url: string,
  categories: ExperienceCategory[],
  history: AppHistory
) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { isAuthenticated } = getState().alloy;
      const { localPreCustomer, alloyCart } = getState().experience;

      const selectedFlow = getBuiltFlowFromCategories(categories);

      const splitUrl = url.split('/').filter((s) => s !== '');

      let submissionExists: boolean = false;

      if (isAuthenticated && !categories.every((c) => c === 'gut-health' || c === 'renewal')) {
        const intakeCategories = getIntakeCategories(categories);

        submissionExists = await submissionByCategoriesExists({
          categories: intakeCategories,
          timeAmount: 2,
          timeUnit: 'week',
        });
      } else {
        submissionExists = localSubmissionExists(categories, localPreCustomer);
      }

      let stepIndex =
        selectedFlow.steps.findIndex(
          (sf) => !isStepValidated(sf.validationKey, submissionExists, categories)
        ) ?? 0;

      // /**
      //  * Here we take the end of the url (intake, register, etc) and we check if the user can
      //  * go to that page based on path
      //  */

      if (splitUrl.length >= 2) {
        if (!selectedFlow.steps.some((step) => splitUrl.includes(step.path))) {
          history.push('/404');
          return;
        }

        let nextNeededStepIndex =
          selectedFlow.steps.findIndex(
            (sf) => !isStepValidated(sf.validationKey, submissionExists, categories)
          ) ?? 0;

        stepIndex = selectedFlow.steps.findIndex((step) => splitUrl.includes(step.path)) ?? 0;

        /**
         * We are checking if the next needed step that requires data to be filled it out
         * is before the page the user tried going to. If it is, then we redirect them there!
         */
        if (nextNeededStepIndex < stepIndex) {
          stepIndex = nextNeededStepIndex;
        }
      }

      stepIndex = getPastAuthenticated(stepIndex, isAuthenticated, selectedFlow);

      // on initial load, if the customer is coming from a link that is only '/checkout-experience' then we want to
      // place them in the relief type selection page (since they will be defaulted to mht, we want to allow them the options)
      // to select more categories

      // this is more so an issue that flow id exists but categories does not and the flow id is meant for mht,
      // so to clean just if it is renewal then don't go into any validation steps otherwise do so if categories
      // is not in url

      // TODO: Clean this up a bit better later
      const searchParams = new URLSearchParams(window.location.search);
      const hasCategoriesInUrl = searchParams.has('categories[]');

      if (!hasCategoriesInUrl && categories.every((c) => c !== 'renewal')) {
        const reliefTypeIndex = selectedFlow.steps.findIndex(
          (step) => step.validationKey === ExperienceValidationKey.reliefType
        );

        if (reliefTypeIndex && stepIndex > reliefTypeIndex) {
          stepIndex = reliefTypeIndex;
        }
      }

      const path = selectedFlow.steps[stepIndex].path;

      const checkoutExperienceUrl = formatExperienceURL(
        `/checkout-experience/${path}`,
        window.location,
        categories
      );

      // if products exist on the cart already, we don't need to re-update them
      // this is a major piece post moving review before register because in review
      // you might select products in bundle but then after register the experience
      // gets reconfirmed so the cart used to be rebuilt (making selection in review
      // not really work)
      if (!alloyCart.products.length) {
        await dispatch(createCart(selectedFlow.productIds, categories, submissionExists));
      }

      history.replace(checkoutExperienceUrl);
    } catch (error) {
      sendExceptionToSentry(error as Error);
    }
  };
};

/**
 * Allow user to switch between flows easily (not forcing them to redo parts that they already did)
 *
 * @param categories ProductCategory[]
 * @param history AppHistory
 * @param nextStep: number
 * @returns void | redirects user to correct page within flow
 */
const updateToNewCheckoutExperienceFlow = (
  categories: ExperienceCategory[],
  history: AppHistory,
  nextStep?: number
) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { isAuthenticated } = getState().alloy;
      const { localPreCustomer } = getState().experience;

      const selectedFlow = getBuiltFlowFromCategories(categories);

      let submissionExists: boolean = false;

      if (isAuthenticated && !categories.every((c) => c === 'gut-health' || c === 'renewal')) {
        const intakeCategories = getIntakeCategories(categories);

        submissionExists = await submissionByCategoriesExists({
          categories: intakeCategories,
          timeAmount: 2,
          timeUnit: 'week',
        });
      } else {
        submissionExists = localSubmissionExists(categories, localPreCustomer);
      }

      let stepIndex =
        nextStep ??
        selectedFlow.steps.findIndex(
          (sf) => !isStepValidated(sf.validationKey, submissionExists, categories)
        ) ??
        0;

      stepIndex = getPastAuthenticated(stepIndex, isAuthenticated, selectedFlow);

      const path = selectedFlow.steps[stepIndex].path;

      const checkoutExperienceUrl = formatExperienceURL(
        `/checkout-experience/${path}`,
        window.location,
        categories
      );

      await dispatch(createCart(selectedFlow.productIds, categories, submissionExists));

      history.push(checkoutExperienceUrl);
    } catch (error) {
      sendExceptionToSentry(error as Error);
    }
  };
};

/**
 *
 * For placing the user in a certain step based on the provided flow, we need to check if they are
 * logged in, if they are then we need to skip those steps and place the user in the next available step [index],
 * otherwise just return the current step [index]
 *
 * @param index number
 * @param isAuthenticated boolean
 * @param selectedFlow FlowMode,
 * @returns number
 */
const getPastAuthenticated = (
  stepIndex: number,
  isAuthenticated: boolean,
  selectedFlow: ExperienceFlow
): number => {
  if (isAuthenticated) {
    /**
     * Not allow authenticated users to go to register or verification (unauth) pages
     */
    while (
      selectedFlow.steps[stepIndex].path === 'register' ||
      selectedFlow.steps[stepIndex].path === 'verification'
    ) {
      stepIndex = Math.min(stepIndex + 1, selectedFlow.steps.length - 1);
    }
  }

  return stepIndex;
};

export { buildCheckoutExperience, updateToNewCheckoutExperienceFlow };
