import { ProductFrequencyRenewal } from 'client/dist/generated/alloy';

interface Props {
  pfAndRenewalAndPrescription: ProductFrequencyRenewal;
}

export default function ProductBlock({ pfAndRenewalAndPrescription }: Props) {
  const { product, prescription } = pfAndRenewalAndPrescription;

  return (
    <div className='ship-product-wrapper'>
      <img src={product.photo} alt={product.name} className='product-photo' />

      <div className='product-content-wrapper'>
        <div className='product-content'>
          <p className='product-title'>{product.name}</p>
          <p className='product-text'>{prescription?.daysSupply ?? product.days} day supply</p>
          <p className='product-text'>Refills left: {prescription?.fillsRemaining ?? '-'}</p>
        </div>

        <p className='product-price'>${(product.priceInCents / 100 / 3).toFixed(2)}/mo</p>
      </div>
    </div>
  );
}
