import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { updateMdiWidget } from 'actions/core/customer_actions';
import { getMdiWidget } from 'client/dist/generated/alloy';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'reducers/alloy_reducer';
import { bindActionCreators } from 'redux';

const MobileBanner = () => {
  const { pathname } = useLocation();

  const { messagesBanner } = useAppSelector((state) => state.alloy);

  const isMessages = pathname === '/messages';
  const showMessagesBanner = isMessages && !!messagesBanner && messagesBanner.fields.isDashboardOn;

  if (!showMessagesBanner) return <></>;

  return (
    <Link to='/messages' className='dashboard-banner-mobile'>
      <section className='dashboard-banner'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-10 text-center'>
              <div className='dashboard-banner-wrapper'>
                <span className='dashboard-banner-text'>{messagesBanner.fields.dashboardText}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Link>
  );
};

const StandardBanner = () => {
  const { pathname } = useLocation();

  const { messagesBanner } = useAppSelector((state) => state.alloy);

  const isMessages = pathname === '/messages';
  const showMessagesBanner = isMessages && !!messagesBanner && messagesBanner.fields.isDashboardOn;

  if (!showMessagesBanner) return <></>;

  return (
    <section className='dashboard-banner-desktop'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 text-center'>
            <div className='dashboard-banner-text-wrapper'>
              <span className='dashboard-banner-text'>{messagesBanner.fields.dashboardText}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default function DashboardBanner() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const dispatchUpdateMdiWidget = bindActionCreators(updateMdiWidget, dispatch);

  useEffect(() => {
    const fetchWidget = async () => {
      try {
        const widget = await getMdiWidget();
        dispatchUpdateMdiWidget(widget);
      } catch (e) {
        dispatchUpdateMdiWidget({ status: 'PENDING' });
      }
    };

    fetchWidget();
  }, []);

  const mdiWidget = useAppSelector((state) => state.alloy.mdiWidget);

  if (
    mdiWidget.status === 'INACTIVE' ||
    pathname !== '/messages' ||
    pathname.includes('-confirmation')
  ) {
    return <></>;
  }

  return (
    <>
      <MobileBanner />
      <StandardBanner />
    </>
  );
}
