import { ApiOrder, RecurrenceType, useGetOrderCarrier } from 'client/dist/generated/alloy';

import chevronDown from 'assets/svg/core/chevron-down.svg';
import alloyProductFiller from 'assets/images/shared/alloy-product-filler.png';

import { getTrackingLink } from 'common/dist/shipping/tracking';
import { displayFriendlyOrderStatus } from 'lib/dashboard/account/displayFriendlyOrderStatus';

import { Skeleton } from '@mui/material';
import DomProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { useEffect, useState } from 'react';
import { differenceInDays } from 'date-fns';

interface Props {
  order: ApiOrder;
  setOpen: (id: string) => void;
}

export default function HeaderBlock({ order, setOpen }: Props) {
  const [products, setProducts] = useState<GroupedContentfulProduct[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const title = displayFriendlyOrderStatus(order);
  const orderId = order.fulfillmentCorrelationId;
  const orderNumber = orderId.substring(0, 11).toUpperCase();

  const skipTrackingFetch =
    order.internalStatus === 'DELIVERED' &&
    !!order.deliveredOn &&
    Math.abs(differenceInDays(new Date(), new Date(order.deliveredOn))) > 90;

  const { data: orderCarrier, isLoading: isLoadingCarrier } = useGetOrderCarrier(order.id, {
    swr: {
      enabled: !skipTrackingFetch,
    },
  });

  const carrier = orderCarrier ? orderCarrier : order.carrier;
  const trackingNumber = order.trackingNumber;

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const gcps = await DomProductRegistry.get().getIndividualGroupedContentfulProductsFor(
      'specific',
      order.productFrequencies.map((pf) => pf.productId)
    );

    setProducts(gcps.flat());
    setIsLoading(false);
  };

  return (
    <div className='order-header-block'>
      <div className='order-header-content'>
        <div className='header-content-wrapper'>
          <p className='content-title'>{title}</p>

          <button
            className='chevron-btn'
            onClick={() => {
              setOpen(orderId);
            }}
          >
            <img src={chevronDown} alt='chevron' />
          </button>
        </div>

        <div className='header-content-wrapper d-block d-md-flex'>
          <p className='content-text'>
            {order.isConsult ? 'Initial Doctor Consult' : `Order #${orderNumber}`}
          </p>

          {isLoadingCarrier ? (
            <Skeleton variant='text' width={125} />
          ) : (
            carrier &&
            trackingNumber && (
              <p className='content-text'>
                Tracking Number:{' '}
                <a
                  className='text-link'
                  href={getTrackingLink(carrier, trackingNumber)}
                  target='_blank'
                  rel='noreferrer'
                >
                  {trackingNumber}
                </a>
              </p>
            )
          )}
        </div>
      </div>

      <div className='order-header-products'>
        {isLoading ? (
          <>
            <div className='order-product'>
              <div className='product-img-wrapper'>
                <Skeleton variant='rectangular' width={100} height={100} />
              </div>

              <div className='product-content-wrapper'>
                <Skeleton variant='text' width={150} />

                <Skeleton variant='text' width={100} />
              </div>
            </div>
          </>
        ) : (
          <>
            {!order.isConsult
              ? products.map((gcp, index) => {
                  const recurrenceType = gcp.alloyProduct.parent[0].recurrenceType;

                  return (
                    <div className='order-product' key={index}>
                      <div className='product-img-wrapper'>
                        {!gcp.contentfulProduct.fields.categories.every(
                          (category) => category === 'gut-health'
                        ) && <span className='product-rx-tag'>rx</span>}

                        <img
                          src={gcp.contentfulProduct.fields.photo.fields.file.url}
                          alt={gcp.contentfulProduct.fields.title}
                          className='product-img'
                        />
                      </div>

                      <div className='product-content-wrapper'>
                        <p className='content-title'>{gcp.contentfulProduct.fields.title}</p>

                        <p className='content-text'>
                          ${gcp.contentfulProduct.fields.pricePer3Months?.toFixed(2)} (
                          {recurrenceType === RecurrenceType.RECURRING
                            ? '3-month supply'
                            : 'One-time fill'}
                          )
                        </p>
                      </div>
                    </div>
                  );
                })
              : order.productFrequencies.map((pf, index) => (
                  <div className='order-product' key={index}>
                    <div className='product-img-wrapper'>
                      <img src={alloyProductFiller} alt={pf.name} className='product-img' />
                    </div>

                    <div className='product-content-wrapper'>
                      <p className='content-title'>{pf.name}</p>

                      <p className='content-text'>${(pf.priceInCents / 100).toFixed(2)}</p>
                    </div>
                  </div>
                ))}
          </>
        )}
      </div>
    </div>
  );
}
