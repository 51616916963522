import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Loader } from 'rsuite';

import { convertCentsToDollars } from 'lib/shared/convert';
import { useAppSelector } from 'reducers/alloy_reducer';
import EmptyState from '../EmptyState';
import HeaderButtonBlock from './core/HeaderButtonBlock';
import { getGroupedContentfulProductsFrom } from 'lib/shared/product';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import ProductNameDosage from 'components/checkout-experience/products/ProductNameDosage';
import { getProductNameWithDosage } from 'lib/shared/product/productNameWithDosage';

export default function ShipmentsBlock() {
  const customer = useAppSelector((state) => state.alloy.customer!!);

  const [isLoadingGrouped, setIsLoadingGrouped] = useState<boolean>(true);
  const [groupedProducts, setGroupedProducts] = useState<GroupedContentfulProduct[]>([]);

  const { data: shipments = [], isLoading: isLoadingSubscriptions } =
    useGetAllSubscriptionsForCustomer();

  const [loading, setLoading] = useState<boolean>(false);

  const isLoading = loading || isLoadingGrouped || isLoadingSubscriptions;

  useEffect(() => {
    retrieveGroupedAndContentfulProducts();
  }, [shipments.length, isLoadingSubscriptions]);

  /**
   * we need to go through our products from subscriptions and use our grouping contentful functions.
   * we use our alloy product frequencies to pass to the library we have that will group those products
   * as intended then it will find the main contentful product for each of those grouped products
   * and return that. this is used to better display grouped content that way we do not get an isntance of
   * displaying say 'pill' and 'prog' individually, we can now display them together 'pill+prog'
   */
  const retrieveGroupedAndContentfulProducts = async () => {
    let grouped: GroupedContentfulProduct[] = [];

    for (const subscription of shipments) {
      const converted = await getGroupedContentfulProductsFrom(
        subscription.products.map((p) => p.product)
      );

      grouped = grouped.concat(converted);
    }

    setGroupedProducts(grouped);
    setIsLoadingGrouped(false);
  };

  if (isLoading) {
    return (
      <div className='dashboard-shipments-wrapper loading-wrapper'>
        <Loader center size='lg' />
      </div>
    );
  }

  if (customer.status === 'NEW') {
    return <EmptyState type='NEW' />;
  } else if (customer.status === 'PENDING') {
    return <EmptyState type='PENDING' />;
  } else if (shipments.length === 0) {
    return (
      <EmptyState
        type='CONSULT'
        title="Ready for relief? Let's make it happen."
        desc="Oops! You don't have any upcoming shipments. Once you have active Alloy shipments, they will appear here."
        btnTitle='Start consult'
      />
    );
  }

  return (
    <div className='dashboard-shipments-wrapper'>
      {shipments.map((shipment, index) => {
        const { status } = shipment;

        const pfIds = shipment.products.map((pf) => pf.product.id);

        const filteredProducts = groupedProducts.filter((grouped) =>
          grouped.alloyProduct.parent.map((pf) => pf.id).some((id) => pfIds.includes(id))
        );

        return (
          <div
            className={'shipment-block' + (status !== 'ACTIVE' ? ' shipment-not-active' : '')}
            key={index}
          >
            <div className='row'>
              <div className='col-12 shipment-header'>
                <div className='shipment-header-text-wrapper'>
                  <p className='shipment-header-text'>
                    <span>Next shipment:</span>{' '}
                    {status === 'ACTIVE'
                      ? format(new Date(shipment.nextRecurrenceOn), 'MM/dd/yyyy')
                      : '-'}
                  </p>

                  <p className='shipment-header-text'>
                    <span>Total: </span>
                    {shipment.upcomingInvoice
                      ? `$${convertCentsToDollars(shipment.upcomingInvoice.total)}`
                      : '-'}
                  </p>
                </div>

                <div className='shipment-header-btn-wrapper'>
                  <HeaderButtonBlock shipment={shipment} setLoading={setLoading} />
                </div>
              </div>
            </div>

            <div className='row shipment-product-row'>
              {filteredProducts.map((groupedProduct, productIndex) => {
                const isRx = groupedProduct.alloyProduct.parent.every((pf) => pf.type === 'RX');
                const productsCleanNameAndDosage = getProductNameWithDosage(groupedProduct);

                const { contentfulProduct } = groupedProduct;

                return (
                  <div className='col-12' key={productIndex}>
                    <div className='shipment-product'>
                      <img
                        src={contentfulProduct.fields.productPhoto.fields.file.url}
                        alt={contentfulProduct.fields.photo.fields.title}
                        className='img-fluid'
                      />

                      <div className='product-content-wrapper'>
                        {isRx && (
                          <p className='mobile-product-tag'>
                            <span className='product-tag'>RX</span>
                          </p>
                        )}

                        <div className='product-header'>
                          {isRx && <span className='product-tag'>RX</span>}
                          {productsCleanNameAndDosage.map(
                            ({ cleanName, dosage }, nameDosageIndex) => (
                              <ProductNameDosage
                                cleanName={cleanName}
                                dosage={dosage}
                                key={nameDosageIndex}
                              />
                            )
                          )}
                        </div>

                        {contentfulProduct.fields.bestFor && (
                          <p className='product-text'>
                            <span className='product-text-best'>
                              {contentfulProduct.fields.bestFor}
                            </span>

                            <Link to='/treatment-plan' className='product-link'>
                              Read more
                            </Link>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className='row d-block d-md-none'>
              <div className='col-12'>
                <div className='shipment-mobile-btn-wrapper'>
                  <HeaderButtonBlock shipment={shipment} setLoading={setLoading} />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
