import {
  submitTreatmentPlan,
  useGetAllOrders,
  useGetAllSubscriptionsForCustomer,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'reducers/alloy_reducer';
import { bindActionCreators } from 'redux';

import { clearTPCart, updateTPCart } from 'actions/treatment-plan/cart_actions';

import Loader from 'components/core/Loader';
import ActivePlanContainer from 'components/dashboard/treatment-plan/containers/ActivePlan';
import AwaitingQueueOrCX from 'components/dashboard/treatment-plan/containers/AwaitingQueueOrCX';
import ConfirmationContainer from 'components/dashboard/treatment-plan/containers/Confirmation';
import DoctorReviewContainer from 'components/dashboard/treatment-plan/containers/DoctorReview';
import EmptyPlanContainer from 'components/dashboard/treatment-plan/containers/EmptyPlan';
import PendingApprovalContainer from 'components/dashboard/treatment-plan/containers/PendingApproval';

import DashboardContainer from 'containers/dashboard/DashboardContainer';

import { getAccountStatus } from 'lib/core/customer/getAccountStatus';
import useMarkNotificationsAsRead from 'lib/dashboard/notifications/useMarkNotificationsAsRead';
import { getProductsWithRecurrence } from 'lib/shared/product';
import { useHistory } from 'react-router';

export default function TreatmentPlan() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead([
    'NEW_PRESCRIPTION',
    'TREATMENT_PLAN_READY',
    'TREATMENT_PLAN_UPDATED',
  ]);

  const dispatch = useDispatch();
  const history = useHistory();

  const customer = useAppSelector((state) => state.alloy.customer!);
  const { treatmentPlanCart: cart } = useAppSelector((state) => state.treatmentPlan);

  const [loading, setLoading] = useState<boolean>(true);
  const [isOrderPlaced, setIsOrderPlaced] = useState<boolean>(cart.isPurchased);

  const {
    data: treatmentPlan,
    isLoading: isLoadingTreatmentPlan,
    mutate: mutateTreatmentPlan,
  } = useGetTreatmentPlan();
  const {
    data: subscriptions = [],
    isLoading: isLoadingSubscriptions,
    mutate: mutateSubscriptions,
  } = useGetAllSubscriptionsForCustomer();
  const { data: orders = [], isLoading: isLoadingOrders } = useGetAllOrders();

  // All products the customer can purchase
  const pendingChargesProducts = treatmentPlan
    ? treatmentPlan.pendingCharges.flatMap((pc) => pc.products)
    : [];

  // See if anything is loading
  const isLoading = loading || isLoadingTreatmentPlan || isLoadingSubscriptions || isLoadingOrders;

  // Update the treatment plan cart or clear it
  const dispatchUpdateCart = bindActionCreators(updateTPCart, dispatch);
  const dispatchClearCart = bindActionCreators(clearTPCart, dispatch);

  useEffect(() => {
    updatingCart();
  }, [JSON.stringify(treatmentPlan), isLoadingTreatmentPlan]);

  const updatingCart = async () => {
    // load cart in properly only for pending statuses

    if (treatmentPlan && !isOrderPlaced) {
      setLoading(true);

      const collated = await getProductsWithRecurrence(pendingChargesProducts);

      // Update our treatment plan cart products off the bat
      dispatchUpdateCart({
        products: collated.flat(),
      });
    }

    setLoading(false);
  };

  const onContinueCheckout = () => {
    dispatchUpdateCart({
      products: cart.products,
    });

    history.push('/treatment-plan/checkout');
  };

  /**
   * Placing the order
   */
  const onPlaceOrder = async () => {
    window.scrollTo(0, 0);

    try {
      setLoading(true);

      const { products, promotionCode, shipping } = cart;

      // Collapse the products from cart into 1D array
      const productFrequencyIds = products
        .flatMap((p) => p.alloyProduct.parent.concat(p.alloyProduct.child ?? []))
        .map((pf) => pf.id);

      // only send along the pending charges we've selected products from, the BE will handle the rest in the submit endpoint
      const selectedPendingChargeIds = treatmentPlan!.pendingCharges
        .filter((pc) => {
          const pendingChargeProductFrequencyIds = pc.products.map((p) => p.id);
          return pendingChargeProductFrequencyIds.some((pcPF) =>
            productFrequencyIds.includes(pcPF)
          );
        })
        .map((pc) => pc.id);

      await submitTreatmentPlan({
        pendingChargeIds: selectedPendingChargeIds,
        productFrequencyIds,
        promotionCodeId: promotionCode.id,
        shippingMethodId: shipping?.id,
        shipNow: true,
      });

      dispatchUpdateCart({
        isPurchased: true,
      });

      setIsOrderPlaced(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  /**
   * View the treatment plan after purchase
   */
  const onViewTreatmentPlan = async () => {
    window.scrollTo(0, 0);

    setLoading(true);

    await Promise.all([mutateTreatmentPlan(), mutateSubscriptions()]);

    dispatchClearCart();

    setIsOrderPlaced(false);
    setLoading(false);
  };

  /**
   * Stuff is loading
   */
  if (isLoading) {
    return (
      <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
        <Loader />
      </DashboardContainer>
    );
  }

  if (isOrderPlaced && cart.products.length !== 0) {
    return <ConfirmationContainer onViewTreatmentPlan={onViewTreatmentPlan} />;
  }

  const accountStatus = getAccountStatus(customer.status, subscriptions, orders, treatmentPlan);

  switch (accountStatus) {
    case 'NO_PURCHASE':
      return <EmptyPlanContainer />;

    case 'AWAITING_CX_OR_QUEUE':
      return <AwaitingQueueOrCX />;

    case 'AWAITING_DOCTOR':
      return <DoctorReviewContainer />;

    case 'AWAITING_CUSTOMER':
      return <PendingApprovalContainer onContinueCheckout={onContinueCheckout} />;

    default:
      return <ActivePlanContainer />;
  }
}
