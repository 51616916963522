import classNames from 'classnames';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { ReactNode, useState } from 'react';

import ProductInfoDrawer from '../../core/drawers/ProductInfoDrawer';

import { isSynbiotic } from 'lib/shared/contentful';
import { getPriceForCombinedProducts } from 'lib/shared/product';
import ProductNameDosage from './ProductNameDosage';
import { getProductNameWithDosage } from 'lib/shared/product/productNameWithDosage';
import { PROGESTERONE_100_PRODUCT_ID } from 'common/dist/products/productRegistry';

interface Props {
  groupedProduct: GroupedContentfulProduct;
  isHighlighted?: boolean;
  onEdit?: () => void;
  children?: ReactNode;
  hideProductInformation?: boolean;
  hideDetails?: boolean;
  hidePrice?: boolean;
  showDosage?: boolean;
  showProgDetails?: boolean;
}

export default function ViewableProduct({
  groupedProduct,
  isHighlighted = false,
  onEdit,
  children,
  hideProductInformation = false,
  hideDetails = false,
  hidePrice = false,
  showDosage = false,
  showProgDetails = false,
}: Props) {
  const { contentfulProduct } = groupedProduct;

  const productsCleanNameAndDosage = showDosage
    ? getProductNameWithDosage(groupedProduct)
    : [{ cleanName: contentfulProduct.fields.title }];

  const productPrice =
    showProgDetails &&
    groupedProduct.alloyProduct.parent.every((pf) => pf.productId === PROGESTERONE_100_PRODUCT_ID)
      ? 'Free with Estradiol*'
      : `$${getPriceForCombinedProducts(groupedProduct) / 100}`;

  const [showProdInfo, setShowProdInfo] = useState<boolean>(false);

  const isProductSynbiotic = isSynbiotic(contentfulProduct);

  const onOpenProdInfo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setShowProdInfo(true);
  };

  return (
    <>
      <div className={classNames('viewable-product', isHighlighted ? 'highlighted-border' : '')}>
        {!isProductSynbiotic && <span className='product-rx-tag'>Rx</span>}

        <div className={classNames('product-header', hideDetails ? 'no-margin' : '')}>
          <div className='header-content'>
            <img
              src={contentfulProduct.fields.photo.fields.file.url}
              alt={contentfulProduct.fields.photo.fields.title}
              className='header-img'
            />

            <div className='header-text-wrapper'>
              {productsCleanNameAndDosage.map(({ cleanName, dosage }, index) => (
                <ProductNameDosage cleanName={cleanName} dosage={dosage} key={index} />
              ))}

              {!hidePrice && (
                <p className='header-text'>
                  <span className='text-price'>{productPrice}</span> (3-month supply)
                </p>
              )}

              {hideDetails && !hideProductInformation && (
                <button onClick={onOpenProdInfo} className='product-info-btn-link'>
                  Product information
                </button>
              )}
            </div>
          </div>

          {onEdit && (
            <div className='header-btn-wrapper'>
              <button className='header-btn' onClick={onEdit}>
                Edit
              </button>
            </div>
          )}
        </div>

        {!hideDetails && <p className='product-text'>{contentfulProduct.fields.detail}</p>}

        {!hideDetails && !hideProductInformation && (
          <button onClick={onOpenProdInfo} className='product-info-btn-link'>
            Product information
          </button>
        )}

        <div>{children}</div>
      </div>

      <ProductInfoDrawer
        open={showProdInfo}
        setOpen={setShowProdInfo}
        product={contentfulProduct}
      />
    </>
  );
}
