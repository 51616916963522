import { Dispatch } from 'redux';

import { COGNITO_USER, LOCAL_USER } from 'actions/types';

import { RootState } from 'reducers';

import { sendExceptionToSentry } from 'lib/tracking/sentry';
import { signUp } from 'lib/core/awsAuth';
import { setItem } from 'client/dist/localstorage';

export const register = (email: string, password: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { localPreCustomer } = getState().experience;

      const updateLocalPreCustomer = {
        ...localPreCustomer,
        email,
      };

      setItem('localPreCustomer', updateLocalPreCustomer, true);

      dispatch({ type: LOCAL_USER, payload: updateLocalPreCustomer });

      const signUpResponse = await signUp(email, password);

      dispatch({
        type: COGNITO_USER,
        payload: {
          email: email,
          password: password,
          ...signUpResponse,
        },
      });
    } catch (error) {
      sendExceptionToSentry(error as Error);
      throw error;
    }
  };
};
