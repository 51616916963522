import SelectableProductsGroup from 'components/checkout-experience/preferences/SelectableProductsGroup';
import { xor } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { bindActionCreators } from 'redux';

import { updateRequestingProducts } from 'actions/checkout-experience/requesting_actions';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import BottomBar from 'components/checkout-experience/BottomBar';
import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import Loader from 'components/core/Loader';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';
import Layout from 'containers/Layout';

import { getPercentComplete } from 'lib/checkout-experience/progress';

import { ExperienceComponentModel } from 'models/alloy/experience';

import { useAppSelector } from 'reducers/alloy_reducer';
import ProductRegistry from 'client/dist/product/productRegistry';

export default function MhtPreferences({ onNext, onBack }: ExperienceComponentModel) {
  const dispatch = useDispatch();
  const location = useLocation();

  const prevRequestedProductIds = useAppSelector((state) => state.experience.requestingProductIds);

  const [isLoading, setIsLoading] = useState(false);
  const [mhtProducts, setMhtProducts] = useState<GroupedContentfulProduct[]>([]);
  const [requestedProductIds, setRequestedProductIds] = useState(prevRequestedProductIds);

  const dispatchUpdateRequesting = bindActionCreators(updateRequestingProducts, dispatch);

  useEffect(() => {
    fetchMhtProducts();
  }, []);

  const fetchMhtProducts = async () => {
    setIsLoading(true);

    const fetchedProducts = (
      await ProductRegistry.get().getIndividualGroupedContentfulProductsFor('mht')
    ).flat();

    const sortedProducts = fetchedProducts.sort(
      (a, b) => a.contentfulProduct.fields.order - b.contentfulProduct.fields.order
    );

    setMhtProducts(sortedProducts);
    setIsLoading(false);
  };

  const onSelect = (groupedProduct?: GroupedContentfulProduct) => {
    if (groupedProduct) {
      setRequestedProductIds(
        xor(
          requestedProductIds,
          groupedProduct.alloyProduct.parent.map((pf) => pf.productId)
        )
      );
    } else {
      setRequestedProductIds([]);
    }
  };

  const onSubmit = () => {
    dispatchUpdateRequesting(requestedProductIds);

    onNext();
  };

  return (
    <Layout title='MHT Preferences - Alloy' desc='' noBars>
      <TopBannerWithProgress
        percentComplete={getPercentComplete(location)}
        onBack={isLoading ? undefined : onBack}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CheckoutExperienceSection>
            <h1 className='content-title'>
              Do you have a specific treatment you are interested in including in your treatment
              plan?
            </h1>

            <p className='content-blurb'>
              If not, don't worry, your doctor will work with you to find the treatment that is best
              for you.
            </p>

            <SelectableProductsGroup
              products={mhtProducts}
              requestedProductIds={requestedProductIds}
              onSelect={onSelect}
            />
          </CheckoutExperienceSection>

          <BottomBar>
            <button className='primary-button' type='submit' onClick={onSubmit}>
              Next
            </button>
          </BottomBar>
        </>
      )}
    </Layout>
  );
}
