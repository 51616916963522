"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductRegistry = exports.ESTRADIOL_GEL_075_PRODUCT_ID = exports.ESTRADIOL_GEL_050_PRODUCT_ID = exports.ESTRADIOL_GEL_025_PRODUCT_ID = exports.ESTRADIOL_GEL_125_PRODUCT_ID = exports.ORAL_MINOXIDIL_02_PRODUCT_ID = exports.ORAL_MINOXIDIL_01_PRODUCT_ID = exports.ORAL_MINOXIDIL_005_PRODUCT_ID = exports.TRETINOIN_09_ID = exports.TRETINOIN_06_ID = exports.TRETINOIN_03_ID = exports.EVAMIST_PRODUCT_ID = exports.SIXTY_DAY_OFFSET_ID = exports.SEVENTY_THREE_DAY_OFFSET_ID = exports.ESTRADIOL_GEL_1_PRODUCT_ID = exports.DIVIGEL_PRODUCT_ID = exports.DOCTOR_CONSULT_PRODUCT_ID = exports.SYNBIOTIC_PRODUCT_ID = exports.M4_PRODUCT_ID = exports.OMAZING_NO_MINT_PRODUCT_ID = exports.OMAZING_PRODUCT_ID = exports.LDBC_PRODUCT_ID = exports.PAROXETINE_PRODUCT_ID = exports.VAGINAL_CREAM_PRODUCT_ID = exports.NORETHINDRONE_ACETATE_5_ID = exports.PROGESTERONE_200_PRODUCT_ID = exports.PROGESTERONE_100_PRODUCT_ID = exports.ESTRADIOL_PILL_2_PRODUCT_ID = exports.ESTRADIOL_PILL_1_PRODUCT_ID = exports.ESTRADIOL_PILL_5_PRODUCT_ID = exports.ESTRADIOL_075_PATCH_PRODUCT_ID = exports.ESTRADIOL_025_PATCH_PRODUCT_ID = exports.ESTRADIOL_0375_PATCH_PRODUCT_ID = exports.ESTRADIOL_01_PATCH_PRODUCT_ID = exports.ESTRADIOL_05_PATCH_PRODUCT_ID = exports.SEVENTY_FIVE_DAY_RECURRING_ID = exports.SINGLE_SUPPLY_ID = exports.NINETY_DAY_RECURRING_ID = void 0;
const lodash_1 = require("lodash");
const productFrequency_1 = require("./productFrequency");
exports.NINETY_DAY_RECURRING_ID = 1;
// const THIRTY_DAY_RECURRING_ID = 2;
exports.SINGLE_SUPPLY_ID = 3;
exports.SEVENTY_FIVE_DAY_RECURRING_ID = 4;
exports.ESTRADIOL_05_PATCH_PRODUCT_ID = 3;
exports.ESTRADIOL_01_PATCH_PRODUCT_ID = 4;
exports.ESTRADIOL_0375_PATCH_PRODUCT_ID = 29;
exports.ESTRADIOL_025_PATCH_PRODUCT_ID = 30;
exports.ESTRADIOL_075_PATCH_PRODUCT_ID = 34;
exports.ESTRADIOL_PILL_5_PRODUCT_ID = 5;
exports.ESTRADIOL_PILL_1_PRODUCT_ID = 6;
exports.ESTRADIOL_PILL_2_PRODUCT_ID = 28;
exports.PROGESTERONE_100_PRODUCT_ID = 11;
exports.PROGESTERONE_200_PRODUCT_ID = 12;
exports.NORETHINDRONE_ACETATE_5_ID = 31;
exports.VAGINAL_CREAM_PRODUCT_ID = 7;
exports.PAROXETINE_PRODUCT_ID = 10;
exports.LDBC_PRODUCT_ID = 8;
exports.OMAZING_PRODUCT_ID = 26;
exports.OMAZING_NO_MINT_PRODUCT_ID = 44;
exports.M4_PRODUCT_ID = 27;
exports.SYNBIOTIC_PRODUCT_ID = 9;
exports.DOCTOR_CONSULT_PRODUCT_ID = 15;
/** @deprecated use ESTRADIOL_GEL_1_PRODUCT_ID instead */
exports.DIVIGEL_PRODUCT_ID = 25;
exports.ESTRADIOL_GEL_1_PRODUCT_ID = exports.DIVIGEL_PRODUCT_ID;
exports.SEVENTY_THREE_DAY_OFFSET_ID = 1;
exports.SIXTY_DAY_OFFSET_ID = 2;
exports.EVAMIST_PRODUCT_ID = 35;
exports.TRETINOIN_03_ID = 36;
exports.TRETINOIN_06_ID = 37;
exports.TRETINOIN_09_ID = 38;
exports.ORAL_MINOXIDIL_005_PRODUCT_ID = 40;
exports.ORAL_MINOXIDIL_01_PRODUCT_ID = 41;
exports.ORAL_MINOXIDIL_02_PRODUCT_ID = 42;
exports.ESTRADIOL_GEL_125_PRODUCT_ID = 43;
exports.ESTRADIOL_GEL_025_PRODUCT_ID = 45;
exports.ESTRADIOL_GEL_050_PRODUCT_ID = 46;
exports.ESTRADIOL_GEL_075_PRODUCT_ID = 47;
/**
 * Use this primarily, because it's way easier to mock with sinon
 */
const get = (fetcher) => ProductRegistry.getInstance(fetcher);
exports.default = { get };
/**
 * Holds product configuration in memory.
 *
 * This is the skeleton key across our integrations. Prefer grabbing and storing
 * this configuration in memory (vs re-fetching) - these are stable and won't change
 * over time (without a redeploy).
 *
 */
class ProductRegistry {
    constructor(fetcher) {
        this.shippingMethodPromise = undefined;
        this.shippingMethods = undefined;
        this.getDocConsult = () => __awaiter(this, void 0, void 0, function* () {
            return (yield this.alloyProductsActiveAndLegacy).filter((ap) => ap.productId === exports.DOCTOR_CONSULT_PRODUCT_ID);
        });
        /**
         * Give a product/frequency and optional bundled config - get the fully hydrated
         * ProductAndFrequency object. Use this to get stripe price id, mdi id, etc.
         *
         * Note bundled config is only applicable to products that HAVE a bundled price (give a bundling discount).
         *
         * Tests of this function are located in apps/api/productRegistry.test.ts
         *
         * @param product - product id to use (these should stay stable)
         * @param frequency - frequency id to use (1/[2]/3/4)
         * @param bundled - optional but required to get the right bundling config of a potentially bundled product!
         */
        this.getProductFrequency = (product, frequency, bundled = undefined) => __awaiter(this, void 0, void 0, function* () {
            const products = yield this.alloyProducts;
            const bundles = yield this.discountBundles;
            const pfs = products.filter((pf) => pf.productId === product && pf.frequencyId === frequency);
            const pf = pfs.find((pf) => {
                if (bundled !== undefined) {
                    const discountedPfs = bundles[pf.productId].map((b) => b.bundledDiscountProduct.productFrequencyId);
                    return discountedPfs.includes(pf.id) === bundled;
                }
                return true;
            });
            return pf;
        });
        /**
         * Similar to above, but driven via recurrence type instead. Use this when you're
         * more concerned about one time vs recurring
         *
         * @param product - product id to use (these should stay stable)
         * @param recurrenceType - one time vs recurring
         * @param bundled - optional but required to get the right bundling config of a potentially bundled product!
         */
        this.getProductFrequencyByRecurrence = (product, recurrenceType, bundled = undefined) => __awaiter(this, void 0, void 0, function* () {
            const products = yield this.alloyProducts;
            const bundles = yield this.discountBundles;
            const pfs = products.filter((pf) => pf.productId === product && pf.recurrenceType === recurrenceType);
            const pf = pfs.find((pf) => {
                const discountedPfs = bundles[pf.productId].map((b) => b.bundledDiscountProduct.productFrequencyId);
                return bundled !== undefined ? discountedPfs.includes(pf.id) === bundled : true;
            });
            return pf;
        });
        /**
         * Given a **valid** cart of product frequencies, return the proper grouping -
         *
         * 1. Find bundled products
         * 2. Group accordingly
         * 3. Append the rest of the straggler products to the list
         *
         * We find the proper bundled product_frequency (thus stripe price) based on whether
         * that child price ends up paired with its parent in the current cart.
         *
         * Tests of this function are located in apps/api/productRegistry.test.ts
         *
         * @param pfs - incoming products. This could be product rather than pf, but we
         * tend to use pf through the app so this makes it a bit simpler. Important -
         * no need to worry about whether you're using the "right" pf here - we'll sort
         * it out in this method.
         */
        this.getGroupedProductsFor = (pfs) => __awaiter(this, void 0, void 0, function* () {
            const nonMdiProducts = pfs.filter((pf) => !pf.mdiProductId);
            const groupedMdiProducts = yield this.getGroupedProductsForMdiIds((0, lodash_1.uniqBy)(pfs, (pf) => pf.mdiProductId)
                .map((pf) => pf.mdiProductId)
                .filter((i) => i));
            return [
                ...groupedMdiProducts,
                ...nonMdiProducts.map((pf) => ({
                    parent: [pf],
                })),
            ];
        });
        /**
         * Given a set of valid mdi product ids (clearly ignoring synbiotic/consult here) -
         * group up products based a set of rules (see above)
         *
         * used when we need to fetch products regardless of being split or not.
         * for example: m4 and tretinoin would be together as parent and child using this method
         *
         * Tests of this function are located in apps/api/productRegistry.test.ts
         *
         * @param mdiIds
         */
        this.getGroupedProductsForMdiIds = (mdiIds) => __awaiter(this, void 0, void 0, function* () {
            const [productFrequencies, bundles] = yield Promise.all([
                this.alloyProducts,
                this.discountBundles,
            ]);
            // all relevant products, regardless of whether they're bundled/standalone!
            const relevantProducts = productFrequencies.filter((paf) => mdiIds.includes(paf.mdiProductId));
            const [recurringPfs, singleSupplyPfs] = (0, lodash_1.partition)(relevantProducts, (pf) => pf.recurrenceType === 'RECURRING');
            const products = [
                ...(yield this.getPricesFor(recurringPfs.map((rp) => rp.productId))),
                ...(yield this.getPricesFor(singleSupplyPfs.map((rp) => rp.productId), productFrequency_1.RecurrenceType.ONE_TIME)),
            ].flatMap((bundle) => {
                const [child, parent] = (0, lodash_1.partition)(bundle, (b) => bundles[b.productId]);
                const hasParentAndChildren = parent.length && child.length;
                if (hasParentAndChildren) {
                    return parent.map((pf) => ({ parent: [pf], child }));
                }
                return bundle.map((pf) => ({ parent: [pf], child: [] }));
            });
            return products;
        });
        this.fetcher = fetcher;
        this.alloyProductsActiveAndLegacy = fetcher.fetch();
        this.alloyProducts = fetcher.fetch().then((f) => f.filter((f) => f.active));
        this.shippingFetcher = fetcher.fetchShippingMethods;
        this.discountBundles = fetcher.fetchDiscountBundles();
    }
    static getInstance(fetcher) {
        if (!ProductRegistry.instance) {
            ProductRegistry.instance = new ProductRegistry(fetcher);
        }
        return ProductRegistry.instance;
    }
    /**
     * @returns Promise<DeepShippingMethod[]>
     * Lazy Loads the Shipping Methods by hand
     */
    getShippingMethods() {
        if (this.shippingMethods) {
            return Promise.resolve(this.shippingMethods);
        }
        if (this.shippingMethodPromise) {
            return this.shippingMethodPromise;
        }
        const inFlight = this.shippingFetcher();
        this.shippingMethodPromise = inFlight;
        inFlight.then((dsm) => {
            this.shippingMethods = dsm;
            this.shippingMethodPromise = undefined;
        });
        return inFlight;
    }
    /**
     * For a given productId, it returns if the specified product is part of a bundle.
     * This will be true if the product is a discounted product or the parent of a discounted product.
     *
     * Tests of this function are located in apps/api/productRegistry.test.ts
     *
     * @param productId
     * @returns Promise<boolean>
     */
    canBeBundled(productId) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const bundles = yield this.discountBundles;
            const isDiscountedProduct = ((_a = bundles[productId]) === null || _a === void 0 ? void 0 : _a.length) > 0;
            const isBundleParent = Object.values(bundles).some((bundles) => bundles.some((bundle) => bundle.bundledParentProductIds.includes(productId)));
            return isDiscountedProduct || isBundleParent;
        });
    }
    /**
     * For a given list of productIds, this function returns available price options for
     * determined products, depending on if it is part of a bundle or not.
     *
     * Tests of this function are located in apps/api/productRegistry.test.ts
     *
     * @param productIds
     * @param recurrenceType
     * @returns Promise<ProductAndFrequency[][]>
     */
    getPricesFor(productIds, recurrenceType = productFrequency_1.RecurrenceType.RECURRING) {
        return __awaiter(this, void 0, void 0, function* () {
            const uniqueProductIds = (0, lodash_1.uniq)(productIds);
            const [alloyProducts, bundles] = yield Promise.all([this.alloyProducts, this.discountBundles]);
            const bundledProductIds = uniqueProductIds.filter((productId) => { var _a; return ((_a = bundles[productId]) === null || _a === void 0 ? void 0 : _a.length) > 0; });
            const sameRecurrenceProducts = alloyProducts.filter((ap) => ap.recurrenceType === recurrenceType || ap.productId === exports.DOCTOR_CONSULT_PRODUCT_ID);
            // Gets available parents and discounted products and put them together in a 2D array
            const availableParents = (0, lodash_1.uniq)(bundledProductIds.flatMap((productId) => { var _a; return (_a = bundles[productId]) === null || _a === void 0 ? void 0 : _a.flatMap((bundle) => bundle.bundledParentProductIds); }));
            const parents = sameRecurrenceProducts.filter((ap) => uniqueProductIds.includes(ap.productId) && availableParents.includes(ap.productId));
            const bundledProducts = parents.map((parent) => {
                const children = sameRecurrenceProducts.filter((ap) => {
                    var _a;
                    return bundledProductIds.includes(ap.productId) &&
                        ((_a = bundles[ap.productId]) === null || _a === void 0 ? void 0 : _a.some((bundle) => bundle.bundledParentProductIds.includes(parent.productId) &&
                            ap.id === bundle.bundledDiscountProduct.productFrequencyId));
                });
                return [parent, ...children];
            });
            // Gets every product that are not in any bundles and put them in a 2D array
            const unbundledProducts = uniqueProductIds
                .filter((productId) => !bundledProducts.some((bp) => bp.some((p) => p.productId === productId)))
                .map((productId) => [
                sameRecurrenceProducts.find((ap) => {
                    var _a;
                    return ap.productId === productId &&
                        // Making sure it selects unbundled product frequency
                        !((_a = bundles[productId]) === null || _a === void 0 ? void 0 : _a.some((bp) => bp.bundledDiscountProduct.productFrequencyId === ap.id));
                }),
            ]);
            return [...bundledProducts, ...unbundledProducts];
        });
    }
    /**
     * For a given list of productIds, it returns all products that are in the same bundle as them.
     * For example if [tretinoin, vaginal cream] is passed as param,
     * this function returns [m4, tretinoin, vaginal cream, omazing]
     *
     * Tests of this function are located in apps/api/productRegistry.test.ts
     *
     * @param productIds ProductAndFrequency['productId'][]
     * @returns Promise<ProductAndFrequency['productId'][]>
     */
    getBundleProductIds(productIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const bundles = yield this.discountBundles;
            const allBundledProductIds = Object.keys(bundles).map((key) => Number(key));
            // get every product id from productIds that can be bundled
            let bundableProductIds = [];
            for (const productId of productIds) {
                if (yield this.canBeBundled(productId)) {
                    bundableProductIds.push(productId);
                }
            }
            // get parent ids for child id present in bundableProductIds
            const parentProductIds = (0, lodash_1.intersection)(allBundledProductIds, bundableProductIds).flatMap((productId) => bundles[productId].flatMap((b) => b.bundledParentProductIds));
            // get children ids for parent id present in bundableProductIds
            const childProductIds = allBundledProductIds.filter((productId) => {
                const parentExistsInBundle = bundles[productId].some((b) => (0, lodash_1.intersection)(b.bundledParentProductIds, bundableProductIds).length > 0);
                return parentExistsInBundle;
            });
            return (0, lodash_1.uniq)([...bundableProductIds, ...parentProductIds, ...childProductIds]);
        });
    }
}
exports.ProductRegistry = ProductRegistry;
