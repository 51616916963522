import { Dispatch } from 'redux';

import { TREATMENT_PLAN_CART } from 'actions/types';

import { AlloyCart } from 'models/alloy/cart';

import { RootState } from 'reducers';

const update = (updatedCart: Partial<AlloyCart>) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const cart = getState().treatmentPlan.treatmentPlanCart;

    const treatmentPlanCart = {
      ...cart,
      ...updatedCart,
    };

    dispatch({
      type: TREATMENT_PLAN_CART,
      payload: treatmentPlanCart,
    });
  };
};

const clear = () => {
  return (dispatch: Dispatch) => {
    const clearedCart = {
      products: [],
      taxAmountToCollect: 0,
      promotionCode: {
        id: '',
        name: '',
        discountAmount: 0,
      },
      shipping: undefined,
      referralCreditBalance: 0,
      shipNow: true,
    };

    dispatch({
      type: TREATMENT_PLAN_CART,
      payload: clearedCart,
    });
  };
};

export { clear as clearTPCart, update as updateTPCart };
