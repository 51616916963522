import { ExperienceValidationKey } from 'lib/checkout-experience/validation';
import { ExperienceFlowStep } from 'models/alloy/experience';

import Renewal from 'pages/checkout-experience/renewal/Renewal';
import RenewalConfirmation from 'pages/checkout-experience/renewal/RenewalConfirmation';

export const renewalSteps: ExperienceFlowStep[] = [
  {
    validationKey: ExperienceValidationKey.renewal,
    component: Renewal,
    path: 'renewal',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.renewalConfirmation,
    component: RenewalConfirmation,
    path: 'renewal-confirmation',
    routeType: 'PRIVATE',
  },
];
