import { uniqBy } from 'lodash';

import { mhtSteps } from './mht';
import { otcSteps } from './otc';
import { renewalSteps } from './renewal';
import { standardSteps } from './standard';
import { addExpectationsStep } from './addExpectations';
import { store } from 'store';
import { RootState } from 'reducers';

const getAllCheckoutExperienceSteps = () => {
  const allSteps = [mhtSteps, otcSteps, renewalSteps, standardSteps, addExpectationsStep];

  const collatedAndUniq = uniqBy(
    allSteps.flatMap((s) => s),
    'path'
  );

  return collatedAndUniq;
};

export {
  getAllCheckoutExperienceSteps,
  mhtSteps,
  otcSteps,
  renewalSteps,
  standardSteps,
  addExpectationsStep,
};
