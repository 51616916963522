import { ShippingMethod, ShippingMethodType, ShippingSpeed } from 'common/dist/models/shipping';

const cleanFullShippingTitle = (method: ShippingMethodType): string => {
  switch (method) {
    case 'STANDARD': {
      return 'Standard shipping';
    }
    case 'TWO_DAY': {
      return 'Priority shipping';
    }
    case 'NEXT_DAY': {
      return 'Overnight shipping';
    }
    default: {
      return 'Standard shipping';
    }
  }
};

const cleanShippingMethod = (shippingSpeed: ShippingSpeed): string => {
  switch (shippingSpeed.shippingSpeedType) {
    case 'SHIP_NOW': {
      return 'Ship now';
    }
    case 'NEXT_SHIPMENT': {
      return 'Ship with my next';
    }
    default: {
      return shippingSpeed.description;
    }
  }
};

const cleanShippingTitle = (shippingMethod: ShippingMethod): string => {
  switch (shippingMethod.method) {
    case 'STANDARD': {
      return 'Standard Shipping (3-5 business days)';
    }
    case 'TWO_DAY': {
      return 'Priority Shipping (1-2 business days)';
    }
    case 'NEXT_DAY': {
      return 'Overnight Shipping';
    }
    default: {
      return shippingMethod.description;
    }
  }
};

const getNumberOfDays = (shippingMethod?: ShippingMethod): string => {
  if (!shippingMethod) {
    return '5-7 business days';
  }

  switch (shippingMethod.method) {
    case 'STANDARD': {
      return '5-7 business days';
    }
    case 'TWO_DAY': {
      return '3-5 business days';
    }
    case 'NEXT_DAY': {
      return '1-3 business days';
    }
    default: {
      return shippingMethod.description;
    }
  }
};

const minDaysFromShipping = (shippingMethod?: ShippingMethod): number => {
  if (!shippingMethod) {
    return 5;
  }

  switch (shippingMethod.method) {
    case 'STANDARD': {
      return 5;
    }
    case 'TWO_DAY': {
      return 3;
    }
    case 'NEXT_DAY': {
      return 1;
    }
    default: {
      return 3;
    }
  }
};

const maxDaysFromShipping = (shippingMethod?: ShippingMethod): number => {
  if (!shippingMethod) {
    return 7;
  }

  switch (shippingMethod.method) {
    case 'STANDARD': {
      return 7;
    }
    case 'TWO_DAY': {
      return 5;
    }
    case 'NEXT_DAY': {
      return 3;
    }
    default: {
      return 7;
    }
  }
};

export {
  cleanFullShippingTitle,
  cleanShippingTitle,
  getNumberOfDays,
  minDaysFromShipping,
  maxDaysFromShipping,
  cleanShippingMethod,
};
