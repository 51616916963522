import { capitalize, isEmpty } from 'lodash';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { useAppSelector } from 'reducers/alloy_reducer';
import { Form } from 'rsuite';

import BottomBar from 'components/checkout-experience/BottomBar';
import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import { SelectFieldsGroup } from 'components/core/fields/SelectFieldsGroup';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';

import Layout from 'containers/Layout';

import { SELECTIONS } from 'data/checkout-experience/content/relief-type';

import { getPercentComplete } from 'lib/checkout-experience/progress';

import { ExperienceComponentModel } from 'models/alloy/experience';
import posthog from 'posthog-js';

export default function Name({ onNext, onBack }: ExperienceComponentModel) {
  const location = useLocation();

  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

  const customer = useAppSelector((state) => state.alloy.customer);
  const localPreCustomer = useAppSelector((state) => state.experience.localPreCustomer);
  const showHairFlag = process.env.REACT_APP_FEATURE_FLAG_SHOW_HAIR_RELIEF_TYPE === 'true';

  const firstName = capitalize(customer ? customer.firstName : localPreCustomer.firstName) ?? '';

  const onSubmit = async () => {
    if (selectedId) {
      const foundSelection = SELECTIONS.find((s) => s.id === selectedId);

      if (foundSelection) {
        posthog.capture('reliefTypesSelected', {
          $set: {
            intake_categories: foundSelection.categories,
          },
        });

        onNext(foundSelection.categories);
      }
    }
  };

  const selections = SELECTIONS.filter((selection) => showHairFlag || selection.id !== '4').map(
    (s) => ({ text: s.text, id: s.id })
  );

  return (
    <Layout title='Select relief - Alloy' desc='' noBars>
      <TopBannerWithProgress onBack={onBack} percentComplete={getPercentComplete(location)} />

      <Form>
        <CheckoutExperienceSection>
          <h1 className='content-title'>
            {/* [ab-test:replace-first-step-with-relief-type] */}
            {isEmpty(firstName)
              ? 'What brings you to Alloy today?'
              : `Hi ${firstName}, what brings you to Alloy today?`}
          </h1>
          <p className='content-text'>Select the answer that best describes your concerns today.</p>

          <SelectFieldsGroup
            selectedIds={selectedId ? [selectedId] : []}
            onChange={(ids) => {
              const id = ids[ids.length - 1];

              setSelectedId(id);
            }}
            options={selections}
            isSingleSelect
          />
        </CheckoutExperienceSection>

        <BottomBar>
          <button
            className='primary-button'
            type='submit'
            disabled={!selectedId}
            onClick={onSubmit}
          >
            Next
          </button>
        </BottomBar>
      </Form>
    </Layout>
  );
}
