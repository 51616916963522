import { ShippingMethod, ShippingMethodType } from 'common/dist/models/shipping';
import { useState } from 'react';

import chevronDownIcon from 'assets/svg/core/chevron-down.svg';

import { convertCentsToDollars } from 'lib/shared/convert';
import { cleanShippingTitle } from 'lib/shared/shipping';

interface Props {
  prevShippingMethodType?: ShippingMethodType;
  shippingMethods: (ShippingMethod & { priceInCents: number })[];
  onSelectShipping: (method: ShippingMethod) => void;
}

export default function ShippingBlock({
  prevShippingMethodType = 'STANDARD',
  shippingMethods = [],
  onSelectShipping,
}: Props) {
  const [showShippingDetails, setShowShippingDetails] = useState<boolean>(false);

  const [selectedShippingType, setSelectedShippingType] =
    useState<ShippingMethodType>(prevShippingMethodType);

  const selectedShippingMethod = shippingMethods
    .sort((a, b) => a.priceInCents - b.priceInCents)
    .find((sm) => sm.method === selectedShippingType);

  const handleSelectingShipping = (sm: ShippingMethod) => {
    setSelectedShippingType(sm.method);
    onSelectShipping(sm);
  };

  return (
    <div className={`${showShippingDetails ? 'active-shipping' : ''}`}>
      {/* MARK: Shipping methods wrappers */}
      <div className='shipping-wrapper'>
        <p className='shipping-title'>Shipping Method</p>
        <button
          className='shipping-options-btn'
          onClick={() => setShowShippingDetails(!showShippingDetails)}
        >
          <p className='shipping-text'>
            <span className='shipping-shortened-text'>
              {selectedShippingMethod
                ? cleanShippingTitle(selectedShippingMethod)
                : 'Standard Shipping (3-5 business days)'}
            </span>
            <img src={chevronDownIcon} alt='chevron-down' />
          </p>
        </button>
      </div>

      <div className='shipping-options-wrapper'>
        {shippingMethods
          .sort((a, b) => a.priceInCents - b.priceInCents)
          .map((sm, index) => (
            <div
              key={index}
              className={`shipping-option ${sm.method === selectedShippingType ? 'selected' : ''}`}
              onClick={() => handleSelectingShipping(sm)}
            >
              <div className='option-content'>
                <p className='option-title'>{cleanShippingTitle(sm)}</p>
                <p className={`option-price ${sm.method === 'STANDARD' ? 'free' : ''}`}>
                  {sm.method === 'STANDARD' ? 'FREE' : `$${convertCentsToDollars(sm.priceInCents)}`}
                </p>
              </div>

              <div className='option-outer-circle'></div>
            </div>
          ))}
      </div>
    </div>
  );
}
