import { IntakeCategory } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { bindActionCreators } from 'redux';

import { updateRequestingProducts } from 'actions/checkout-experience/requesting_actions';

import drMennImg from 'assets/images/checkout-experience/review/dr-menn.png';

import ViewableProduct from 'components/checkout-experience/products/ViewableProduct';
import EditDrawer from '../EditDrawer';
import ProductReviewCardBlock from '../ProductReviewCardBlock';
import ReviewSection from '../ReviewSection';

import { SELECTIONS } from 'data/checkout-experience/content/relief-type';
import { retrieveCategoriesFromUrl } from 'lib/shared/experience';
import { sendExceptionToSentry } from 'lib/tracking/sentry';
import { filterProductsByCategories, getProductIdsFromGroupedProducts } from 'lib/shared/product';

import { useAppSelector } from 'reducers/alloy_reducer';

interface Props {
  onBack?: () => void;
  onNext: () => void;
}

export default function DoctorConsultContent({ onBack, onNext }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const categories = retrieveCategoriesFromUrl(location);
  const filteredCategories = SELECTIONS.filter(({ categories: c }) =>
    categories.some((category) => c.includes(category))
  ).map(({ text }) => text);

  const {
    alloyCart: cart,
    requestingProductIds,
    localPreCustomer,
  } = useAppSelector((state) => state.experience);
  const { isAuthenticated } = useAppSelector((state) => state.alloy);

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [mhtProducts, setMhtProducts] = useState<GroupedContentfulProduct[][]>([]);
  const [upsellProducts, setUpsellProducts] = useState<GroupedContentfulProduct[][]>([]);
  const [requestedProducts, setRequestedProducts] = useState<GroupedContentfulProduct[][]>([]);

  const dispatchUpdateRequesting = bindActionCreators(updateRequestingProducts, dispatch);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setIsLoading(true);

    const [fetchedRequested, fetchedMht, fetchedUpsell] = await Promise.all([
      ProductRegistry.get().getIndividualGroupedContentfulProductsFor(
        'specific',
        requestingProductIds
      ),
      ProductRegistry.get().getIndividualGroupedContentfulProductsFor('mht'),
      ProductRegistry.get().getIndividualGroupedContentfulProductsFor('upsell'),
    ]);

    // at this point, the customer has already filled out the intake, we need to only show them
    // non mht products that would line up with the categories filled out otherwise
    // we don't have submission data on upsell products not selected
    // we also want gut health no matter what
    const filteredUpsellProducts = filterProductsByCategories(
      fetchedUpsell,
      uniq(categories.concat('gut-health'))
    );

    setRequestedProducts(fetchedRequested);
    setMhtProducts(fetchedMht);
    setUpsellProducts(filteredUpsellProducts);

    setIsLoading(false);
  };

  const onSaveRequesting = (products: GroupedContentfulProduct[][]) => {
    const sortedRequestedProducts = ProductRegistry.get().sortGroupedContentfulProducts(products);

    setRequestedProducts(sortedRequestedProducts);

    dispatchUpdateRequesting(getProductIdsFromGroupedProducts(sortedRequestedProducts.flat()));

    setIsEditing(false);
  };

  const onContinue = async () => {
    try {
      setIsLoading(true);
      onNext();
    } catch (error) {
      setIsLoading(false);
      sendExceptionToSentry(error as Error);
    }
  };

  return (
    <ReviewSection
      isLoading={isLoading}
      onBack={onBack}
      isContinueDisabled={cart.products.length === 0}
      onContinue={onContinue}
    >
      <div className='content-header-wrapper'>
        <h1 className='content-title'>Review</h1>
      </div>

      <ProductReviewCardBlock
        topImg={drMennImg}
        title='Doctor Consultation'
        subtitle='$49.00 (1-time cost)'
        description='A menopause specialized doctor will review your intake and work with you to find a treatment that is the best for you.'
        benefitsList={filteredCategories}
      />

      <div className='content-header-wrapper'>
        <h1 className='header-title'>
          {requestedProducts.length === 1 ? 'Requested treatment' : 'Requested treatments'}
        </h1>

        <button className='secondary-border-button' onClick={() => setIsEditing(true)}>
          Edit
        </button>
      </div>

      {requestedProducts.length === 0 ? (
        <p className='content-text-md'>
          I would like the doctor to make a treatment recommendation.
        </p>
      ) : (
        <>
          <p className='content-text-md'>
            You won't be charged for or shipped prescription treatments unless a doctor prescribes
            it to you.
          </p>

          <div className='content-product-list'>
            {requestedProducts.map((gcpList, index) =>
              gcpList.map((gcp) => (
                <ViewableProduct key={index} groupedProduct={gcp} hideDetails showProgDetails />
              ))
            )}
          </div>
        </>
      )}

      <EditDrawer
        open={isEditing}
        setOpen={setIsEditing}
        onSave={onSaveRequesting}
        productsWithType={{
          mht: mhtProducts,
          upsell: upsellProducts,
        }}
        prevSelectedProducts={requestedProducts.flat()}
      />
    </ReviewSection>
  );
}
