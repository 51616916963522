interface Props {
  steps: string[];
}

export default function Steps({ steps }: Props) {
  return (
    <div className='pt-md-4 pt-2'>
      {steps.map((step, idx) => (
        <div className='step-container'>
          <div className='step-number'>
            <span>{idx + 1}</span>
          </div>
          <div className='step-content'>
            <p>{step}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
