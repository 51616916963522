import classNames from 'classnames';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import ViewableProduct from 'components/checkout-experience/products/ViewableProduct';

export type MhtChangeType = 'CUSTOMER_FORM_FACTOR_CHANGE' | 'CUSTOMER_DOSAGE_CHANGE';

interface Props {
  product: GroupedContentfulProduct;
  selectedType: MhtChangeType | undefined;
  setSelectedType: (type: MhtChangeType) => void;
}

export default function HeaderBlock({ product, selectedType, setSelectedType }: Props) {
  return (
    <div className='request-content-wrapper'>
      <p className='content-title'>Your current treatment</p>

      <div className='request-products-wrapper'>
        <ViewableProduct groupedProduct={product} showDosage hideProductInformation hideDetails />
      </div>

      <div className='request-products-buttons'>
        <button
          className={classNames(
            'secondary-border-button',
            selectedType === 'CUSTOMER_FORM_FACTOR_CHANGE' && 'selected-button'
          )}
          onClick={() => setSelectedType('CUSTOMER_FORM_FACTOR_CHANGE')}
        >
          Change treatment
        </button>

        <button
          className={classNames(
            'secondary-border-button',
            selectedType === 'CUSTOMER_DOSAGE_CHANGE' && 'selected-button'
          )}
          onClick={() => setSelectedType('CUSTOMER_DOSAGE_CHANGE')}
        >
          Change dosage
        </button>
      </div>
    </div>
  );
}
