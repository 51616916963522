import {
  SubscriptionWithRenewal,
  reactivateSubscription,
  startRenewalAlert,
  unnpauseSubscription,
  useGetAllSubscriptionsForCustomer,
  useGetPendingMhtSwitch,
  useIsProcessing,
} from 'client/dist/generated/alloy';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { Loader } from 'rsuite';

import { updateToNewCheckoutExperienceFlow } from 'actions/checkout-experience/flow_actions';

import { showDashboardAlert } from 'components/core/Notification';

import {
  isAllPrescriptionExist,
  isAllPrescriptionsActive,
  isAllPrescriptionsExpired,
  isSomeNeedRenew,
} from 'lib/dashboard/prescription';
import { getExpiringPrescriptionsFromAll } from 'lib/dashboard/subscription';

import { useAppSelector } from 'reducers/alloy_reducer';

interface Props {
  shipment: SubscriptionWithRenewal;
  setLoading: (isLoading: boolean) => void;
}

export default function HeaderButtonBlock({ shipment, setLoading }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: shipments = [], mutate: mutateShipments } = useGetAllSubscriptionsForCustomer();
  const {
    data: isProcessing,
    isLoading: isLoadingProcessing,
    mutate: mutateIsProcessing,
  } = useIsProcessing(shipment.stripeSubscriptionId);
  const { data: pendingMhtSwitch, isLoading: isLoadingPendingMhtSwitch } = useGetPendingMhtSwitch();

  // a customer might have either their sub being processed OR they might have some mht switch processing, we don't want to allow them to
  // make changes to sub if it is processing that way they don't ship some product while waiting for doc
  const isProcessingData =
    isProcessing ||
    (shipment.products.some((pfr) => pfr.product.category === 'mht') &&
      (pendingMhtSwitch?.isSwitchDose || pendingMhtSwitch?.isSwitchFormFactor));

  const mutate = () => Promise.all([mutateShipments, mutateIsProcessing]);

  const dispatchUpdateToNewCheckoutExperience = bindActionCreators(
    updateToNewCheckoutExperienceFlow,
    dispatch
  );

  const allPrescriptionsActive = isAllPrescriptionsActive(shipment);
  const somePrescriptionsNeedRenewal = isSomeNeedRenew(shipment);
  const allPrescriptionsExpired = isAllPrescriptionsExpired(shipment);

  const allPrescriptionsExist = isAllPrescriptionExist(shipment);

  // Renewal Flags
  const renewalCompleteLocally = useAppSelector((state) => state.experience.renewalCompleteLocally);
  const needsRenewal = !allPrescriptionsActive || somePrescriptionsNeedRenewal;

  const onManageShipment = () => {
    history.push(`/shipments/upcoming/${shipment.stripeSubscriptionId}`);
  };

  const onResumeAndMaybeRenew = async () => {
    try {
      window.scrollTo(0, 0);

      setLoading(true);

      const { stripeSubscriptionId } = shipment;

      await unnpauseSubscription(stripeSubscriptionId);
      await mutate();

      if (needsRenewal && !renewalCompleteLocally) {
        onRenew();
      } else {
        showDashboardAlert('Your shipment has successfully been resumed', 'success');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const onReactivate = async () => {
    try {
      window.scrollTo(0, 0);

      setLoading(true);

      const { stripeSubscriptionId } = shipment;

      await reactivateSubscription(stripeSubscriptionId);
      await mutate();

      if (allPrescriptionsExpired || somePrescriptionsNeedRenewal) {
        onRenew();
      } else {
        showDashboardAlert('Your shipment has successfully been reactivated', 'success');
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onRenew = () => {
    const expiredProductFrequencies = getExpiringPrescriptionsFromAll(shipments);

    const expiredProductNames = expiredProductFrequencies.map((pf) => pf.name);

    startRenewalAlert({ productNames: expiredProductNames });

    dispatchUpdateToNewCheckoutExperience(['renewal'], history);
  };

  const { status } = shipment;

  if (status === 'ACTIVE') {
    if (needsRenewal && !renewalCompleteLocally) {
      return (
        <button className='btn btn-primary' onClick={onResumeAndMaybeRenew}>
          Renew
        </button>
      );
    } else {
      if (isLoadingProcessing || isLoadingPendingMhtSwitch) {
        return <Loader />;
      }

      return isProcessingData ? (
        <p className='btn-header-orange-bold'>Processing</p>
      ) : (
        <button className='btn btn-primary' onClick={onManageShipment}>
          Manage shipment
        </button>
      );
    }
  } else if (status === 'PAUSED') {
    if (needsRenewal && !renewalCompleteLocally) {
      return (
        <button className='btn btn-primary' onClick={onResumeAndMaybeRenew}>
          Renew
        </button>
      );
    } else {
      return (
        <button className='btn btn-primary' onClick={onResumeAndMaybeRenew}>
          Resume
        </button>
      );
    }
  } else {
    if (!allPrescriptionsActive || !allPrescriptionsExist) {
      return (
        <p className='btn-header-text'>
          To reactivate, please reach out to{' '}
          <a href='mailto:support@myalloy.com' className='header-link'>
            support@myalloy.com
          </a>
        </p>
      );
    } else {
      return (
        <button className='btn btn-primary' onClick={onReactivate}>
          Reactivate
        </button>
      );
    }
  }
}
