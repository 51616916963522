import { ExperienceCategory } from 'common/dist/models/experience';
import { first, uniq } from 'lodash';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  buildCheckoutExperience,
  updateToNewCheckoutExperienceFlow,
} from 'actions/checkout-experience/flow_actions';
import { initABTesting } from 'actions/core/ab_testing_actions';
import { updateLocalPreCustomer } from 'actions/core/app_actions';

import Loader from 'components/core/Loader';
import {
  AuthFlowRoute,
  PrivateFlowRoute,
  PublicFlowRoute,
} from 'components/routes/HandleExperience';

import { getAllCheckoutExperienceSteps } from 'data/checkout-experience/steps';

import { getBuiltFlowFromCategories, retrieveFlowFromUrl } from 'lib/checkout-experience/flow';
import { isAvSyncRequired } from 'lib/checkout-experience/identity/avSync';
import { formatExperienceURL } from 'lib/core/url';
import {
  getAllPathsFromFlow,
  getCurrentStepIndex,
  retrieveCategoriesFromUrl,
} from 'lib/shared/experience';
import { sendExceptionToSentry } from 'lib/tracking/sentry';

import { useAppSelector } from 'reducers/alloy_reducer';

export default function CheckoutExperience() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const { isAuthenticated, customer } = useAppSelector((state) => state.alloy);
  const { licenseExists, localPreCustomer } = useAppSelector((state) => state.experience);

  const dispatchBuildCheckoutExperience = bindActionCreators(buildCheckoutExperience, dispatch);
  const dispatchUpdateToNewCheckoutExperience = bindActionCreators(
    updateToNewCheckoutExperienceFlow,
    dispatch
  );
  const dispatchUpdateLocalPreCustomer = bindActionCreators(updateLocalPreCustomer, dispatch);
  const dispatchInitABTesting = bindActionCreators(initABTesting, dispatch);

  useEffect(() => {
    const loadCheckoutExperience = async () => {
      try {
        // get any ab testing values when loading in the experience, should only happen once per flow load
        await dispatchInitABTesting();

        const retrievedCategories = retrieveCategoriesFromUrl(location);

        posthog.capture('checkoutExperienceStarted', {
          $set: {
            intake_categories: retrievedCategories,
          },
        });

        const url = location.pathname;

        await dispatchBuildCheckoutExperience(url, retrievedCategories, history);

        setLoading(false);
      } catch (error) {
        sendExceptionToSentry(error as Error);
      }
    };

    loadCheckoutExperience();
  }, []);

  const onNext = (newFlowCategories: ExperienceCategory[] | undefined = []) => {
    const retrievedCategories = retrieveCategoriesFromUrl(location);
    const retrievedFlow = retrieveFlowFromUrl(location);

    const urls = getAllPathsFromFlow(retrievedFlow);
    const currentIndex = getCurrentStepIndex(location.pathname, retrievedFlow);

    let nextStep = Math.min(currentIndex + 1, retrievedFlow.steps.length - 1);

    const key = retrievedFlow.steps[currentIndex].validationKey;

    const isCategoriesValidated = localPreCustomer.experienceValidation?.categories.every((c) =>
      retrievedCategories.includes(c)
    );

    const prevStepsViewed = isCategoriesValidated
      ? localPreCustomer.experienceValidation?.stepsViewed ?? []
      : [];

    const updatedStepsViewed = uniq([...prevStepsViewed, key]);

    dispatchUpdateLocalPreCustomer({
      experienceValidation: {
        date: new Date().toISOString(),
        categories:
          newFlowCategories && newFlowCategories.length !== 0
            ? newFlowCategories
            : retrievedCategories,
        stepsViewed: updatedStepsViewed,
      },
    });

    if (newFlowCategories && newFlowCategories.length !== 0) {
      const newFlow = getBuiltFlowFromCategories(newFlowCategories);
      const currentIndex = getCurrentStepIndex(location.pathname, newFlow);

      const updatedNextStep = Math.min(currentIndex + 1, newFlow.steps.length - 1);

      dispatchUpdateToNewCheckoutExperience(newFlowCategories, history, updatedNextStep);
    } else {
      if (isAuthenticated) {
        // MARK: Not allow authenticated users to go to register or verification (unauth) pages
        // TODO authentication/identifying the customer should be the "step" - so the flow logic
        // gets to stay clean. that step could bypass or short circuit itself if unnecessary
        while (
          retrievedFlow.steps[nextStep].path === 'register' ||
          retrievedFlow.steps[nextStep].path === 'verification' ||
          (retrievedFlow.steps[nextStep].path === 'verify-identity' && licenseExists)
        ) {
          nextStep = Math.min(nextStep + 1, retrievedFlow.steps.length - 1);
        }

        // MARK: If a user logs in on the register page, we want to make sure when they land on verify-identity or whatever is next that the next step is incremented
        if (urls[currentIndex] === retrievedFlow.steps[nextStep].path) {
          nextStep = Math.min(nextStep + 1, retrievedFlow.steps.length - 1);
        }
      }

      // this skips av sync
      if (
        retrievedFlow.steps[nextStep].path === 'upload-video' &&
        !isAvSyncRequired(customer?.stateAbbr ?? '')
      ) {
        nextStep += 1;
      }

      history.push(
        formatExperienceURL(
          `/checkout-experience/${retrievedFlow.steps[nextStep].path}`,
          location,
          retrievedCategories
        )
      );
    }
  };

  const onBack = () => {
    const retrievedCategories = retrieveCategoriesFromUrl(location);
    const retrievedFlow = retrieveFlowFromUrl(location);

    const currentIndex = getCurrentStepIndex(location.pathname, retrievedFlow);

    let prevStep = Math.max(currentIndex - 1, 0);

    // dropping a note that when going back, we need to start from top of flow and go backwards when
    // going down a prev step

    // this skips av sync
    if (
      retrievedFlow.steps[prevStep].path === 'upload-video' &&
      !isAvSyncRequired(customer?.stateAbbr ?? '')
    ) {
      prevStep -= 1;
    }

    if (isAuthenticated) {
      while (
        retrievedFlow.steps[prevStep].path === 'register' ||
        retrievedFlow.steps[prevStep].path === 'verification' ||
        (retrievedFlow.steps[prevStep].path === 'verify-identity' && licenseExists)
      ) {
        prevStep = Math.max(prevStep - 1, 0);
      }
    }

    // if the current index is greater than 0 (0 = first step of the flow), we want to take them back in
    // the flow otherwise take them back in history
    if (prevStep >= 0 && currentIndex > 0) {
      history.push(
        formatExperienceURL(
          `/checkout-experience/${retrievedFlow.steps[prevStep].path}`,
          location,
          retrievedCategories
        )
      );
    } else {
      history.goBack();
    }
  };

  if (loading) return <Loader />;

  const retrievedFlow = retrieveFlowFromUrl(location);
  const firstStep = first(retrievedFlow.steps);

  return (
    <>
      {getAllCheckoutExperienceSteps().map((step, index) => {
        const path = '/' + step.path;

        // for pages that are the FIRST in the list of their desginated steps
        // we need to not display an onBack button in the ui otherwise it could end in
        // going back and forth with the history since history in onBack above
        // goes by push and then goBack so doing what we had with Name initially,
        // this will always hide the first step back button!!
        const handleOnBack =
          firstStep && firstStep.validationKey === step.validationKey ? undefined : onBack;

        switch (step.routeType) {
          case 'AUTH':
            return (
              <AuthFlowRoute
                key={index}
                component={step.component}
                basePath='/checkout-experience'
                path={path}
                onNext={onNext}
                onBack={handleOnBack}
                location={location}
                isAuthenticated={isAuthenticated}
                customer={customer}
              />
            );

          case 'PRIVATE':
            return (
              <PrivateFlowRoute
                key={index}
                component={step.component}
                basePath='/checkout-experience'
                path={path}
                onNext={onNext}
                onBack={handleOnBack}
                location={location}
                isAuthenticated={isAuthenticated}
                customer={customer}
              />
            );

          default:
            return (
              <PublicFlowRoute
                key={index}
                component={step.component}
                basePath='/checkout-experience'
                path={path}
                onNext={onNext}
                onBack={handleOnBack}
              />
            );
        }
      })}
    </>
  );
}
