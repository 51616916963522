import { getRecentSubmission, runSuggestions } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { xor } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { bindActionCreators } from 'redux';

import { updateCart } from 'actions/checkout-experience/cart_actions';

import BundleSelectableProduct from 'components/checkout-experience/products/BundleSelectableProduct';
import ReviewSection from '../ReviewSection';

import { retrieveIntakeCategoriesFromUrl } from 'lib/shared/experience';

import { cleanPurchasableProducts } from 'lib/request-experience/flow';
import { sendExceptionToSentry } from 'lib/tracking/sentry';
import SelectableProduct from 'components/checkout-experience/products/SelectableProduct';
import ProductRegistry from 'client/dist/product/productRegistry';
import { useAppSelector } from 'reducers/alloy_reducer';
import {
  getProductIdsFromGroupedProducts,
  getProductToBeBundledWith,
  sortGroupedProductsByCategories,
} from 'lib/shared/product';

interface Props {
  onBack?: () => void;
  onNext: () => void;
}

export default function BundledContent({ onBack, onNext }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);
  const [flowProducts, setFlowProducts] = useState<GroupedContentfulProduct[][]>([]);

  const localPreCustomer = useAppSelector((state) => state.experience.localPreCustomer);
  const isAuthenticated = useAppSelector((state) => state.alloy.isAuthenticated);

  const dispatchUpdateCart = bindActionCreators(updateCart, dispatch);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setIsLoading(true);

    let submissionId = localPreCustomer.alloySubmissionId ?? '';
    const intakeCategories = retrieveIntakeCategoriesFromUrl(location);

    if (isAuthenticated) {
      const intakeCategories = retrieveIntakeCategoriesFromUrl(location);

      const recentSubmission = await getRecentSubmission({ categories: intakeCategories });

      submissionId = recentSubmission.id!;
    }

    const suggestions = await runSuggestions({ submissionId });

    const qualifiedProductIds = suggestions.qualified.map((q) => q.product.productId);

    const fetchedProducts = await ProductRegistry.get().getRecurringProductsForV2(
      qualifiedProductIds
    );

    const sortedProducts = sortGroupedProductsByCategories(fetchedProducts, intakeCategories);

    setFlowProducts(sortedProducts);
    setSelectedProductIds(getProductIdsFromGroupedProducts(fetchedProducts.flat()));

    setIsLoading(false);
  };

  const onSelect = (groupedProduct: GroupedContentfulProduct) => {
    setSelectedProductIds(
      xor(
        selectedProductIds,
        groupedProduct.alloyProduct.parent.map((pf) => pf.productId)
      )
    );
  };

  const isParentSelected = (parent?: GroupedContentfulProduct) => {
    return (
      !!parent &&
      selectedProductIds.some((pid) =>
        parent.alloyProduct.parent.every((pf) => pf.productId === pid)
      )
    );
  };

  const onContinue = async () => {
    try {
      setIsLoading(true);

      const products = await cleanPurchasableProducts(selectedProductIds, []);

      dispatchUpdateCart({ products });

      onNext();
    } catch (error) {
      setIsLoading(false);
      sendExceptionToSentry(error as Error);
    }
  };

  return (
    <ReviewSection
      isLoading={isLoading}
      onBack={onBack}
      isContinueDisabled={selectedProductIds.length === 0}
      onContinue={onContinue}
    >
      <div className='content-header-wrapper'>
        <h1 className='content-title'>
          {flowProducts.length === 1 ? 'Your treatment' : 'Your treatments'}
        </h1>
      </div>

      {flowProducts.map((gcpList, listIndex) => {
        return gcpList.map((gcp, index) => {
          const isSelected = selectedProductIds.some((pid) =>
            gcp.alloyProduct.parent.every((pf) => pf.productId === pid)
          );
          const parent = getProductToBeBundledWith(gcp, flowProducts);

          return !!parent ? (
            <BundleSelectableProduct
              key={`${listIndex}-${index}`}
              groupedProduct={gcp}
              isSelected={isSelected}
              onSelect={onSelect}
              isParentSelected={isParentSelected(parent)}
              parent={parent}
            />
          ) : (
            <SelectableProduct
              key={`${listIndex}-${index}`}
              groupedProduct={gcp}
              isSelected={isSelected}
              onSelect={onSelect}
              multiSelect
            />
          );
        });
      })}
    </ReviewSection>
  );
}
