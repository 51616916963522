import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import Divider from '../Divider';
import SelectableProduct from '../products/SelectableProduct';
import TextSelectableProduct from './TextSelectableProduct';

import { RequestingProductIds } from 'reducers/experience_reducer';

interface Props {
  products: GroupedContentfulProduct[];
  requestedProductIds: RequestingProductIds;
  onSelect: (groupedProduct?: GroupedContentfulProduct) => void;
}

export default function SelectableProductsGroup({
  products,
  requestedProductIds,
  onSelect,
}: Props) {
  return (
    <>
      <TextSelectableProduct isSelected={requestedProductIds.length === 0} onSelect={onSelect} />

      <Divider text='Or' />

      {products.map((gcp, index) => {
        const isSelected = requestedProductIds.some((pid) =>
          gcp.alloyProduct.parent.every((pf) => pf.productId === pid)
        );

        return (
          <SelectableProduct
            key={index}
            groupedProduct={gcp}
            isSelected={isSelected}
            onSelect={onSelect}
            multiSelect
            hideDetails
            showProgDetails
          />
        );
      })}
    </>
  );
}
