import BottomBar from 'components/checkout-experience/BottomBar';
import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import Mht from 'components/checkout-experience/expectations/Mht';
import Sex from 'components/checkout-experience/expectations/Sex';
import Skin from 'components/checkout-experience/expectations/Skin';

import Layout from 'containers/Layout';
import { getPercentComplete } from 'lib/checkout-experience/progress';
import formatReviewCards from 'lib/core/formatReviewCards';
import { getReviews } from 'lib/shared/contentful';
import { retrieveCategoriesFromUrl } from 'lib/shared/experience';
import { ExperienceComponentModel } from 'models/alloy/experience';
import { ContentfulReview } from 'models/contentful/reviews';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

export default function CategorySpecificExpectations({ onNext, onBack }: ExperienceComponentModel) {
  const [reviews, setReviews] = useState<ContentfulReview[] | undefined>(undefined);

  const location = useLocation();

  // ensure 'categories' only updates when the relevant part of location updates
  const categories = useMemo(() => retrieveCategoriesFromUrl(location), [location.pathname]);

  const isMhtIntake = categories.includes('mht');
  const isSexIntake = categories.includes('sexual-health') || categories.includes('vaginal-health');
  const isSkinIntake = categories.includes('skin-health');

  useEffect(() => {
    getTrustPilotReviews();
  }, [categories]);

  const getTrustPilotReviews = async () => {
    const reviewType = isSexIntake ? 'home' : 'skincare';
    const reviews = await getReviews(reviewType);

    setReviews(reviews);
  };

  const formattedReviews = useMemo(() => formatReviewCards(reviews || []), [reviews]);

  const onSubmit = () => {
    onNext();
  };

  return (
    <Layout title='Expectations - Alloy' desc='' noBars>
      <TopBannerWithProgress percentComplete={getPercentComplete(location)} onBack={onBack} />
      <div className='pb-6'>
        {isMhtIntake && <Mht />}
        {isSkinIntake && <Skin reviews={formattedReviews} />}
        {isSexIntake && <Sex reviews={formattedReviews} />}
      </div>
      <BottomBar>
        <button className='primary-button' type='submit' onClick={onSubmit}>
          Start intake
        </button>
      </BottomBar>
    </Layout>
  );
}
