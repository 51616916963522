import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Loader } from 'rsuite';

import symptomQuestionnaire from 'common/src/questionnaire/symptomsurvey.json';

import { useAppSelector } from 'reducers/alloy_reducer';

import {
  Customer,
  Submission,
  SubmissionAnswer,
  postPreIdentifiedSubmission,
  postSubmission,
} from 'client/dist/generated/alloy';
import QuestionnaireRoutes from 'components/checkout-experience/questionnaire/QuestionnaireRoutes';

export default function SymptomSurvey() {
  const history = useHistory();

  const dateStarted = new Date();

  const [isLoading, setIsLoading] = useState(false);

  const customer: Customer | undefined = useAppSelector((state) => state.alloy.customer);

  const onBack = () => {
    history.goBack();
  };

  const onSubmit = async (answers: SubmissionAnswer[]) => {
    try {
      setIsLoading(true);

      const submission: Submission = {
        categories: ['symptom-survey'],
        started: dateStarted.toISOString(),
        answers: answers,
      };

      if (customer) {
        await postSubmission(submission);
      } else {
        await postPreIdentifiedSubmission(submission);
      }

      history.push('/');
    } catch (error) {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <QuestionnaireRoutes
      questions={symptomQuestionnaire.questions}
      previousResults={{}}
      onSubmit={onSubmit}
      onBack={onBack}
      hideProgressBar
      parentUrlPath='/symptomsurvey'
      metaTitle='Symptom Survey | Alloy'
    />
  );
}
