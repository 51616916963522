import {
  M4_PRODUCT_ID,
  OMAZING_PRODUCT_ID,
  ORAL_MINOXIDIL_005_PRODUCT_ID,
  ORAL_MINOXIDIL_01_PRODUCT_ID,
  ORAL_MINOXIDIL_02_PRODUCT_ID,
  SYNBIOTIC_PRODUCT_ID,
  TRETINOIN_03_ID,
  TRETINOIN_06_ID,
  TRETINOIN_09_ID,
  VAGINAL_CREAM_PRODUCT_ID,
} from 'common/dist/products/productRegistry';
import mhtQuestionnaire from 'common/dist/questionnaire/mht.json';
import sexualHealthQuestionnaire from 'common/dist/questionnaire/sexual-health.json';
import skinHealthQuestionnaire from 'common/dist/questionnaire/skin-health.json';
import hairHealthQuestionnaire from 'common/dist/questionnaire/hair-health.json';
import vaginalHealthQuestionnaire from 'common/dist/questionnaire/vaginal-health.json';
import { ExperienceValidationKey } from 'lib/checkout-experience/validation';

import { ExperienceFlowStep, ExperienceFlowsDict } from 'models/alloy/experience';
import Review from 'pages/checkout-experience/shared/Review';
import UploadVideo from 'pages/checkout-experience/shared/UploadVideo';
import VerifyIdentity from 'pages/checkout-experience/shared/VerifyIdentity';

import Checkout from 'pages/request-experience/Checkout';
import Intake from 'pages/request-experience/shared/Intake';

export const mhtSteps: ExperienceFlowStep[] = [
  {
    validationKey: ExperienceValidationKey.intake,
    component: Intake,
    path: 'intake',
    routeType: 'PRIVATE',
  },
];

export const requestSteps: ExperienceFlowStep[] = [
  {
    validationKey: ExperienceValidationKey.intake,
    component: Intake,
    path: 'intake',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.uploadId,
    component: VerifyIdentity,
    path: 'verify-identity',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.uploadVideo,
    component: UploadVideo,
    path: 'upload-video',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.checkout,
    component: Checkout,
    path: 'checkout',
    routeType: 'PRIVATE',
  },
];

export const requestStepsWithReview: ExperienceFlowStep[] = [
  {
    validationKey: ExperienceValidationKey.intake,
    component: Intake,
    path: 'intake',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.uploadId,
    component: VerifyIdentity,
    path: 'verify-identity',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.uploadVideo,
    component: UploadVideo,
    path: 'upload-video',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.review,
    component: Review,
    path: 'review',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.checkout,
    component: Checkout,
    path: 'checkout',
    routeType: 'PRIVATE',
  },
];

export const requestFlows: ExperienceFlowsDict = {
  mht: {
    intakeQuestions: mhtQuestionnaire.questions,
    steps: mhtSteps,
    productIds: [],
  },
  'sexual-health': {
    intakeQuestions: sexualHealthQuestionnaire.questions,
    steps: requestStepsWithReview,
    productIds: [OMAZING_PRODUCT_ID],
  },
  'skin-health': {
    intakeQuestions: skinHealthQuestionnaire.questions,
    steps: requestStepsWithReview,
    productIds: [M4_PRODUCT_ID, TRETINOIN_03_ID, TRETINOIN_06_ID, TRETINOIN_09_ID],
  },
  'gut-health': {
    steps: requestSteps,
    productIds: [SYNBIOTIC_PRODUCT_ID],
  },
  'vaginal-health': {
    intakeQuestions: vaginalHealthQuestionnaire.questions,
    steps: requestStepsWithReview,
    productIds: [VAGINAL_CREAM_PRODUCT_ID],
  },
  'hair-health': {
    intakeQuestions: hairHealthQuestionnaire.questions,
    steps: requestSteps,
    productIds: [
      ORAL_MINOXIDIL_005_PRODUCT_ID,
      ORAL_MINOXIDIL_01_PRODUCT_ID,
      ORAL_MINOXIDIL_02_PRODUCT_ID,
    ],
  },
};
