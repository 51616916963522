import * as braze from '@braze/web-sdk';
import { Customer } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { ExperienceCategory } from 'common/dist/models/experience';
import dayjs from 'dayjs';
import { CartAbandonEventType } from 'models/alloy/cart-abandon/cartAbandonEvent';
import { LocalPreCustomer } from 'reducers/experience_reducer';

export const brazeInit = () => {
  braze.initialize(process.env.REACT_APP_BRAZE_API_KEY!!, {
    baseUrl: process.env.REACT_APP_BRAZE_SDK_ENDPOINT!!,
  });
};

export const identifyBraze = (customer: Customer) => {
  braze.changeUser(customer.patientId);
  braze.openSession();
};

export const brazeLogout = () => {
  braze.wipeData();
  brazeInit();
};

export type BrazeCustomer = Partial<
  Pick<Customer, 'email' | 'firstName' | 'lastName' | 'phoneNumber' | 'stateAbbr'> &
    Pick<LocalPreCustomer, 'state'>
>;

/**
 * Clean up string value by trimming and lowercasing (optionally)
 * @param s
 * @param shouldLower - should we lowercase too
 */
export const cleanForBraze = (s: string | undefined, shouldLower: boolean = false) => {
  if (!s) {
    return undefined;
  }

  const trimmed = s.trim();

  if (trimmed === '') {
    return undefined;
  }

  if (shouldLower) {
    return trimmed.toLocaleLowerCase();
  } else {
    return trimmed;
  }
};

const setForBraze = (
  customer: BrazeCustomer,
  k: keyof BrazeCustomer,
  brazeSetter: (cleaned: string) => void,
  lower: boolean = false
) => {
  const cleaned = cleanForBraze(customer[k], lower);

  cleaned && brazeSetter(cleaned);
};

// TODO we may want to ask braze to merge
// a preidentified customer here

/**
 * This gets called frequently in the front end, meaning braze will often get the latest and greatest
 * values from our API.
 *
 * Note that this can be invoked with either `LocalPreCustomer` or `Customer` (we should consolidate these),
 * and there are probably a few more fields to consider.
 *
 * Their web SDK seems to be their more standardized path for web applications, so we let this do the
 * heavy lifting (if roundabout).
 *
 * @param customer
 */
export const brazeSetPartial = async (customer: BrazeCustomer) => {
  const user = braze.getUser();

  if (!user) {
    console.error('Braze user was not defined');
    return;
  }

  setForBraze(
    customer,
    'email',
    (s) => {
      user.setEmail(s);
      user.addAlias(s, 'email');
    },
    true
  );
  setForBraze(customer, 'firstName', (s) => user.setFirstName(s));
  setForBraze(customer, 'lastName', (s) => user.setLastName(s));
  setForBraze(customer, 'phoneNumber', (s) => user.setPhoneNumber(s));
};

/**
 * get the braze user and check if the abandon event is one of "REGISTRATION_COMPLETE",
 * "CHECKOUT_SHOWN" and "CHECKOUT_COMPLETED".
 * if so, set the `intakeCategories` attribute on braze user to an object containing
 * the categories (and the product ids if it's from a request experience flow)
 *
 * @param abandonEvent
 * @param categories
 * @param avRequiredState
 * @param productIds
 * @param url
 * @returns
 */
export const brazeSendAbandonEvent = async (
  abandonEvent: CartAbandonEventType,
  categories: ExperienceCategory[],
  avRequiredState: string | undefined,
  productIds: number[] = [],
  url: string
) => {
  const user = braze.getUser();

  if (!user) {
    console.error('Braze user was not defined');
    return;
  }

  // used in Braze to direct the customer back to the point they left

  // TODO silly to have a switch that only ever does one thing
  // change invocations to only fire on reg/checkout shown/checkout completed

  switch (abandonEvent) {
    case 'REGISTRATION_COMPLETE':
    case 'CHECKOUT_SHOWN':
    case 'CHECKOUT_COMPLETED':
      const intakeEvent = await getIntakeEvent(categories, abandonEvent, productIds);
      user.setCustomUserAttribute('intakeCategoriesV2', intakeEvent, true);
      break;
    default:
      break;
  }

  return braze.logCustomEvent(abandonEvent, {
    avRequiredState,
    returnUrl: url,
  });
};

/**
 * through an array of categories, an abandon event type and a array of product ids
 * which returns an intakeEvent dictionary with the current categories as the key and all
 * the params in an object as the value.
 * @param categories
 * @param abandonEvent
 * @param productIds
 * @returns
 */
const getIntakeEvent = async (
  categories: ExperienceCategory[],
  abandonEvent: CartAbandonEventType,
  productIds: number[]
) => {
  const alloyProducts = await ProductRegistry.get().alloyProducts;
  const intakeEvent: { [key: string]: object } = {};
  const products = productIds.map((pid) => alloyProducts.find((pf) => pf.productId === pid));

  for (const category of categories) {
    const filteredProducts = products
      .filter((p) => p?.category === category)
      .map((p) => p?.cleanName);

    intakeEvent[category] = {
      createdAt: dayjs().toString(),
      event: abandonEvent,
      products: filteredProducts,
    };
  }

  return intakeEvent;
};
