import Loader from 'components/core/Loader';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { bindActionCreators } from 'redux';

import { buildRequestExperience } from 'actions/request-experience/flow_actions';

import { PrivateFlowRoute } from 'components/routes/HandleExperience';

import { requestStepsWithReview } from 'data/request-experience/flow';

import { isAvSyncRequired } from 'lib/checkout-experience/identity/avSync';
import { formatExperienceURL } from 'lib/core/url';
import { retrieveFlowFromUrl, retrieveProductIdsFromUrl } from 'lib/request-experience/flow';
import { getCurrentStepIndex, retrieveCategoriesFromUrl } from 'lib/shared/experience';
import { sendExceptionToSentry } from 'lib/tracking/sentry';

import { useAppSelector } from 'reducers/alloy_reducer';

export default function RequestExperience() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const isAuthenticated = useAppSelector((state) => state.alloy.isAuthenticated);
  const customer = useAppSelector((state) => state.alloy.customer);
  const licenseExists = useAppSelector((state) => state.experience.licenseExists);

  const dispatchBuildRequestExperience = bindActionCreators(buildRequestExperience, dispatch);

  useEffect(() => {
    const loadRequestExperience = async () => {
      try {
        const retrievedCategories = retrieveCategoriesFromUrl(location);
        const retrievedRequestedProductIds = retrieveProductIdsFromUrl(location);

        const url = location.pathname;

        await dispatchBuildRequestExperience(
          url,
          retrievedCategories,
          retrievedRequestedProductIds,
          history
        );

        setIsLoading(false);
      } catch (error) {
        sendExceptionToSentry(error as Error);
      }
    };

    loadRequestExperience();
  }, []);

  const onNext = () => {
    const retrievedCategories = retrieveCategoriesFromUrl(location);
    const retrievedFlow = retrieveFlowFromUrl(location);
    const retrievedRequestedProductIds = retrieveProductIdsFromUrl(location);

    const currentIndex = getCurrentStepIndex(location.pathname, retrievedFlow);

    let nextStep = Math.min(currentIndex + 1, retrievedFlow.steps.length - 1);

    // add check if upload id exists and skip them if so
    if (retrievedFlow.steps[nextStep].path === 'verify-identity' && licenseExists) {
      nextStep += 1;
    }

    // this skips av sync
    if (
      retrievedFlow.steps[nextStep].path === 'upload-video' &&
      !isAvSyncRequired(customer?.stateAbbr ?? '')
    ) {
      nextStep += 1;
    }

    // in the instance we are going to a review page and only have 1 product in the url, then we skip that page
    // this only happens for skin health atm too but we can just check product length
    if (
      retrievedFlow.steps[nextStep].path === 'review' &&
      retrievedRequestedProductIds.length === 1
    ) {
      nextStep += 1;
    }

    history.push(
      formatExperienceURL(
        `/request-experience/${retrievedFlow.steps[nextStep].path}`,
        location,
        retrievedCategories
      )
    );
  };

  const onBack = () => {
    const retrievedCategories = retrieveCategoriesFromUrl(location);
    const retrievedFlow = retrieveFlowFromUrl(location);
    const retrievedRequestedProductIds = retrieveProductIdsFromUrl(location);

    const currentIndex = getCurrentStepIndex(location.pathname, retrievedFlow);

    let prevStep = Math.max(currentIndex - 1, 0);

    // in the instance we are going to a review page and only have 1 product in the url, then we skip that page
    // this only happens for skin health atm too but we can just check product length
    if (
      retrievedFlow.steps[prevStep].path === 'review' &&
      retrievedRequestedProductIds.length === 1
    ) {
      prevStep -= 1;
    }

    // this skips av sync
    if (
      retrievedFlow.steps[prevStep].path === 'upload-video' &&
      !isAvSyncRequired(customer?.stateAbbr ?? '')
    ) {
      prevStep -= 1;
    }

    // add check if upload id exists and skip them if so
    if (retrievedFlow.steps[prevStep].path === 'verify-identity' && licenseExists) {
      prevStep -= 1;
    }

    if (prevStep >= 0 && currentIndex > 0) {
      history.push(
        formatExperienceURL(
          `/request-experience/${retrievedFlow.steps[prevStep].path}`,
          location,
          retrievedCategories
        )
      );
    } else {
      history.goBack();
    }
  };

  if (isLoading) return <Loader />;

  return (
    <>
      {requestStepsWithReview.map((step, index) => (
        <PrivateFlowRoute
          key={index}
          component={step.component}
          basePath='/request-experience'
          path={'/' + step.path}
          onNext={onNext}
          onBack={onBack}
          location={location}
          isAuthenticated={isAuthenticated}
          customer={customer}
        />
      ))}
    </>
  );
}
