import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { useEffect, useState } from 'react';
import { getProductIdsFromGroupedProducts, getProductToBeBundledWith } from 'lib/shared/product';
import BundleViewableProduct from './BundleViewableProduct';
import ViewableProduct from './ViewableProduct';
import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { useAppSelector } from 'reducers/alloy_reducer';
import { useLocation } from 'react-router';

interface ListViewableProductsProps {
  products: GroupedContentfulProduct[][];
  subscriptions?: SubscriptionWithRenewal[];
  showDosage?: boolean;
  hideDetails?: boolean;
}

export default function ListViewableProducts({
  products,
  subscriptions,
  showDosage,
  hideDetails,
}: ListViewableProductsProps) {
  const location = useLocation();

  const cart = useAppSelector((state) => state.experience.alloyCart);
  const tpCart = useAppSelector((state) => state.treatmentPlan.treatmentPlanCart);

  const [activeSubProducts, setActiveSubProducts] = useState<GroupedContentfulProduct[][]>([]);

  const activeSubscriptions = (subscriptions ?? []).filter(
    (s) => s.status === 'ACTIVE' || s.status === 'PAUSED'
  );

  const isCheckoutExperience = location.pathname.includes('checkout-experience');
  const isRequestExperience = location.pathname.includes('request-experience');

  /**
   * needed to add this because we've (for now) three scenarios to display in the checkout
   * - for checkout experience -> ship now defaults to true BUT we don't care about it, so we
   * should always send the `activeSubProducts` to be consider when showing the bundled/not bundled price in the UI
   * - for request experience / add-on TP -> we care about it!
   * if ship now is selected we DON'T consider the `activeSubProducts` and the opposite happens if they select to ship later (next shipment)
   * todo: mikhail says he'll think of a way of clean this whole component in the future
   */
  const shipNowOnRequestExperience = cart.shipNow && isRequestExperience;
  const shipNowOnTreatmentPlan = tpCart.shipNow;
  const shipNowSelected = shipNowOnRequestExperience || shipNowOnTreatmentPlan;

  useEffect(() => {
    fetchProducts();
  }, [JSON.stringify(products.flat())]);

  const fetchProducts = async () => {
    if (activeSubscriptions.length === 0) {
      setActiveSubProducts(products);
    } else {
      const productIds = getProductIdsFromGroupedProducts(products.flat());
      const activeSubsProductIds = activeSubscriptions.flatMap((sub) =>
        sub.products.map((p) => p.product.productId)
      );
      const activeSubProducts = await ProductRegistry.get().getRecurringProductsForV2([
        ...productIds,
        ...activeSubsProductIds,
      ]);

      setActiveSubProducts(activeSubProducts);
    }
  };

  return (
    <>
      {products.map((gcpList, listIndex) => {
        return gcpList.map((gcp, index) => {
          const parent = getProductToBeBundledWith(
            gcp,
            shipNowSelected && !isCheckoutExperience && gcpList.length === 1
              ? []
              : activeSubProducts
          );
          return !!parent ? (
            <BundleViewableProduct
              key={`${listIndex}-${index}`}
              groupedProduct={gcp}
              isParentSelected
              parent={parent}
              showDosage={showDosage}
              hideDetails={hideDetails}
            />
          ) : (
            <ViewableProduct
              key={`${listIndex}-${index}`}
              groupedProduct={gcp}
              showDosage={showDosage}
              hideDetails={hideDetails}
            />
          );
        });
      })}
    </>
  );
}
