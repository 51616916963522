import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { initializeApp } from 'actions/core/app_actions';

import Loader from 'components/core/Loader';
import { AuthRoute, PrivateRoute, PublicRoute } from 'components/routes/Routes';

import { RedirectRoutes } from 'data/core/redirects';
import { AUTHENTICATION_ROUTES, PRIVATE_ROUTES, PUBLIC_ROUTES } from 'data/core/routes';

import { formatURL } from 'lib/core/url';
import PosthogShim from 'lib/tracking/PosthogShim';

import CheckoutExperience from 'pages/checkout-experience/CheckoutExperience';
import NotFound from 'pages/other/NotFound';
import RequestExperience from 'pages/request-experience/RequestExperience';

import { useAppSelector } from 'reducers/alloy_reducer';

export default function Main() {
  const dispatch = useDispatch();

  const dispatchInitializeApp = bindActionCreators(initializeApp, dispatch);

  const { isAppLoaded } = useAppSelector((state) => state.alloy);

  useEffect(() => {
    dispatchInitializeApp();
  }, []);

  if (!isAppLoaded) return <Loader />;

  return (
    <BrowserRouter>
      <PosthogShim>
        <Switch>
          {PUBLIC_ROUTES.map((route, index) => (
            <PublicRoute key={index} path={route.path} comp={route.component} exact />
          ))}

          {AUTHENTICATION_ROUTES.map((route, index) => (
            <AuthRoute key={index} path={route.path} comp={route.component} exact />
          ))}

          {PRIVATE_ROUTES.map((route, index) => (
            <PrivateRoute key={index} path={route.path} comp={route.component} exact />
          ))}

          {/* Experience routes */}
          <Route path='/checkout-experience' component={CheckoutExperience} />
          <Route path='/request-experience' component={RequestExperience} />

          {/* Redirect routes */}
          {RedirectRoutes.map((route, index) => (
            <Route path={route.oldPath} key={index}>
              <Redirect to={formatURL(route.newPath, window.location, route.params)} />
            </Route>
          ))}

          {/* 404 route */}
          <Route component={NotFound} />
        </Switch>
      </PosthogShim>
    </BrowserRouter>
  );
}
