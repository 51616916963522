import { uniq } from 'lodash';

import { ProductAndFrequency } from 'client/dist/generated/alloy';
import { useHistory, useLocation } from 'react-router';

import drMennImg from 'assets/images/checkout-experience/review/dr-menn.png';

import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';
import TopBannerWithProgress from '../TopBannerWithProgress';
import FeaturesBlock from '../expectations/FeaturesBlock';
import ImprovementBlock from '../expectations/ImprovementBlock';

import Layout from 'containers/Layout';

import { features } from 'data/checkout-experience/content/expectations';

import { updateToNewCheckoutExperienceFlow } from 'actions/checkout-experience/flow_actions';
import { getBuiltFlowFromCategories } from 'lib/checkout-experience/flow';
import { getPercentComplete } from 'lib/checkout-experience/progress';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProductReviewCardBlock from '../review/ProductReviewCardBlock';

interface Props {
  products: ProductAndFrequency[];
}

export default function Disqualified({ products }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const productNames = uniq(products.map((pf) => pf.name));

  const dispatchUpdateToNewCheckoutExperience = bindActionCreators(
    updateToNewCheckoutExperienceFlow,
    dispatch
  );

  const onStartConsult = async () => {
    dispatchUpdateToNewCheckoutExperience(['mht'], history);
  };

  return (
    <Layout title='Intake - Alloy' desc='' noBars>
      <TopBannerWithProgress percentComplete={getPercentComplete(location)} />

      <CheckoutExperienceSection colClass='col-md-7'>
        <h1 className='content-title'>Our Recommendation</h1>
        <p className='content-text'>
          Based on your answers,{' '}
          {productNames.length === 1 ? productNames[0] : 'your requested treatments'} may not be the
          right fit, but there's a solution for everyone. We need to ask a few more questions to
          find your ideal treatment.
        </p>

        <ProductReviewCardBlock
          topImg={drMennImg}
          title='Doctor Consultation'
          subtitle='$49.00 (1-time cost)'
          description='A menopause specialized doctor will review your intake and work with you to find a treatment that is the best for you.'
        >
          <div className='product-button-wrapper'>
            <button className='primary-button' onClick={onStartConsult}>
              Start intake
            </button>

            <a
              className='primary-link-button'
              href={process.env.REACT_APP_MARKETING_URL + '/solutions'}
            >
              Explore other treatments
            </a>
          </div>
        </ProductReviewCardBlock>

        <ImprovementBlock
          title='95% of Alloy customers '
          text='taking MHT have symptom relief in 2 weeks or less'
        />

        <FeaturesBlock data={features} />
      </CheckoutExperienceSection>
    </Layout>
  );
}
