import { Alert } from '@mui/material';
import { Message, Notification, toaster } from 'rsuite';

import greenCheckmark from 'assets/svg/core/checkmark-green.svg';

export const showSuccessNotification = (message: string, duration: number = 5000) => {
  toaster.push(
    <Notification duration={duration} closable type='success' header='Success'>
      {message}
    </Notification>,
    {
      placement: 'topEnd',
    }
  );
};

export const showErrorNotification = (message: string, duration: number = 5000) => {
  toaster.push(
    <Notification duration={duration} closable type='error' header='Error'>
      {message}
    </Notification>,
    {
      placement: 'topEnd',
    }
  );
};

export const showDashboardAlert = (
  message: string,
  type: 'error' | 'info' | 'success' | 'warning',
  duration: number = 5000
) => {
  toaster.push(
    <Message duration={duration}>
      <Alert
        icon={<img src={greenCheckmark} alt='checkmark icon' className='dashboard-alert-icon' />}
        severity={type}
      >
        {message}
      </Alert>
    </Message>,
    {
      placement: 'bottomCenter',
    }
  );
};

export default {
  showErrorNotification,
};
