import isNarrowScreen from 'lib/core/isNarrowScreen';

interface Props {
  desktopImgSrc: string;
  mobileImgSrc?: string;
  children: React.ReactNode;
}

export default function Hero({ mobileImgSrc, desktopImgSrc, children }: Props) {
  const getHeroImg = isNarrowScreen() && !!mobileImgSrc ? mobileImgSrc : desktopImgSrc;

  return (
    <section className='section-expectation-hero'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-md-6 p-3 d-flex justify-content-center'>
            <div className='d-flex flex-column m-md-4 mt-md-5'>{children}</div>
          </div>
          <div className='col-12 col-md-6 p-0'>
            <img src={getHeroImg} alt='expectations-hero-img' />
          </div>
        </div>
      </div>
    </section>
  );
}
