import { Customer } from 'client/dist/generated/alloy';

import CardIcon from 'components/core/CardIcon';

import { transformDateString } from '../../shared/date';
import { prettyPrintPhone } from '../../core/phone';

import { StripePaymentMethod } from 'models/alloy/stripe';
import { ProfileRow } from 'models/components/profile';

type GeneratedProfileDictionary = { profile: { [key: string]: ProfileRow[] }; titles: string[] };

/**
 * for display purposes and not having to set up multiple arrays inside the Settings page. we can just easily pass in
 * some data like customer and pms here and then spit out each section appropriately. this allows us to just render
 * the setting blocks as needed!
 *
 * @param customer Customer
 * @param paymentMethods StripePaymentMethod[]
 * @returns GeneratedSettingsDictionary
 */
export const generateProfile = (
  customer: Customer,
  paymentMethods: StripePaymentMethod[]
): GeneratedProfileDictionary => {
  const defaultPaymentMethod = (paymentMethods as StripePaymentMethod[]).find(
    (pm) => pm.is_primary
  );

  const combinedName = `${customer.firstName ?? ''}${
    customer.lastName ? ` ${customer.lastName}` : ''
  }`;

  return {
    profile: {
      information: [
        {
          title: 'Name',
          text: combinedName.length > 0 ? combinedName : '-',
        },
        {
          title: 'Email',
          text: customer.email,
        },
        {
          title: 'Phone',
          text: customer.phoneNumber ? prettyPrintPhone(customer.phoneNumber) : '-',
        },
        {
          title: 'Birthday',
          text: customer.dateOfBirth
            ? transformDateString(customer.dateOfBirth, 'MM/DD/YYYY')!
            : '-',
        },
        {
          title: 'Password',
          text: '•••••••••',
        },
      ],
      shippingAddress: [
        {
          text:
            customer.shippingAddressLineOne && customer.shippingAddressLineOne !== '' ? (
              <>
                {customer.shippingAddressLineOne}
                {customer.shippingAddressLineTwo !== ''
                  ? ` ${customer.shippingAddressLineTwo}`
                  : ''}
                <br />
                {customer.city}, {customer.stateAbbr} {customer.zip}
              </>
            ) : (
              '-'
            ),
        },
      ],
      paymentMethod: [
        {
          text: defaultPaymentMethod ? (
            <>
              <CardIcon brand={defaultPaymentMethod.card.brand} />
              •••• •••• •••• {defaultPaymentMethod.card.last4}
            </>
          ) : (
            '-'
          ),
        },
      ],
    },
    titles: ['Profile Information', 'Shipping Address', 'Payment Method'],
  };
};
