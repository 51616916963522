import { ReactNode } from 'react';

import rxIcon from 'assets/svg/checkout-experience/review/rx-icon.svg';
import checkmarkIcon from 'assets/svg/core/checkmarks/checkmark.svg';

interface Props {
  topImg: string;
  title: string;
  subtitle: string;
  description: string;
  benefitsList?: string[];
  showRx?: boolean;
  children?: ReactNode;
}

export default function ProductReviewCardBlock({
  topImg,
  title,
  subtitle,
  description,
  benefitsList = [],
  showRx = false,
  children,
}: Props) {
  return (
    <div className='product-review-card-block'>
      {showRx ? (
        <div className='product-rx-img-wrapper'>
          <img src={topImg} alt='products' className='product-img' />

          <img src={rxIcon} alt='rx icon' className='rx-icon-img' />
        </div>
      ) : (
        <div className='product-img-wrapper'>
          <img src={topImg} alt='product' className='product-img' />
        </div>
      )}

      <div className='product-content-wrapper'>
        <p className='product-title'>{title}</p>
        <p className='product-subtitle'>{subtitle}</p>

        <p className='product-text'>{description}</p>

        {benefitsList.length !== 0 && (
          <div className='product-category-wrapper'>
            {benefitsList.map((text, index) => (
              <div className='category-list-wrapper' key={index}>
                <img src={checkmarkIcon} alt='checkmark-icon' className='list-icon' />

                <p className='list-text'>{text}</p>
              </div>
            ))}
          </div>
        )}

        {children}
      </div>
    </div>
  );
}
