import StatBlock from './StatBlock';
import sexBundleDesktop from 'assets/images/checkout-experience/expectations/sexual-bundle-expectations-desktop.png';
import sexBundleMobile from 'assets/images/checkout-experience/expectations/sexual-bundle-expectations-mobile.png';
import Hero from './Hero';
import HowItWorksBlock from './HowItWorksBlock';
import { howItWorksSex } from 'data/checkout-experience/content/expectations';
import ReviewCarousel from 'components/core/ReviewCarousel';
import { ContentfulReview } from 'models/contentful/reviews';

interface Props {
  reviews: ContentfulReview[];
}
export default function Sex({ reviews }: Props) {
  return (
    <>
      <Hero desktopImgSrc={sexBundleDesktop} mobileImgSrc={sexBundleMobile}>
        <h3 className='expectations-header'>You're on your way to better sexual health</h3>
        <p className='expectations-desc'>It should only take a minute!</p>
        <HowItWorksBlock data={howItWorksSex} showInfoBG={false} />
      </Hero>
      <StatBlock title='Did you know?'>
        <span>About 33%</span>of US women report low sexual desire or interest, and is at a higher
        rate among women aged 45 to 64.
      </StatBlock>
      <ReviewCarousel showTPWidget reviewCards={reviews} />
    </>
  );
}
