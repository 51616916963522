import { useGetTreatmentPlan } from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'rsuite';

import { updateTPCart } from 'actions/treatment-plan/cart_actions';

import closeIcon from 'assets/svg/btn-close.svg';

import { TextField } from 'components/core/fields/TextField';
import { showDashboardAlert } from 'components/core/Notification';

import { getGroupedCartTotals } from 'lib/shared/cart';
import { getPromotionCodeForCart } from 'lib/shared/promotionCode';

import { useAppSelector } from 'reducers/alloy_reducer';

/**
 * Component that handles showing the promotion code, used within <OrderSummaryBlock />
 * Allows user to update their treatment plan cart with a promotion code
 */
export default function DiscountBlock() {
  const dispatch = useDispatch();

  const { data: treatmentPlan = { pendingCharges: [] } } = useGetTreatmentPlan();

  const [showDiscountInput, setShowDiscountInput] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Treatment plan cart
  const cart = useAppSelector((state) => state.treatmentPlan.treatmentPlanCart);

  // Update the treatment plan cart
  const dispatchUpdateCart = bindActionCreators(updateTPCart, dispatch);

  useEffect(() => {
    try {
      initPromoCode();
    } catch (e) {
      setIsLoading(false);
    }
  }, []);

  // If there exists a promotion code in the pending charges then we need to grab it and apply to our cart appropriately
  const initPromoCode = async () => {
    const foundPromotionCode = treatmentPlan.pendingCharges.find(
      (pc) => pc.promotionCode
    )?.promotionCode;

    if (foundPromotionCode) {
      setIsLoading(true);

      const { subtotal } = getGroupedCartTotals(cart);
      const cartStripeProductIds = cart.products.flatMap((p) =>
        p.alloyProduct.parent.map((par) => par.stripeProductId)
      );

      const promotionCode = await getPromotionCodeForCart(
        foundPromotionCode.code,
        subtotal,
        cartStripeProductIds
      );

      dispatchUpdateCart({
        promotionCode,
      });

      setIsLoading(false);
    }
  };

  /**
   * Attempt to apply the promotion code to the treatment plan cart
   */
  const onApply = async () => {
    try {
      setIsLoading(true);

      const { subtotal } = getGroupedCartTotals(cart);
      const cartStripeProductIds = cart.products.flatMap((p) =>
        p.alloyProduct.parent.map((par) => par.stripeProductId)
      );

      const promotionCode = await getPromotionCodeForCart(
        promoCode,
        subtotal,
        cartStripeProductIds
      );

      dispatchUpdateCart({
        promotionCode,
      });

      showDashboardAlert('Promo code successfully applied', 'success');

      setPromoCode('');
      setShowDiscountInput(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  /**
   * Remove the promotion code from the treatment plan cart
   */
  const onRemove = () => {
    dispatchUpdateCart({
      promotionCode: {
        id: '',
        name: '',
        discountAmount: 0,
      },
    });

    showDashboardAlert('Promo code successfully removed', 'success');

    setPromoCode('');
    setShowDiscountInput(false);
  };

  /**
   * If a promotion code exists, show that to the user
   */
  if (cart.promotionCode.id !== '') {
    return (
      <div className='tp-discount-block'>
        <div className='discount-content'>
          <button onClick={onRemove} className='discount-close-btn'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>

          <p className='discount-text'>Active Discount: {cart.promotionCode.name}</p>
        </div>
      </div>
    );
  }

  return (
    <div className='tp-discount-block'>
      {showDiscountInput ? (
        <div className='discount-content'>
          <button
            onClick={() => setShowDiscountInput(false)}
            className='discount-close-btn'
            disabled={isLoading}
          >
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>

          <div className='dicount-input-wrapper'>
            <Form>
              <TextField
                name='code'
                placeholder='Discount code'
                classes='discount-input'
                value={promoCode}
                onChange={(value) => setPromoCode(value.toUpperCase())}
                disabled={isLoading}
              />

              <button
                className='discount-btn'
                onClick={onApply}
                disabled={isLoading || promoCode === ''}
              >
                Apply
              </button>
            </Form>
          </div>
        </div>
      ) : (
        <button className='discount-link' onClick={() => setShowDiscountInput(true)}>
          Have a Promo Code?
        </button>
      )}
    </div>
  );
}
