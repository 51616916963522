import {
  getRecentSubmission,
  runSuggestions,
  useGetAllSubscriptionsForCustomer,
} from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { bindActionCreators } from 'redux';

import { updateCart } from 'actions/checkout-experience/cart_actions';

import drMennImg from 'assets/images/checkout-experience/review/dr-menn.png';

import DoctorConsultViewableProduct from 'components/checkout-experience/products/DoctorConsultViewableProduct';
import EditDrawer from 'components/checkout-experience/review/EditDrawer';
import ListViewableProducts from 'components/checkout-experience/products/ListViewableProducts';

import { isConsultCart } from 'lib/checkout-experience/checkout/cart';
import { retrieveFlowFromUrl } from 'lib/checkout-experience/flow';
import { retrieveCategoriesFromUrl, retrieveIntakeCategoriesFromUrl } from 'lib/shared/experience';
import {
  getProductIdsFromGroupedProducts,
  retrieveGroupedContentfulProductsFrom,
} from 'lib/shared/product';

import { useAppSelector } from 'reducers/alloy_reducer';
import { cleanGroupedPurchasableProducts } from 'lib/request-experience/flow';

export default function CartWrapper() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [flowProducts, setFlowProducts] = useState<GroupedContentfulProduct[][]>([]);
  const [cartProducts, setCartProducts] = useState<GroupedContentfulProduct[][]>([]);

  const cart = useAppSelector((state) => state.experience.alloyCart);

  const isConsultCheckout = isConsultCart(cart);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const dispatchUpdateCart = bindActionCreators(updateCart, dispatch);

  const flow = retrieveFlowFromUrl(location);
  const categories = retrieveCategoriesFromUrl(location);

  const isRequestExperience = location.pathname.includes('request-experience');

  const shouldShowEdit = !(
    categories.some((c) => c === 'mht') ||
    (categories.length === 1 && !categories.every((c) => c === 'skin-health'))
  );

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (isRequestExperience) {
      setCartProducts([cart.products]);
    }
  }, [cart.shipNow]);

  /**
   * this function handles gathering products to potentially show in the edit drawer. it helps handle any potential dq scenarios as well
   * that way a customer isn't able to select a DQd product!
   */
  const fetchProducts = async () => {
    if (shouldShowEdit) {
      const intakeCategories = retrieveIntakeCategoriesFromUrl(location);

      const recentSubmission = await getRecentSubmission({ categories: intakeCategories });

      const submissionId = recentSubmission.id!;

      const suggestions = await runSuggestions({ submissionId });

      const qualifiedProductIds = suggestions.qualified.map((q) => q.product.productId);
      const disqualifiedProductIds = suggestions.disqualified.map((dq) => dq.product.productId);

      const productIdsToFetch = [...flow.productIds, ...qualifiedProductIds].filter(
        (productId) => !disqualifiedProductIds.includes(productId)
      );

      const fetchedProducts = await retrieveGroupedContentfulProductsFrom(
        productIdsToFetch,
        categories,
        true
      );

      setFlowProducts(fetchedProducts);
    }

    if (!isConsultCheckout) {
      const productIds = getProductIdsFromGroupedProducts(cart.products);

      const cartProducts = await ProductRegistry.get().getRecurringProductsForV2(productIds);

      setCartProducts(cartProducts);
    }

    setIsLoading(false);
  };

  const onSave = async (products: GroupedContentfulProduct[][]) => {
    const productIds = getProductIdsFromGroupedProducts(products.flat());
    const subscribedProductIds = subscriptions
      .filter((s) => s.status === 'ACTIVE' || s.status === 'PAUSED')
      .flatMap((sub) => sub.products.map((p) => p.product.productId));

    const cleanedProducts = await cleanGroupedPurchasableProducts(productIds, subscribedProductIds);

    dispatchUpdateCart({ products: cleanedProducts.flat() });
    setCartProducts(cleanedProducts);
    setIsEditing(false);
  };

  switch (true) {
    case isConsultCheckout: {
      return (
        <DoctorConsultViewableProduct
          topImg={drMennImg}
          title='Doctor Consultation'
          subtitle='$49.00 (1-time cost)'
        />
      );
    }

    default: {
      return (
        <div>
          {shouldShowEdit && (
            <div className='text-left text-md-right mb-3'>
              <button className='secondary-border-button' onClick={() => setIsEditing(true)}>
                Edit cart
              </button>
            </div>
          )}

          <ListViewableProducts products={cartProducts} subscriptions={subscriptions} hideDetails />

          {shouldShowEdit && !isLoading && (
            <EditDrawer
              open={isEditing}
              setOpen={setIsEditing}
              onSave={onSave}
              productsWithType={{ specific: flowProducts }}
              prevSelectedProducts={cartProducts.flat()}
            />
          )}
        </div>
      );
    }
  }
}
