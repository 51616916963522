import { ExperienceValidationKey } from 'lib/checkout-experience/validation';
import { ExperienceFlowStep } from 'models/alloy/experience';
import Expectations from 'pages/checkout-experience/mht/Expectations';
import CategorySpecificExpectations from 'pages/checkout-experience/shared/CategorySpecificExpectations';

// [ab-test:should-add-expectations-step]
export const addExpectationsStep: ExperienceFlowStep[] = [
  {
    validationKey: ExperienceValidationKey.whatToExpect,
    component: CategorySpecificExpectations,
    path: 'what-to-expect',
    routeType: 'PUBLIC',
  },
];
