import {
  ProductAndFrequency,
  ProductFrequencyRenewal,
  SubscriptionWithRenewal,
} from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { sortBy } from 'lodash';
import ProductRegistry from 'client/dist/product/productRegistry';

/**
 * For the instance of our upsell products or prescribed but not purchased, we need to understand what products
 * the customer has not purchase (both subscription and one time) as well as know which products (if any)
 * the customer is potentially able to approve on (that way we do not say ie: m4 in pending charge + m4 in upsell)
 *
 * @param subscriptions SubscriptionWithRenewal[]
 * @param purchasedOneTimePrescriptionProducts ProductFrequencyRenewal[]
 * @param prescriptionProducts ProductFrequencyRenewal[]
 * @param customerApprovalPendingChargesProducts ProductAndFrequency[]
 * @returns ProductFrequencyRenewal[]
 */
export const getNonPurchasedProducts = (
  subscriptions: SubscriptionWithRenewal[],
  purchasedOneTimePrescriptionProducts: ProductFrequencyRenewal[],
  prescriptionProducts: ProductFrequencyRenewal[],
  customerApprovalPendingChargesProducts: ProductAndFrequency[]
): ProductFrequencyRenewal[] => {
  const subscriptionProducts = subscriptions.flatMap((s) => s.products);

  return prescriptionProducts
    .filter((pp) => !subscriptionProducts.map((sp) => sp.product.id).includes(pp.product.id))
    .filter(
      (pp) => !purchasedOneTimePrescriptionProducts.map((p) => p.product.id).includes(pp.product.id)
    )
    .filter(
      (pp) =>
        !customerApprovalPendingChargesProducts
          .map((p) => p.productId)
          .includes(pp.product.productId)
    )
    .filter((pp) => pp.product.category !== 'mht'); // filter out any mht products from round out routine
};

export const sortMhtProducts = (groupedProducts: GroupedContentfulProduct[]) => {
  const SORT_MHT = ['pill', 'spray', 'patch', 'gel'];

  return sortBy(groupedProducts, (gcp) => {
    return SORT_MHT.indexOf(getMhtShortName(gcp).toLowerCase());
  });
};

export const getMhtShortName = (gcp: GroupedContentfulProduct) => {
  const initialProduct = gcp.alloyProduct.parent[0];
  const nameSplit = initialProduct.cleanName.split(' ');

  const name = nameSplit[nameSplit.length - 1];

  switch (name) {
    case 'Evamist':
      return 'Spray';
    default:
      return name;
  }
};

/**
 * Since our grouped product should always be mht if it exists in a parent we can just check if one of the parents has mht
 * and that will give us all grouped mht products
 *
 * @param groupedProducts GroupedContentfulProduct[]
 * @returns GroupedContentfulProduct[]
 */
export const filterMhtProductsFrom = (
  groupedProducts: GroupedContentfulProduct[]
): GroupedContentfulProduct[] => {
  return groupedProducts.filter((gcp) =>
    gcp.alloyProduct.parent.some((pf) => pf.category === 'mht')
  );
};

/**
 * Give us all non mht products in an array of grouped products
 *
 * @param groupedProducts GroupedContentfulProduct[][]
 * @returns GroupedContentfulProduct[][]
 */
export const filterNonMhtProductsFrom = (groupedProducts: GroupedContentfulProduct[][]) => {
  return groupedProducts.filter((gcpList) =>
    gcpList.some((gcp) => gcp.alloyProduct.parent.every((pf) => pf.category !== 'mht'))
  );
};
