import axios, { AxiosResponse } from 'axios';
import { AXIOS_INSTANCE } from 'client/dist/authenticatingAxiosInstance';
import sentry from '../tracking/sentry';
import Notification from 'components/core/Notification';

const unrecoverableErrorMessage = `Looks like we're having an issue. Sorry about that! Please reach out to support@myalloy.com and we'll have it resolved as quickly as possible.`;

const errorHandler = (r: AxiosResponse<any>) => {
  // Global set of ERRORs the app can return
  // TODO - Import from server/errorHandler once we have monorepo up

  if (axios.isAxiosError(r)) {
    const { response, code } = r;

    if (code === 'ERR_NETWORK') {
      // handle network problem?
    }

    if (code === 'ERR_CANCELED') {
      // handle connection cancel?
    }

    if (response?.status === 401) {
      // MARK: initial replace is to remove the first / in the url then everything after we encode
      const nextPath = encodeURIComponent(window.location.pathname.replace('/', ''));
      window.location.replace(`/login?loggedOut=true&nextPath=${nextPath}`);
    }

    if (response) {
      const error: ServerError = response.data;
      const unrecoverableError = response.status >= 500;
      // Recoverable Errors do not need to be sent to sentry
      if (unrecoverableError) {
        sentry.sendServerErrorToSentry(error);
      }

      // Only show generic error message for Unrecoverable Errors
      const notificationMessage = unrecoverableError
        ? unrecoverableErrorMessage +
          (error.correlationId ? ` \n Correlation ID: ${error.correlationId}` : '')
        : formatNotificationMessage(error);

      // Display Unrecoverable Messages indefinitely (0)
      // Display Recoverable Messages for a default of 8000ms // todo: probably in the future we'd need this to be more dynamic
      const notificationDuration = unrecoverableError ? 0 : 8000;
      Notification.showErrorNotification(notificationMessage, notificationDuration);
      // still returning (or throw?) here as we use the `catch` block throughout the components to
      // set loading = false
      throw new Error(error?.message ?? 'Unknown Server Error');
    }
  }
};

const formatNotificationMessage = (error: ServerError) => {
  let message: string;
  switch (error.name) {
    case 'TAXJAR_ERROR':
      message = error.message
        .replace('Error calculating taxes: ', '')
        .replace('to_zip', 'Zipcode')
        .replace('to_state', 'state');
      break;
    case 'CUSTOMER_DATA_ERROR':
    case 'COUPON_ERROR':
    case 'CHECKOUT_FAILED':
    case 'STRIPE_ERROR':
    case 'MDI_ERROR':
    case 'REFERRAL_ERROR':
    case 'SHIPPING_METHOD_ERROR':
      message = error.message;
      break;
    case 'UNKNOWN_ERROR':
    default:
      // assume here we've thrown an unhandled error and we don't want to expose raw stack traces to the user.
      message = unrecoverableErrorMessage;
      break;
  }

  return message;
};

type ServiceErrorName =
  | 'CHECKOUT_FAILED'
  | 'STRIPE_ERROR'
  | 'MDI_ERROR'
  | 'TAXJAR_ERROR'
  | 'CUSTOMER_DATA_ERROR'
  | 'REFERRAL_ERROR'
  | 'COUPON_ERROR'
  | 'SHIPPING_METHOD_ERROR'
  | 'UNKNOWN_ERROR';

export interface ServerError {
  name: ServiceErrorName;
  message: string;
  status: number;
  error?: any;
  correlationId: string;
}

export default {
  errorHandler,
};
