import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';
import { Form, Loader } from 'rsuite';

import closeIcon from 'assets/svg/btn-close.svg';
import chevronDownIcon from 'assets/svg/core/chevron-down.svg';

import { TextField } from 'components/core/fields/TextField';

import { convertCentsToDollars } from 'lib/shared/convert';
import { getProductNameWithDosageFromProductFrequency } from 'lib/shared/product/productNameWithDosage';

import { SummaryContent } from 'models/alloy/dashboard-base';

interface Props {
  shipment: SubscriptionWithRenewal;
  onApplyDiscount: (code: string) => Promise<void>;
  onRemoveDiscount: (code: string) => Promise<void>;
}

const SummaryAndTotalBlock = ({
  shipment,
}: Omit<Props, 'onApplyDiscount' | 'onRemoveDiscount'>) => {
  const toggleSummary = () => {
    const overviewDetails = document.getElementById('overview-details');

    if (overviewDetails) {
      if (overviewDetails.classList.contains('active')) {
        overviewDetails.classList.remove('active');
      } else {
        overviewDetails.classList.add('active');
      }
    }
  };

  if (!shipment.upcomingInvoice) return <></>;

  const { subtotal, tax, total, shipping } = shipment.upcomingInvoice;

  const subtotalToDollars = convertCentsToDollars(subtotal);
  const taxToDollars = convertCentsToDollars(tax);
  const shippingToDollars = convertCentsToDollars(shipping);
  const totalToDollars = convertCentsToDollars(total);

  const summaryContent: SummaryContent[] = [
    {
      title: 'Sub Total',
      content: `$${subtotalToDollars}`,
    },
    {
      title: 'Taxes',
      content: `$${taxToDollars}`,
    },
    {
      title: 'Shipping',
      content: `$${shippingToDollars}`,
    },
  ];

  const discount = shipment.upcomingInvoice.promotionCode;
  const discountExists = !!discount;
  let discountToDollars = '0.00';

  if (discountExists) {
    if (!!discount.coupon.amount_off) {
      discountToDollars = convertCentsToDollars(discount.coupon.amount_off);
    } else if (!!discount.coupon.percent_off) {
      discountToDollars = ((subtotal * (discount.coupon.percent_off / 100)) / 100).toFixed(2);
    }

    // Insert at index 1 the discount row since it should come before taxes
    summaryContent.splice(1, 0, {
      title: `Discounts (${discount.code})`,
      content: `-$${discountToDollars}`,
    });
  }

  return (
    <>
      <div className='overview-summary-wrapper'>
        {summaryContent.map((summary, index) => (
          <div className='summary-row' key={index}>
            <p className='summary-title'>{summary.title}</p>
            <p className='summary-content'>{summary.content}</p>
          </div>
        ))}
      </div>

      <div className='overview-total-wrapper'>
        <p className='total-title'>Order Total</p>
        <button className='total-amount-btn' onClick={() => toggleSummary()}>
          <p className='total-amount'>
            <span>${totalToDollars}</span>

            <img src={chevronDownIcon} alt='chevron-down' />
          </p>
        </button>
      </div>
    </>
  );
};

const PromoCodeBlock = ({ shipment, onApplyDiscount, onRemoveDiscount }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showPromoCode, setShowPromoCode] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<string>('');

  useEffect(() => {
    if (shipment.upcomingInvoice?.promotionCode) {
      setPromoCode(shipment.upcomingInvoice.promotionCode.code);
    }
  }, [shipment.upcomingInvoice?.promotionCode?.code]);

  const onApply = async () => {
    try {
      setLoading(true);

      await onApplyDiscount(promoCode);

      setPromoCode(promoCode);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('error:', error);
    }
  };

  const onRemove = async () => {
    try {
      setLoading(true);

      await onRemoveDiscount(promoCode);

      setPromoCode('');
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className='overview-discount-wrapper discount-loader-wrapper'>
        <Loader size='md' />
      </div>
    );
  }

  if (shipment.upcomingInvoice && shipment.upcomingInvoice.promotionCode) {
    return (
      <div className='overview-discount-wrapper'>
        <div className='discount-content'>
          <button onClick={onRemove} className='discount-close-btn'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>

          <p className='discount-text'>
            Active Discount: {shipment.upcomingInvoice.promotionCode.code}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className='overview-discount-wrapper'>
      {showPromoCode ? (
        <div className='discount-content'>
          <button onClick={() => setShowPromoCode(false)} className='discount-close-btn'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>

          <div className='dicount-input-wrapper'>
            <Form>
              <TextField
                name='code'
                placeholder='Discount code'
                classes='discount-input'
                value={promoCode}
                onChange={(value) => setPromoCode(value.toUpperCase())}
              />

              <button className='discount-btn' onClick={onApply} disabled={promoCode === ''}>
                Apply
              </button>
            </Form>
          </div>
        </div>
      ) : (
        <button className='discount-link' onClick={() => setShowPromoCode(true)}>
          Have a Promo Code?
        </button>
      )}
    </div>
  );
};

export default function OverviewBlock({ shipment, onApplyDiscount, onRemoveDiscount }: Props) {
  return (
    <div className='shipment-overview-wrapper'>
      <div className='shipment-header'>
        <div className='row'>
          <div className='col-12 col-md-4'>
            <p className='header-section-title'>Overview</p>
          </div>
          <div className='col-12 col-md-4'>
            <p className='header-title'>Your Shipment</p>
          </div>
          <div className='col-12 col-md-4'>
            <p className='header-title'>Order Summary</p>
          </div>
        </div>
      </div>

      <div className='shipment-header-mobile'>
        <div className='row'>
          <div className='col-12 col-md-4'>
            <p className='header-section-title'>Overview</p>
          </div>
        </div>
      </div>

      <div id='overview-details' className='overview-details'>
        <div className='row'>
          <div className='col-md-4'></div>

          <div className='col-md-4'>
            {shipment.products.map((product, index) => {
              const { cleanName, dosage } = getProductNameWithDosageFromProductFrequency(
                product.product
              );

              return (
                <div className='overview-product' key={index}>
                  <img
                    src={product.product.photo}
                    alt={product.product.name}
                    className='product-photo'
                  />

                  <div className='product-content'>
                    <p className='product-title'>{cleanName}</p>
                    <p className='product-text'>{dosage}</p>

                    <p className='product-text'>
                      ${(product.product.priceInCents / 100 / 3).toFixed(2)}/mo
                    </p>
                    <p className='product-text'>
                      Refills left: {product.prescription?.fillsRemaining ?? '-'}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className='col-md-4 overview-totals-and-promo'>
            <SummaryAndTotalBlock shipment={shipment} />

            <PromoCodeBlock
              shipment={shipment}
              onApplyDiscount={onApplyDiscount}
              onRemoveDiscount={onRemoveDiscount}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
