import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';

import SectionLoader from 'components/core/Loader';
import ShipmentsBlock from 'components/dashboard/shipments/ShipmentsBlock';
import TitleBlock from 'components/dashboard/treatment-plan/core/TitleBlock';

import DashboardContainer from 'containers/dashboard/DashboardContainer';
import useMarkNotificationsAsRead from 'lib/dashboard/notifications/useMarkNotificationsAsRead';

export default function Upcoming() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead(['PRESCRIPTION_AUTOPAUSED']);

  const { isLoading } = useGetAllSubscriptionsForCustomer();

  return (
    <DashboardContainer title='Upcoming Shipments | Alloy' desc='' currentPage='shipments'>
      {isLoading ? (
        <SectionLoader />
      ) : (
        <section className='dashboard-section light-grey-bg'>
          <div className='container dashboard-page-container'>
            <TitleBlock title='Your' fancyTitle='Shipments' />

            <ShipmentsBlock />
          </div>
        </section>
      )}
    </DashboardContainer>
  );
}
