import { applyMiddleware, compose, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import { createReduxEnhancer } from '@sentry/react';

const sentryReduxEnhancer = createReduxEnhancer();

export const store = createStore(
  reducers,
  compose(applyMiddleware(reduxThunk), sentryReduxEnhancer)
);
