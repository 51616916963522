import {
  ProductAndFrequency,
  Submission,
  SubmissionAnswer,
  postPreIdentifiedSubmission,
  postSubmission,
  runSuggestions,
} from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { updateLocalPreCustomer } from 'actions/core/app_actions';

import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import QuestionnaireRoutes from 'components/checkout-experience/questionnaire/QuestionnaireRoutes';
import Loader from 'components/core/Loader';

import Layout from 'containers/Layout';

import { retrieveFlowFromUrl } from 'lib/checkout-experience/flow';
import { getPercentComplete } from 'lib/checkout-experience/progress';
import { collateHiddenSubmissionAnswers } from 'lib/checkout-experience/questionnaire/intake';
import { getIntakeCategories, retrieveCategoriesFromUrl } from 'lib/shared/experience';
import { getPreviousResults } from 'lib/shared/questionnaire';

import { ExperienceComponentModel } from 'models/alloy/experience';
import { PreviousAnswersLocalStorage } from 'models/alloy/questionnaire';
import { QuestionnaireAnswers } from 'models/components/questionnaire';

import { useAppSelector } from 'reducers/alloy_reducer';
import { LocalPreCustomer } from 'reducers/experience_reducer';
import { getItem } from 'client/dist/localstorage';
import Disqualified from 'components/checkout-experience/questionnaire/Disqualified';

export default function Intake({ onNext, onBack }: ExperienceComponentModel) {
  const dispatch = useDispatch();
  const location = useLocation();

  const dateStarted = new Date();

  const flow = retrieveFlowFromUrl(location);
  const categories = retrieveCategoriesFromUrl(location);

  const intakeQuestions = flow.intakeQuestions!!;

  const [previousResults, setPreviousResults] = useState<QuestionnaireAnswers>({});
  const [isLoading, setIsLoading] = useState(true);
  const [disqualifiedProducts, setDisqualifiedProducts] = useState<ProductAndFrequency[]>([]);

  const isAuthenticated = useAppSelector((state) => state.alloy.isAuthenticated);
  const customer = useAppSelector((state) => state.alloy.customer);
  const localPreCustomer = useAppSelector((state) => state.experience.localPreCustomer);

  const dispatchUpdateLocalPreCustomer = bindActionCreators(updateLocalPreCustomer, dispatch);

  useEffect(() => {
    let previousResults = getPreviousResults(intakeQuestions);

    let recentIntakeAnswers: PreviousAnswersLocalStorage | undefined = getItem(
      'recentIntakeAnswers',
      true
    );

    if (
      recentIntakeAnswers &&
      recentIntakeAnswers.categories &&
      recentIntakeAnswers.categories.sort().toString() === categories.sort().toString()
    ) {
      previousResults = {
        ...previousResults,
        ...recentIntakeAnswers.answers,
      };
    }

    setPreviousResults(previousResults);
    setIsLoading(false);
  }, []);

  const onSubmit = async (answers: SubmissionAnswer[]) => {
    try {
      setIsLoading(true);

      const flow = retrieveFlowFromUrl(location);
      const categories = retrieveCategoriesFromUrl(location);
      const intakeCategories = getIntakeCategories(categories);
      const questions = flow.intakeQuestions!!;

      const updatedAnswers = collateHiddenSubmissionAnswers(
        answers,
        questions,
        localPreCustomer,
        customer
      );

      const submission: Submission = {
        categories: intakeCategories,
        started: dateStarted.toISOString(),
        answers: updatedAnswers,
      };

      const { id } = isAuthenticated
        ? await postSubmission(submission)
        : await postPreIdentifiedSubmission(submission);

      // as long as the customer is not on consult flow, we should be able to just fetch submission
      // and run the suggestions against, this will allow us to check if all products against flow are disqualified
      // and if so we show dq otherwise filtering against not showing them products happens in the
      // review page!
      if (id && categories.every((c) => c !== 'mht')) {
        const suggestions = await runSuggestions({ submissionId: id });

        const flowProductIds = flow.productIds;
        const disqualifiedProductIds = suggestions.disqualified.map((dq) => dq.product.productId);
        const qualifiedProductIds = suggestions.qualified.map((dq) => dq.product.productId);

        const filteredFlowProductIds = flowProductIds
          .filter((pid) => !disqualifiedProductIds.includes(pid))
          .concat(qualifiedProductIds);

        if (filteredFlowProductIds.length === 0) {
          setDisqualifiedProducts(suggestions.disqualified.map((dq) => dq.product));
          setIsLoading(false);
          return;
        }
      }

      const updatedLocalPreCustomer: LocalPreCustomer = {
        ...localPreCustomer,
        alloySubmissionId: id,
        previousCategoriesForSubmission: intakeCategories,
        recentSubmissionDate: dateStarted.toISOString(),
      };

      dispatchUpdateLocalPreCustomer(updatedLocalPreCustomer);

      onNext();
    } catch (error) {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Layout title='Intake - Alloy' desc='' noBars>
        <TopBannerWithProgress percentComplete={getPercentComplete(location)} />

        <Loader />
      </Layout>
    );
  }

  if (disqualifiedProducts.length !== 0) return <Disqualified products={disqualifiedProducts} />;

  return (
    <QuestionnaireRoutes
      questions={intakeQuestions}
      previousResults={previousResults}
      onSubmit={onSubmit}
      onBack={onBack}
      parentUrlPath='/checkout-experience/intake'
    />
  );
}
