import { SubscriptionGroupConsentStatus, subscribeToNewsletter } from 'client/dist/generated/alloy';
import { cleanForBraze } from 'lib/tracking/braze';

// TODO: Need some cleaning here
export const subscribeNewsletter = async (
  email: string,
  consentStatus: SubscriptionGroupConsentStatus | undefined
) => {
  await subscribeToNewsletter({
    email: cleanForBraze(email, true)!,
    consentStatus: consentStatus!,
  });
};
