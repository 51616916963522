import { useAppSelector } from 'reducers/alloy_reducer';

import playIcon from 'assets/images/play-icon.png';

import { clearCart } from 'actions/checkout-experience/cart_actions';
import ViewableProduct from 'components/checkout-experience/products/ViewableProduct';
import { EmailUs } from 'components/core/Buttons';
import SectionWrapper from 'components/core/layout/SectionWrapper';
import HeaderBlock from 'components/dashboard/treatment-plan/core/HeaderBlock';
import MessageBlock from 'components/dashboard/treatment-plan/core/MessageBlock';
import ProgressBlock from 'components/dashboard/treatment-plan/core/ProgressBlock';
import ReferralBlock from 'components/dashboard/treatment-plan/core/ReferralBlock';
import TopBlogBlock from 'components/dashboard/shared/blocks/TopBlogBlock';
import InfoSection from 'components/dashboard/shared/sections/Info';
import TestimonialsSection from 'components/dashboard/shared/sections/Testimonials';
import DashboardContainer from 'containers/dashboard/DashboardContainer';
import isNarrowScreen from 'lib/core/isNarrowScreen';
import { useEffect, useState } from 'react';
import { Modal } from 'rsuite';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeardAboutUs from 'components/checkout-experience/surveys/HeardAboutUs';

const CONSULT_CONFIRMATION_DESKTOP_URL =
  'https://videos.ctfassets.net/md0kv0ejg0xf/29PfQbIGBAbl5dmlhx95A5/32c404817f68fbfe0c184df4dd85692a/3_4.mp4';
const CONSULT_CONFIRMATION_DESKTOP_THUMBNAIL =
  'https://images.ctfassets.net/md0kv0ejg0xf/2fSiu209K9W305pdtuAQ4a/e5b69c4350608096b2bb272a73ac64bc/3_4_cover.jpg';

const CONSULT_CONFIRMATION_MOBILE_URL =
  'https://videos.ctfassets.net/md0kv0ejg0xf/qnARDg038ij4cifkTJYAq/7e1251f27e735e2f94569699daf62565/9_16.mp4';
const CONSULT_CONFIRMATION_MOBILE_THUMBNAIL =
  'https://images.ctfassets.net/md0kv0ejg0xf/4FebvfCgOVM9XhZB8M93qK/8157b78bfb7ab086b1c46f5a828225e0/9_16_cover.jpg';

export default function OrderConfirmation() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const isConsultCheckout = searchParams.get('consult-checkout');
  const isOtcCheckout = searchParams.get('otc-checkout');

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);

  const customer = useAppSelector((state) => state.alloy.customer!!);
  const checkout = useAppSelector((state) => state.experience.checkout);
  const cart = useAppSelector((state) => state.experience.alloyCart);

  const dispatchClearCart = bindActionCreators(clearCart, dispatch);

  useEffect(() => {
    if (isOtcCheckout && cart.products.length === 0) {
      dispatchClearCart();

      history.replace('/');
    }
  });

  useEffect(() => {
    setTimeout(() => {
      if (isConsultCheckout) {
        setShowModal(true);
      }
    }, 1000);
  }, []);

  const onViewTreatmentPlan = () => {
    dispatchClearCart();

    history.replace('/');
  };

  return (
    <DashboardContainer title='Order Confirmation | Alloy' desc=''>
      <HeaderBlock
        title={isOtcCheckout ? 'Your order is' : 'Welcome to Alloy'}
        fancyTitle={isOtcCheckout ? 'confirmed' : customer?.firstName ?? ''}
        backgroundType='secondary'
      />

      <SectionWrapper sectionClasses='order-confirmation-section'>
        {checkout && <div className='fp-checkout-id' data-checkout-id={checkout.id} />}

        <div className='col-12 col-md-7'>
          {isOtcCheckout ? (
            <>
              {cart.products.map((gcp, index) => (
                <ViewableProduct key={index} groupedProduct={gcp} showDosage hideDetails />
              ))}
            </>
          ) : (
            <>
              <ProgressBlock
                title="You've taken your first step. Here's what's next."
                description='Your doctor will review your health history, messages, and requests to create a treatment plan for you. Usually within 1-3 days.'
                barTitles={
                  isConsultCheckout
                    ? ['Complete intake', 'Doctor review', 'Confirm your plan', 'Ship treatments']
                    : ['Complete intake', 'Doctor review', 'Ship treatments']
                }
                currentIndex={1}
                hideButton
              />

              {isConsultCheckout ? (
                <MessageBlock text="Your doctor will be in touch within 2 business days about your treatment plan. We'll send you a notification by email and text." />
              ) : (
                <MessageBlock />
              )}
            </>
          )}

          <HeardAboutUs />

          <p className='confirmation-text'>
            You'll receive an order confirmation email shortly at{' '}
            <span className='text-bold'>{customer.email}</span>.
          </p>

          <p className='confirmation-text'>
            Any questions about your order at all, email us at <EmailUs btnClass='text-bold' />.
            We're here for you!
          </p>

          {isOtcCheckout && (
            <button className='primary-button my-3 w-100' onClick={onViewTreatmentPlan}>
              View treatment plan
            </button>
          )}
        </div>

        <div className='col-12 col-md-5'>
          <ReferralBlock />
          <TopBlogBlock />
        </div>
      </SectionWrapper>

      <InfoSection />
      <TestimonialsSection />

      <Modal
        open={showModal}
        overflow={true}
        size='md'
        className='messages-video'
        onClose={() => setShowModal(false)}
      >
        <Modal.Header />
        <Modal.Body className='text-center'>
          <h4 className='title'>Meet Dr. Menn</h4>

          {showVideo ? (
            <video controls autoPlay>
              <source
                src={
                  isNarrowScreen()
                    ? CONSULT_CONFIRMATION_MOBILE_URL
                    : CONSULT_CONFIRMATION_DESKTOP_URL
                }
                type='video/mp4'
              />
              Your browser does not support videos
            </video>
          ) : (
            <div className='messages-video-thumbnail' onClick={() => setShowVideo(true)}>
              <div className='messages-video-block'>
                <img
                  src={
                    isNarrowScreen()
                      ? CONSULT_CONFIRMATION_MOBILE_THUMBNAIL
                      : CONSULT_CONFIRMATION_DESKTOP_THUMBNAIL
                  }
                  alt={`dr menn thumbnail`}
                  className='img-fluid messages-thumbnail-img'
                />
                <img src={playIcon} alt='Play icon' className='play-icon' />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </DashboardContainer>
  );
}
