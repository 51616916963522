import { format, getTime, isValid } from 'date-fns';
import { sendExceptionToSentry } from 'lib/tracking/sentry';
import { zonedTimeToUtc } from 'date-fns-tz';

export type ValidDob = {
  dob?: string;
  error?: string;
};

export const getAge = (dob: string, now: number = Date.now()): number => {
  const ageDifMs = (now ?? Date.now()) - new Date(dob).getTime();
  const ageDate = new Date(ageDifMs);

  return Math.abs(ageDate.getFullYear() - 1970);
};

export const validDob = (date: string): ValidDob => {
  const newDate = new Date(date);

  if (!isValid(newDate)) {
    return {
      error: 'Please enter a valid date of birth.',
    };
  }

  const dateOnly = new Date(newDate.valueOf() + newDate.getTimezoneOffset() * 60 * 1000);
  const formattedDate = format(dateOnly, 'yyyy-MM-dd');

  const age = getAge(formattedDate);

  if (age >= 18 && age <= 99) {
    return {
      dob: formattedDate,
    };
  } else {
    return {
      error: 'You must be at least 18 years old to purchase.',
    };
  }
};

/**
 * given an string date, format and return only what it matters to
 * show to the user, which is the day, month and year.
 *
 * current supported formats: [YYYY-MM-DD] | [MM/DD/YYYY]
 *
 * @param date the date string to transform
 * @param format the format you want it to convert
 *
 * @returns the date string in the format you want to be transformed to
 */
export const transformDateString = (date: string, format: string) => {
  try {
    let stringDate;
    switch (format) {
      case 'YYYY-MM-DD':
        stringDate = `${date.substring(6, 10)}-${date.substring(0, 2)}-${date.substring(3, 5)}`;
        break;
      case 'MM/DD/YYYY':
        stringDate = `${date.substring(5, 7)}/${date.substring(8, 10)}/${date.substring(0, 4)}`;
        break;
      default:
        break;
    }
    return stringDate;
  } catch (error) {
    sendExceptionToSentry(error as Error);
    throw error;
  }
};

const randomInteger = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getMillisWithRandomTime = (date: Date): number => {
  const millisMomentEastern = getTime(zonedTimeToUtc(date, 'America/New_York'));

  const oneHourOfMillis = 1000 * 60 * 60;
  const randomTimeOffsetMillis = randomInteger(oneHourOfMillis * 8, oneHourOfMillis * 18);

  return millisMomentEastern + randomTimeOffsetMillis;
};
