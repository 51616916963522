export default function HelpBlock() {
  return (
    <div className='shipment-help-wrapper'>
      <div className='row justify-content-center text-center'>
        <div className='col-12 col-md-8'>
          <p className='help-title'>Need Help?</p>
          <p className='help-text'>
            Need help? To adjust the bill date of only one of your products or any other questions
            about your shipment, email{' '}
            <a href='mailto:support@myalloy.com' className='help-link'>
              support@myalloy.com
            </a>
          </p>

          {/* MARK: Skip has been removed and pause have been moved to reschedule */}
          {/* <div className='help-btns-wrapper'>
            <button onClick={onSkip} className='btn-link'>
              Skip shipment
            </button>
            <button onClick={onPause} className='btn-link'>
              Pause shipment
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}
