import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';
import { add, min, startOfToday } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

/**
 *
 * Get the max date for rescheduling. The normal max number of days is 90 but if the expiration date comes before
 * then that is the date to use
 *
 * @param shipment SubscriptionWithRenewal
 * @returns Date - the max date for rescheduling either the expiration date or the 90s out from today()
 */
export const getMaxDateForRescheduling = (shipment: SubscriptionWithRenewal): Date => {
  const MAX_DAYS = 90;
  const date90DaysFromNextRecurrence = add(
    zonedTimeToUtc(shipment.nextRecurrenceOn, 'America/New_York'),
    { days: MAX_DAYS }
  );

  const earliestExpDate = getEarliestExpirationDate(shipment);

  if (earliestExpDate) {
    return min([earliestExpDate, date90DaysFromNextRecurrence]);
  } else {
    return date90DaysFromNextRecurrence;
  }
};

/**
 *
 * Get the earliest expiration date that or get no date at all
 *
 * @param shipment SubscriptionWithRenewal
 * @returns Date | null - earliest exp date or none
 */
export const getEarliestExpirationDate = (shipment: SubscriptionWithRenewal): Date | null => {
  // Filter out only dates with expiration ones
  const renewalExpDates = shipment.products
    .filter((p) => p.renewal && p.renewal.expirationDate)
    .map((p) => p.renewal!! && p.renewal.expirationDate!!);

  // If no expiration dates exist, then we don't need a date
  if (renewalExpDates.length === 0) {
    return null;
  }

  // Sort the dates and get the one closest to today()
  const dates = renewalExpDates
    .map((item) => zonedTimeToUtc(item, 'America/New_York'))
    .sort((a, b) => b.getTime() - a.getTime());

  return dates[0];
};

export const isAllPrescriptionsExpired = (shipment: SubscriptionWithRenewal): boolean => {
  const today = startOfToday();

  return shipment.products.every((p) => {
    const expDate = p.renewal?.expirationDate;

    if (expDate) {
      return today > new Date(expDate);
    } else {
      return false;
    }
  });
};

/**
 *
 * Checks to see if all the prescriptions in a given shipment are active and have not passed the expiration date
 *
 * @param shipment SubscriptionWithRenewal
 * @returns boolean - all prescriptions are active
 */
export const isAllPrescriptionsActive = (shipment: SubscriptionWithRenewal): boolean => {
  const today = startOfToday();

  return shipment.products.every((p) => {
    const expDate = p.renewal?.expirationDate;

    if (expDate) {
      return new Date(expDate) > today;
    } else {
      return true;
    }
  });
};

/**
 *
 * Check to see if any prescriptions need renewal
 *
 * @param shipment SubscriptionWithRenewal
 * @returns boolean - if some prescriptions need renewal
 */
export const isSomeNeedRenew = (shipment: SubscriptionWithRenewal): boolean =>
  shipment.products.some((p) => (p.renewal?.needsRenewed ?? false) && !p.renewal?.completedDate);

/**
 *
 * Check to see if every prescription exists
 *
 * @param shipment SubscriptionWithRenewal
 * @returns boolean - if all prescriptions exist
 */
export const isAllPrescriptionExist = (shipment: SubscriptionWithRenewal): boolean =>
  shipment.products.every((p) => p.prescription);
