import { ExperienceCategory } from 'common/dist/models/experience';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { uniq, xor } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { bindActionCreators } from 'redux';

import { updateRequestingProducts } from 'actions/checkout-experience/requesting_actions';

import BottomBar from 'components/checkout-experience/BottomBar';
import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import SelectableProduct from 'components/checkout-experience/products/SelectableProduct';
import Loader from 'components/core/Loader';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';

import Layout from 'containers/Layout';

import { getPercentComplete } from 'lib/checkout-experience/progress';
import { retrieveCategoriesFromUrl } from 'lib/shared/experience';

import { ExperienceComponentModel } from 'models/alloy/experience';

import { useAppSelector } from 'reducers/alloy_reducer';
import ProductRegistry from 'client/dist/product/productRegistry';

export default function MhtPreferences({ onNext, onBack }: ExperienceComponentModel) {
  const dispatch = useDispatch();
  const location = useLocation();

  const categories = retrieveCategoriesFromUrl(location);

  const prevRequestedProductIds = useAppSelector((state) => state.experience.requestingProductIds);

  const [isLoading, setIsLoading] = useState(false);
  const [upsellProducts, setUpsellProducts] = useState<GroupedContentfulProduct[]>([]);
  const [requestedProductIds, setRequestedProductIds] = useState(prevRequestedProductIds);

  const dispatchUpdateRequesting = bindActionCreators(updateRequestingProducts, dispatch);

  useEffect(() => {
    fetchUpsellProducts();
  }, []);

  const fetchUpsellProducts = async () => {
    setIsLoading(true);

    const fetchedProducts = (
      await ProductRegistry.get().getIndividualGroupedContentfulProductsFor('upsell')
    ).flat();

    const sortedProducts = fetchedProducts.sort(
      (a, b) => a.contentfulProduct.fields.order - b.contentfulProduct.fields.order
    );

    const categoriesPreselectedProductIds = sortedProducts
      .filter((gcp) =>
        categories.some((category) =>
          gcp.alloyProduct.parent.every((pf) => pf.category === category)
        )
      )
      .flatMap((gcp) => gcp.alloyProduct.parent.map((pf) => pf.productId));

    const preselected = uniq([...prevRequestedProductIds, ...categoriesPreselectedProductIds]);

    setRequestedProductIds(preselected);

    setUpsellProducts(sortedProducts);
    setIsLoading(false);
  };

  const onSelect = (groupedProduct?: GroupedContentfulProduct) => {
    if (groupedProduct) {
      setRequestedProductIds(
        xor(
          requestedProductIds,
          groupedProduct.alloyProduct.parent.map((pf) => pf.productId)
        )
      );
    } else {
      setRequestedProductIds([]);
    }
  };

  const onSubmit = () => {
    dispatchUpdateRequesting(requestedProductIds);

    const requestingProducts = upsellProducts.filter((gcp) =>
      requestedProductIds.some((pid) => gcp.alloyProduct.parent.every((pf) => pf.productId === pid))
    );

    const additionalCategories = uniq(
      requestingProducts.flatMap((gcp) =>
        gcp.alloyProduct.parent.map((pf) => pf.category)
      ) as ExperienceCategory[]
    );

    const collatedCategories: ExperienceCategory[] = ['mht', ...additionalCategories];

    onNext(collatedCategories);
  };

  return (
    <Layout title='Add On Preferences - Alloy' desc='' noBars>
      <TopBannerWithProgress
        percentComplete={getPercentComplete(location)}
        onBack={isLoading ? undefined : onBack}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CheckoutExperienceSection>
            <h1 className='content-title'>
              Round out your routine. Anything else you'd like to add?
            </h1>
            <p className='content-text'></p>

            {upsellProducts.map((gcp, index) => (
              <SelectableProduct
                key={index}
                groupedProduct={gcp}
                isSelected={requestedProductIds.some((id) =>
                  gcp.alloyProduct.parent.every((pf) => pf.productId === id)
                )}
                onSelect={onSelect}
                multiSelect
                hideDetails
                showProgDetails
              />
            ))}
          </CheckoutExperienceSection>

          <BottomBar>
            <button className='primary-button' type='submit' onClick={onSubmit}>
              Next
            </button>
          </BottomBar>
        </>
      )}
    </Layout>
  );
}
