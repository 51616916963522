import { retrieveCategoriesFromUrl } from 'lib/shared/experience';
import { useLocation } from 'react-router';
import { useAppSelector } from 'reducers/alloy_reducer';

export default function BlurbWrapper() {
  const location = useLocation();

  const cart = useAppSelector((state) => state.experience.alloyCart);

  // TODO: Update for if we ever have more than 2 products later on (maybe mht somehow [probaly not tho])
  const productNames = cart.products
    .flatMap((gcp) => gcp.alloyProduct.parent.map((pf) => pf.cleanName))
    .join(' and ');

  const categories = retrieveCategoriesFromUrl(location);

  // in the instance a customer only checks out with synbiotic, we do not need to show
  // mht / doc copy since they will be able to purchase right away
  const isSynbioticOnly = categories.every((c) => c === 'gut-health');

  const isRequestExperience = location.pathname.includes('request-experience');

  if (isSynbioticOnly) return <></>;

  return (
    <div className='ce-header-wrapper'>
      {!isSynbioticOnly && !isRequestExperience && (
        <p className='header-blurb'>
          Your doctor will create your treatment plan. Usually within 1-3 days.
        </p>
      )}

      {isRequestExperience && (
        <p className='header-blurb'>
          {cart.shipNow
            ? `Your ${productNames} will ship once approved by the doctor.`
            : `Once approved by your doctor, your ${productNames} will arrive with your next shipment.`}
        </p>
      )}
    </div>
  );
}
