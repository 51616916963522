export const REQUIRED_STATES = [
  {
    long: 'Delaware',
    short: 'DE',
  },
  {
    long: 'District of Columbia',
    short: 'DC',
  },
  {
    long: 'Kansas',
    short: 'KS',
  },
  {
    long: 'Missouri',
    short: 'MO',
  },
  {
    long: 'Mississippi',
    short: 'MS',
  },
  {
    long: 'West Virginia',
    short: 'WV',
  },
  {
    long: 'Alaska',
    short: 'AK',
  },
  {
    long: 'Arkansas',
    short: 'AR',
  },
  {
    long: 'Hawaii',
    short: 'HI',
  },
  {
    long: 'Rhode Island',
    short: 'RI',
  },
  {
    long: 'Indiana',
    short: 'IN',
  },
  {
    long: 'Maine',
    short: 'ME',
  },
];
