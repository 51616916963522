import { validateAddress } from 'client/dist/generated/alloy';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Drawer, Form, Loader } from 'rsuite';

import { ProfileUpdates, updateCustomer } from 'actions/core/customer_actions';

import closeIcon from 'assets/svg/btn-close.svg';

import { PhoneField } from 'components/core/fields/PhoneField';
import { SelectField } from 'components/core/fields/SelectField';
import { TextField } from 'components/core/fields/TextField';
import { ZipCodeField } from 'components/core/fields/ZipCodeField';

import { showDashboardAlert, showErrorNotification } from 'components/core/Notification';

import { useAppSelector } from 'reducers/alloy_reducer';

import { sendExceptionToSentry } from 'lib/tracking/sentry';

import states from 'data/basics/states.json';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function AddressDrawer({ open, setOpen }: Props) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const customer = useAppSelector((state) => state.alloy.customer!!);

  const [addressForm, setAddressForm] = useState<ProfileUpdates>({
    shippingAddressLineOne: customer.shippingAddressLineOne ?? '',
    shippingAddressLineTwo: customer.shippingAddressLineTwo ?? '',
    city: customer.city ?? '',
    stateAbbr: customer.stateAbbr ?? '',
    zip: customer.zip ?? '',
    phoneNumber: customer.phoneNumber ?? '',
  });

  const dispatchUpdateCustomer = bindActionCreators(updateCustomer, dispatch);

  const onSave = async () => {
    try {
      setLoading(true);

      const line1Length = addressForm.shippingAddressLineOne
        ? addressForm.shippingAddressLineOne.length
        : 0;
      const line2Length = addressForm.shippingAddressLineTwo
        ? addressForm.shippingAddressLineTwo.length
        : 0;

      if (line1Length + line2Length >= 35) {
        showErrorNotification(
          `Addresses fields (Street Address and Apt#, Floor, etc.) can't have more than 35 characters combined.`
        );

        setLoading(false);
        return;
      }

      const { shippingAddressLineTwo, phoneNumber, ...addressValidationPayload } = addressForm;

      const validAddress = await validateAddress(addressValidationPayload);

      if (validAddress) {
        await dispatchUpdateCustomer(addressForm);

        showDashboardAlert('Successfully updated your shipping address', 'success');
      }

      setLoading(false);
      setOpen(false);
    } catch (error) {
      sendExceptionToSentry(error as Error);
      setLoading(false);
    }
  };

  const onClose = () => {
    if (!loading) {
      setOpen(false);
    }
  };

  return (
    <Drawer open={open} size='sm' className='alloy-drawer address-drawer' onClose={onClose}>
      <Drawer.Body>
        <div className='drawer-header'>
          <p className='header-title'>Shipping Address</p>

          <button onClick={onClose} className='header-btn-close'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>
        </div>

        {loading ? (
          <Loader center size='lg' />
        ) : (
          <>
            <div className='drawer-body'>
              <Form>
                <div className='address-form-wrapper'>
                  <TextField
                    name='line1'
                    label='Address Line 1'
                    value={addressForm.shippingAddressLineOne}
                    placeholder={customer.shippingAddressLineOne}
                    onChange={(value) =>
                      setAddressForm({ ...addressForm, shippingAddressLineOne: value })
                    }
                  />

                  <TextField
                    name='line2'
                    label='Address Line 2 (optional)'
                    value={addressForm.shippingAddressLineTwo}
                    placeholder={customer.shippingAddressLineTwo}
                    onChange={(value) =>
                      setAddressForm({ ...addressForm, shippingAddressLineTwo: value })
                    }
                  />

                  <TextField
                    name='city'
                    label='City'
                    value={addressForm.city}
                    placeholder={customer.city}
                    onChange={(value) => setAddressForm({ ...addressForm, city: value })}
                  />

                  <div className='form-flex'>
                    <SelectField
                      name='stateAbbr'
                      label='State'
                      value={addressForm.stateAbbr ? addressForm.stateAbbr.toUpperCase() : ''}
                      placeholder={customer.stateAbbr}
                      data={states.States}
                      onChange={(value) =>
                        setAddressForm({ ...addressForm, stateAbbr: value?.toUpperCase() ?? '' })
                      }
                    />

                    <ZipCodeField
                      name='zip'
                      label='Zip code'
                      value={addressForm.zip}
                      placeholder={customer.zip}
                      onChange={(value) => setAddressForm({ ...addressForm, zip: value })}
                    />
                  </div>

                  <PhoneField
                    name='phone'
                    label='Phone number'
                    value={addressForm.phoneNumber ?? customer.phoneNumber ?? ''}
                    placeholder='Phone number'
                    onChange={(phoneText) =>
                      setAddressForm({ ...addressForm, phoneNumber: phoneText })
                    }
                  />
                </div>
              </Form>
            </div>

            <div className='drawer-footer'>
              <button
                onClick={onSave}
                className='btn-save'
                disabled={
                  !addressForm.shippingAddressLineOne?.trim() ||
                  !addressForm.city?.trim() ||
                  !addressForm.stateAbbr?.trim() ||
                  !addressForm.zip?.trim() ||
                  !addressForm.phoneNumber?.trim() ||
                  Object.values(addressForm).some((field) => field?.includes('_'))
                }
              >
                Save
              </button>
            </div>
          </>
        )}
      </Drawer.Body>
    </Drawer>
  );
}
