import { AppLocation } from 'components';
import { retrieveCategoriesFromUrl } from 'lib/shared/experience';
import { updateToNewCheckoutExperienceFlow } from '../../actions/checkout-experience/flow_actions';

export const handleNextPath = (dispatch: any, history: any, location: AppLocation) => {
  const searchParams = new URLSearchParams(location.search);
  /**
   * Since login handles potential flow movement in CE, we need to be able to move the user (once the above items are dispatched)
   * over to the next appropriate step. As these actions are redux thunks that we cannot await on, moving the functionality
   * inside here once everything above is good makes the most sense in terms of loading as doing it on its own
   * inside the <Login /> component caused both the dispatch functions to run at the same time and would create
   * problems around otc going from login -> checkout.
   */

  if (searchParams.has('checkout-experience')) {
    // for checkout experience, we need to handle a special way to get them back into the flow properly
    /**
     * Need to refresh the CE flow and place user in correct place
     */
    const categories = retrieveCategoriesFromUrl(location);

    dispatch(updateToNewCheckoutExperienceFlow(categories, history));
  } else if (searchParams.has('nextPath')) {
    // if the customer is trying to get to another page like settings or shipments, we need to redirect properly there and maintain params
    const nextPath = searchParams.get('nextPath');
    searchParams.delete('nextPath');

    history.push(`/${nextPath}?${searchParams.toString()}`);
  } else if (searchParams.toString() !== '') {
    // if the customer has some sort of params that do not include criteria above, then just pass those along
    history.push(`?${searchParams.toString()}`);
  } else {
    //for everything else, just send them to main page!
    history.push({ pathname: '/', search: location.search });
  }
};
