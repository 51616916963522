interface Props {
  title: string;
  children: React.ReactNode;
}

export default function StatBlock({ title, children }: Props) {
  return (
    <section className='section-stat-block'>
      <div className='container justify-content-center'>
        <div className='row d-flex flex-column justify-content-center pt-md-6 pb-md-6 p-3 pt-4 pb-4'>
          <h2 className='title'>{title}</h2>
          <p className='content-text'>{children}</p>
        </div>
      </div>
    </section>
  );
}
