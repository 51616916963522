import { updateToNewCheckoutExperienceFlow } from 'actions/checkout-experience/flow_actions';
import emptyStateIcon from 'assets/svg/core/empty-state.svg';
import { getBuiltFlowFromCategories } from 'lib/checkout-experience/flow';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { bindActionCreators } from 'redux';

interface Props {
  type?:
    | 'NEW'
    | 'RECOMMENDATION'
    | 'NEW_CONSULT'
    | 'CONSULT'
    | 'PENDING'
    | 'PENDING_ORDER'
    | 'OTHER';
  title?: string;
  desc?: string;
  btnTitle?: string;
  btnAction?: () => void;
}

export default function EmptyState({
  type,
  title = '',
  desc = '',
  btnTitle = '',
  btnAction,
}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [emptyTitle, setEmptyTitle] = useState<string>(title);
  const [emptyDesc, setEmptyDesc] = useState<string>(desc);
  const [emptyBtnTitle, setEmptyBtnTitle] = useState<string>(btnTitle);

  const dispatchUpdateToNewCheckoutExperience = bindActionCreators(
    updateToNewCheckoutExperienceFlow,
    dispatch
  );

  useEffect(() => {
    switch (type) {
      case 'NEW':
        setEmptyTitle("Ready for relief? Let's make it happen.");
        setEmptyDesc(
          "It's easy-just answer a few questions and get recommendations within minutes. A doctor will approve everything before we ship it!"
        );
        setEmptyBtnTitle('Start free assessment');
        break;
      case 'NEW_CONSULT':
        setEmptyTitle("Ready for relief? Let's make it happen.");
        setEmptyDesc(
          "Get a virtual consult for just $49. Share your medical history, ask all your questions, and your menopause-trained doctor will create a personalized treatment plan for you. If you choose to move forward, we'll ship everything to your door and you'll have unlimited messaging with your doctor for as long as your prescription is active."
        );
        setEmptyBtnTitle('Start consult');
        break;
      case 'PENDING':
        setEmptyTitle("Relief is on it's way!");
        setEmptyDesc(
          "We'll get back to you soon regarding your consultation or prescriptions. You'll be notified once any treatment plan has been reviewed and approved."
        );
        break;
      case 'PENDING_ORDER':
        setEmptyTitle("Oops! You don't have any past orders yet.");
        setEmptyDesc('Once you start receiving Alloy shipments they will appear here.');
        break;
      default:
        break;
    }
  }, []);

  const handleAction = () => {
    if (btnAction) {
      btnAction();
    } else if (
      type === 'NEW' ||
      type === 'RECOMMENDATION' ||
      type === 'NEW_CONSULT' ||
      type === 'CONSULT'
    ) {
      dispatchUpdateToNewCheckoutExperience(['mht'], history);
    }
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <div className='empty-state-wrapper'>
          <h2 className='empty-state-title'>{emptyTitle}</h2>
          <img src={emptyStateIcon} alt='anxiety icon' className='empty-state-icon' />
          <p className='empty-state-text'>{emptyDesc}</p>

          {emptyBtnTitle !== '' && (
            <button className='btn btn-primary' onClick={handleAction}>
              {emptyBtnTitle}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
