import {
  useGenerateSetupIntent,
  useGetPaymentMethods,
  useGetShippingMethods,
  useGetTreatmentPlan,
  viewedPendingTreatmentPlan,
} from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { isEmpty, xor } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateTPCart } from 'actions/treatment-plan/cart_actions';

import Loader from 'components/core/Loader';
import SectionWrapper from 'components/core/layout/SectionWrapper';
import DoctorBlock from '../core/DoctorBlock';
import HeaderBlock from '../core/HeaderBlock';
import ListSelectableProducts from '../core/ListSelectableProducts';
import TestimonialsSection from '../../shared/sections/Testimonials';
import OrderSummaryBlock from '../submit/OrderSummaryBlock';

import DashboardContainer from 'containers/dashboard/DashboardContainer';

import {
  filterMhtProductsFrom,
  filterNonMhtProductsFrom,
  sortMhtProducts,
} from 'lib/dashboard/treatmentPlan';
import { getGroupedCartTotals } from 'lib/shared/cart';
import { getProductsWithRecurrence, translateToDomProduct } from 'lib/shared/product';
import { getPromotionCodeForCart } from 'lib/shared/promotionCode';

import { useAppSelector } from 'reducers/alloy_reducer';
import { transformDateString } from 'lib/shared/date';
import OrderSummaryProductPerksWrapper from 'components/shared/checkout/layout/OrderSummaryProductPerksWrapper';
import { SINGLE_SUPPLY_ID } from 'common/dist/products/productRegistry';

interface Props {
  onContinueCheckout: () => void;
}

export default function PendingApproval({ onContinueCheckout }: Props) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);

  // Grouped products used to display content
  const [groupedProducts, setGroupedProducts] = useState<GroupedContentfulProduct[][]>([]);

  const customer = useAppSelector((state) => state.alloy.customer!!);
  const cart = useAppSelector((state) => state.treatmentPlan.treatmentPlanCart);

  // Data fetching
  const { data: treatmentPlan } = useGetTreatmentPlan();
  const { data: paymentMethods = [], isLoading: isLoadingPaymentMethods } = useGetPaymentMethods();
  const { data: setupIntent, isLoading: isLoadingSetupIntent } = useGenerateSetupIntent();
  const { isLoading: isLoadingShippingMethods } = useGetShippingMethods(customer.stateAbbr!!);

  const isLoading =
    loading || isLoadingPaymentMethods || isLoadingSetupIntent || isLoadingShippingMethods;

  // Selected products the customer would like to purchase, will be here and in our treatment plan cart
  const [selectedProducts, setSelectedProducts] = useState<GroupedContentfulProduct[]>([]);

  const dispatchUpdateCart = bindActionCreators(updateTPCart, dispatch);

  useEffect(() => {
    auditPending();
  }, []);

  useEffect(() => {
    fetchedProducts();
  }, [JSON.stringify(treatmentPlan)]);

  const fetchedProducts = async () => {
    setLoading(true);

    const products = await getProductsWithRecurrence(
      cart.products.flatMap((gcp) => [
        ...gcp.alloyProduct.parent.map((p) => translateToDomProduct(p)),
        ...(gcp.alloyProduct.child?.map((p) => translateToDomProduct(p)) || []),
      ])
    );

    const mhtProducts = sortMhtProducts(filterMhtProductsFrom(products.flat()));
    const nonMhtProducts = filterNonMhtProductsFrom(products);

    let selected = nonMhtProducts.flat();

    if (mhtProducts.length !== 0) {
      selected.push(mhtProducts[0]);
    }

    dispatchUpdateCart({
      products: selected,
    });

    setGroupedProducts(products);
    setSelectedProducts(selected);
    setLoading(false);
  };

  const auditPending = async () => {
    await viewedPendingTreatmentPlan({
      pendingChargeIds: treatmentPlan!.pendingCharges.map((pc) => pc.id),
    });
  };

  /**
   * Selecting a product and attaching to our cart or removing!
   */
  const onSelectProduct = async (
    product: GroupedContentfulProduct,
    prevSelected: GroupedContentfulProduct[] = selectedProducts
  ) => {
    const selected = xor(prevSelected, [product]);

    const products = (
      await getProductsWithRecurrence(
        selected.flatMap((gcp) => [
          ...gcp.alloyProduct.parent.map((p) => translateToDomProduct(p)),
          ...(gcp.alloyProduct.child?.map((p) => translateToDomProduct(p)) || []),
        ])
      )
    ).flat();

    let promoCodeIfStillValidAfterSelect = await checkPromoCodeValid(products);

    dispatchUpdateCart({
      products,
      promotionCode: promoCodeIfStillValidAfterSelect ?? {
        id: '',
        name: '',
        discountAmount: 0,
      },
    });

    setSelectedProducts(selected);
  };

  const checkPromoCodeValid = async (selectedProducts: GroupedContentfulProduct[]) => {
    try {
      if (!isEmpty(cart.promotionCode?.name ?? '')) {
        return await getPromotionCodeForCart(
          cart.promotionCode.name,
          getGroupedCartTotals(cart).subtotal,
          selectedProducts.flatMap((s) => s.alloyProduct.parent.map((p) => p.stripeProductId))
        );
      } else {
        return undefined;
      }
    } catch (e) {
      // swallow the error since we just want to remove the promo from the subtotal
      return undefined;
    }
  };

  const onSwitch = async (
    prevSelected: GroupedContentfulProduct,
    product: GroupedContentfulProduct
  ) => {
    const switchSelected = selectedProducts.filter(
      (gcp) => gcp.contentfulProduct.sys.id !== prevSelected.contentfulProduct.sys.id
    );

    onSelectProduct(product, switchSelected);
  };

  if (isLoading || !setupIntent) {
    return (
      <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
        <Loader />
      </DashboardContainer>
    );
  }

  const selectedProductFrequencies = selectedProducts
    .map((sp) => [...sp.alloyProduct.parent, ...(sp.alloyProduct.child ?? [])])
    .flat()
    .filter((p) => p.type !== 'OTC'); // filter out otc since if the mht product(s) are a single supply, it should take precedent over it

  const onlySingleSupplySelected =
    !!selectedProductFrequencies.length &&
    selectedProductFrequencies.every((pf) => pf.frequencyId === SINGLE_SUPPLY_ID);

  return (
    <>
      <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
        <HeaderBlock title='Treatment plan for' fancyTitle={customer.firstName ?? ''} />

        <SectionWrapper sectionClasses='dashboard-treatment-plan-section tp-pending-approval-section'>
          <div className='col-12 col-lg-7 tp-pending-approval-selection'>
            <DoctorBlock />

            {treatmentPlan?.messagingWidgetExpiry && (
              <p className='tp-messaging-expiration-banner'>
                {`Approve your treatment plan to feel great and get ${
                  onlySingleSupplySelected ? 'continued' : 'unlimited'
                } doctor messaging. Otherwise, your doctor access ends ${transformDateString(
                  treatmentPlan?.messagingWidgetExpiry,
                  'MM/DD/YYYY'
                )}.`}
              </p>
            )}

            <div className='tp-selected-treatments-section'>
              <p className='selected-text'>{`${selectedProducts.length} ${
                selectedProducts.length > 1 ? `treatments` : `treatment`
              } selected`}</p>
              <p className='selected-info'>
                Please check the treatments you would like to receive. Any treatments you deselect
                will remain in your treatment plan and can be purchased at a later time.
              </p>
            </div>

            <ListSelectableProducts
              products={groupedProducts}
              selectedProducts={selectedProducts}
              onSelect={onSelectProduct}
              onSwitch={onSwitch}
              hideDetails
            />
          </div>

          <div className='col-12 col-lg-5 tp-order-summary-col'>
            <OrderSummaryBlock
              paymentMethods={paymentMethods}
              onContinueCheckout={onContinueCheckout}
              onlySingleSupplySelected={onlySingleSupplySelected}
            />
            <OrderSummaryProductPerksWrapper />
          </div>
        </SectionWrapper>

        <TestimonialsSection />
      </DashboardContainer>
    </>
  );
}
