import { ExperienceCategory } from 'common/dist/models/experience';

export const SELECTIONS: { id: string; text: string; categories: ExperienceCategory[] }[] = [
  {
    id: '1',
    text: 'I am here for menopause or perimenopause relief; I would like to ask a doctor some questions',
    categories: ['mht'],
  },
  {
    id: '2',
    text: 'I am here for skincare only',
    categories: ['skin-health'],
  },
  {
    id: '3',
    text: 'I am here for vaginal & sexual health only',
    categories: ['vaginal-health', 'sexual-health'],
  },
  {
    id: '4',
    text: 'I am here for hair health only',
    categories: ['hair-health'],
  },
  {
    id: '5',
    text: 'I am here for gut health only',
    categories: ['gut-health'],
  },
  {
    id: '6',
    text: 'I am here for several different reasons; I would like to ask a doctor some questions',
    categories: ['mht'],
  },
];
