import { featuresWithLightIcons, mhtSteps } from 'data/checkout-experience/content/expectations';
import FeaturesBlock from './FeaturesBlock';
import StatBlock from './StatBlock';
import Hero from './Hero';
import hairCurly from 'assets/images/checkout-experience/expectations/hair-curly.png';
import Steps from './Steps';

export default function Mht() {
  return (
    <>
      <Hero desktopImgSrc={hairCurly}>
        <h3 className='mht-expectations-header'>
          Next up &ndash; <br /> specialized care.
        </h3>
        <p className='mht-expectations-desc'>We&apos;ll make this quick.</p>
        <Steps steps={mhtSteps} />
      </Hero>
      <StatBlock title='Menopause is treatable'>
        95% of Alloy customers taking MHT have symptom <span>relief</span> in 2 weeks or less
      </StatBlock>
      <FeaturesBlock data={featuresWithLightIcons} customBg='dark-bg' />
    </>
  );
}
