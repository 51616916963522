import {
  Submission,
  SubmissionAnswer,
  postSubmission,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import getIntakesByCategory from 'common/dist/intake/getIntakesByCategory';
import Loader from 'components/core/Loader';
import { fillAnswer } from 'lib/checkout-experience/questionnaire/fillAnswer';
import { getPrescribingDoctorByNpiId } from 'lib/shared/contentful';
import { ContentfulPrescribingDoctor } from 'models/contentful/doctor';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import QuestionnaireRoutes from '../questionnaire/QuestionnaireRoutes';

export default function DoctorSurvey() {
  const history = useHistory();
  const dateStarted = new Date();

  const [isLoading, setIsLoading] = useState(true);
  const [doctor, setDoctor] = useState<undefined | ContentfulPrescribingDoctor>(undefined);
  const [encounterId, setEncounterId] = useState<undefined | number>(undefined);

  const questions = getIntakesByCategory(['doctor-survey']);

  const { data: treatmentPlan } = useGetTreatmentPlan();

  const onBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (treatmentPlan) {
      retrieveDoctor();
    }
  }, [treatmentPlan]);

  const retrieveDoctor = async () => {
    if (treatmentPlan) {
      const prescriptions = treatmentPlan.prescriptionProducts
        .filter((pp) => pp.prescription)
        .map((pp) => pp.prescription!);

      if (prescriptions.length > 0) {
        const mostRecentPrescription = prescriptions[prescriptions.length - 1];
        const mostRecentEncounterId = mostRecentPrescription.encounterId;

        const fetchedDoctor = await getPrescribingDoctorByNpiId(
          mostRecentPrescription.doctorNpiId ?? ''
        );

        if (fetchedDoctor) {
          setDoctor(fetchedDoctor);
        }

        if (mostRecentEncounterId) {
          setEncounterId(mostRecentEncounterId);
        }
      }

      setIsLoading(false);
    }
  };

  const onSubmit = async (answers: SubmissionAnswer[]) => {
    let updatedAnswers: SubmissionAnswer[] = [];
    try {
      setIsLoading(true);

      const doctorNameAnswer = fillAnswer(
        questions,
        answers,
        'doctor-name',
        doctor?.fields.name || 'DOCTOR_NOT_FOUND'
      );

      const encounterIdAnswer = fillAnswer(
        questions,
        answers,
        'encounter-id',
        encounterId?.toString() || 'ENCOUNTER_NOT_FOUND'
      );

      updatedAnswers = [...answers, doctorNameAnswer, encounterIdAnswer];

      const submission: Submission = {
        categories: ['doctor-survey'],
        started: dateStarted.toISOString(),
        answers: updatedAnswers,
      };

      await postSubmission(submission);

      history.push('/doctor-survey-confirmation');
    } catch (error) {
      setIsLoading(false);
    }
  };

  const SurveyIntro = useMemo(() => {
    const doctorName = doctor?.fields.name;
    return (
      <>
        Thank you for taking the time to provide feedback on your most recent doctor interaction
        through Alloy! Please answer the following questions based on your experience with
        {doctorName ? <span className='doctor-name'> {doctorName}</span> : ' your doctor'}:
      </>
    );
  }, [doctor]);

  if (isLoading) return <Loader />;

  return (
    <QuestionnaireRoutes
      questions={questions}
      previousResults={{}}
      onSubmit={onSubmit}
      onBack={onBack}
      hideProgressBar
      parentUrlPath='/doctor-survey'
      metaTitle='Doctor Survey | Alloy'
      introduction={SurveyIntro}
    />
  );
}
