import { useEffect, useState } from 'react';

import {
  ShippingMethodType,
  SubscriptionWithRenewal,
  useGetShippingMethods,
} from 'client/dist/generated/alloy';

import chevronRight from 'assets/svg/core/chevron-right.svg';

import AddressDrawer from 'components/dashboard/account/drawers/AddressDrawer';
import { convertCentsToDollars } from 'lib/shared/convert';
import { cleanFullShippingTitle, getNumberOfDays } from 'lib/shared/shipping';
import { useAppSelector } from 'reducers/alloy_reducer';

interface Props {
  shipment: SubscriptionWithRenewal;
}

export default function ShippingBlock({ shipment }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const customer = useAppSelector((state) => state.alloy.customer!!);

  const { data: shippingMethods = [], isLoading: isLoadingShippingMethods } = useGetShippingMethods(
    customer.stateAbbr!!.toUpperCase()
  );

  const [selectedShippingType, setSelectedShippingType] = useState<ShippingMethodType>('STANDARD');

  const foundSelectedShippingMethod = shippingMethods.find(
    (sm) => sm.method === selectedShippingType
  );

  useEffect(() => {
    const upcomingInvoice = shipment.upcomingInvoice;

    if (upcomingInvoice) {
      const foundShippingMethod = shippingMethods.find(
        (sm) => sm.priceInCents === upcomingInvoice.shipping
      );

      if (foundShippingMethod) {
        setSelectedShippingType(foundShippingMethod.method);
      }
    }
  }, [shippingMethods.length, isLoadingShippingMethods, JSON.stringify(shipment.upcomingInvoice)]);

  const onOpenShipping = () => {
    setIsOpen(true);
  };

  return (
    <div className='shipment-shipping-wrapper'>
      <div className='shipment-header'>
        <div className='row'>
          <div className='col-12 col-md-4'>
            <p className='header-section-title'>Shipping Info</p>
          </div>
          <div className='col-12 col-md-4'>
            <p className='header-title'>Shipping Address</p>
          </div>
          <div className='col-12 col-md-4'>
            <p className='header-title'>Shipping Method</p>
          </div>
        </div>
      </div>

      <div className='shipment-header-mobile'>
        <div className='row'>
          <div className='col-12 col-md-4'>
            <p className='header-section-title'>Billing & Shipping</p>
          </div>
        </div>
      </div>

      <div className='shipping-details'>
        <div className='row'>
          <div className='col-md-4'></div>

          <div className='col-md-4'>
            <div
              className='shipment-content-block top-corner-radius is-openable'
              onClick={onOpenShipping}
            >
              <div className='shipment-content'>
                <p className='content-title'>
                  {customer.firstName} {customer.lastName}
                </p>

                <p className='content-text'>
                  {customer.shippingAddressLineOne} {customer.shippingAddressLineTwo}{' '}
                  {customer.city}
                </p>

                <p className='content-text'>
                  {customer.stateAbbr} {customer.zip} United States
                </p>
              </div>

              <div className='shipment-chevron-wrapper'>
                <img src={chevronRight} alt='chevron right' />
              </div>
            </div>
          </div>

          <div className='col-md-4'>
            <div className='shipment-content-block'>
              <div className='shipment-content'>
                <p className='content-title'>{cleanFullShippingTitle(selectedShippingType)}</p>

                <p className='content-text'>({getNumberOfDays(foundSelectedShippingMethod)})</p>

                <p className='content-text'>
                  ${convertCentsToDollars(foundSelectedShippingMethod?.priceInCents ?? 0)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddressDrawer open={isOpen} setOpen={setIsOpen} />
    </div>
  );
}
