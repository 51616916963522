import { Amplify } from 'aws-amplify';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import { store } from './store';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { AXIOS_INSTANCE } from 'client/dist/authenticatingAxiosInstance';
import error from 'lib/core/error';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_SRP_AUTH',
  },
  Analytics: {
    disabled: true,
  },
});

// load axios interceptors to call the error handler
AXIOS_INSTANCE.interceptors.response.use((r) => r, error.errorHandler);

ReactDOM.render(
  <Provider store={store}>
    <Zendesk
      zendeskKey='d936964e-abff-4d39-9d47-621e55036007'
      onLoaded={() => {
        ZendeskAPI('messenger', 'hide'); // messenger is what they call it ig :shrug:
      }}
    />

    <App />
  </Provider>,
  document.getElementById('root')
);
