import {
  SubscriptionWithRenewal,
  useGenerateSetupIntent,
  useGetPaymentMethods,
} from 'client/dist/generated/alloy';
import { useState } from 'react';

import SectionLoader from 'components/core/Loader';
import RescheduleDrawer from './drawer/RescheduleDrawer';
import ShipNowDrawer from './drawer/ShipNowDrawer';
import HeaderBlock from './individual/HeaderBlock';
import HelpBlock from './individual/HelpBlock';
import OverviewBlock from './individual/OverviewBlock';
import PaymentBlock from './individual/PaymentBlock';
import ShippingBlock from './individual/ShippingBlock';
import PauseModal from './modal/PauseModal';

interface Props {
  shipment: SubscriptionWithRenewal;
  onApplyDiscount: (code: string) => Promise<void>;
  onRemoveDiscount: (code: string) => Promise<void>;
}

export default function SingleShipmentBlock({
  shipment,
  onApplyDiscount,
  onRemoveDiscount,
}: Props) {
  const { data: paymentMethods = [], isLoading: isPaymentMethodsLoading } = useGetPaymentMethods();

  const [isRescheduleOpen, setIsRescheduleOpen] = useState<boolean>(false);
  const [isShipNowOpen, setIsShipNowOpen] = useState<boolean>(false);
  const [isPauseOpen, setIsPauseOpen] = useState<boolean>(false);

  const { data: setupIntent, isLoading } = useGenerateSetupIntent();

  const onReschedule = () => {
    setIsRescheduleOpen(true);
  };

  const onShipNow = () => {
    setIsShipNowOpen(true);
  };

  const onPause = () => {
    setIsRescheduleOpen(false);
    setIsPauseOpen(true);
  };

  if (isLoading || isPaymentMethodsLoading || !setupIntent) return <SectionLoader />;

  const defaultPaymentMethod = paymentMethods.find((pm) => pm.is_primary)!;

  return (
    <div className='dashboard-single-shipment-wrapper'>
      {/* MAIN CONTENT */}
      <HeaderBlock shipment={shipment} onReschedule={onReschedule} onShipNow={onShipNow} />
      <OverviewBlock
        shipment={shipment}
        onApplyDiscount={onApplyDiscount}
        onRemoveDiscount={onRemoveDiscount}
      />
      <ShippingBlock shipment={shipment} />
      <PaymentBlock
        paymentMethod={defaultPaymentMethod}
        stripeClientSecret={setupIntent.client_secret}
      />
      <HelpBlock />

      {/* DRAWERS */}
      <RescheduleDrawer
        shipment={shipment}
        open={isRescheduleOpen}
        setOpen={setIsRescheduleOpen}
        onPause={onPause}
      />
      <ShipNowDrawer shipment={shipment} open={isShipNowOpen} setOpen={setIsShipNowOpen} />

      {/* MODALS */}
      <PauseModal shipment={shipment} open={isPauseOpen} setOpen={setIsPauseOpen} />
    </div>
  );
}
