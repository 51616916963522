import { useLocation } from 'react-router';

import BottomBar from 'components/checkout-experience/BottomBar';
import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import FeaturesBlock from 'components/checkout-experience/expectations/FeaturesBlock';
import HowItWorksBlock from 'components/checkout-experience/expectations/HowItWorksBlock';
import ImprovementBlock from 'components/checkout-experience/expectations/ImprovementBlock';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';

import Layout from 'containers/Layout';

import { features, howItWorks } from 'data/checkout-experience/content/expectations';
import { getPercentComplete } from 'lib/checkout-experience/progress';
import { ExperienceComponentModel } from 'models/alloy/experience';

const HOW_IT_WORKS_THUMBNAIL_URL =
  'https://images.ctfassets.net/md0kv0ejg0xf/5TyPCyFnvuJfvG6QJWq8OB/140cf9e6a0d2947a149127900af1a9b2/how-it-work-no-text.jpeg';
const HOW_IT_WORKS_VIDEO_URL =
  'https://videos.ctfassets.net/md0kv0ejg0xf/5CUqBpNO1XEQKr1D4lbfHt/7c52c3f743bf6ea3f6a096d51d3b9925/Dr_Menn_Consult_Video.mp4';

export default function Expectations({ onNext, onBack }: ExperienceComponentModel) {
  const location = useLocation();

  const onSubmit = () => {
    onNext();
  };

  return (
    <Layout title='Expectations - Alloy' desc='' noBars>
      <TopBannerWithProgress percentComplete={getPercentComplete(location)} onBack={onBack} />

      <CheckoutExperienceSection colClass='col-md-7'>
        <h1 className='content-title'>Here's how it works</h1>

        <HowItWorksBlock data={howItWorks} />

        <ImprovementBlock
          title='95% of Alloy customers '
          text='taking MHT have symptom relief in 2 weeks or less'
        />

        <FeaturesBlock data={features} />
      </CheckoutExperienceSection>

      <BottomBar>
        <button className='primary-button' type='submit' onClick={onSubmit}>
          Continue with intake
        </button>
      </BottomBar>
    </Layout>
  );
}
