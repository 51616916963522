import {
  SubscriptionWithRenewal,
  applyPromoCode,
  removePromoCode,
  useGetAllSubscriptionsForCustomer,
} from 'client/dist/generated/alloy';
import { useState } from 'react';
import { Form } from 'rsuite';

import closeIcon from 'assets/svg/btn-close.svg';

import { TextField } from 'components/core/fields/TextField';

interface Props {
  shipment: SubscriptionWithRenewal;
  onLoading: (isLoading: boolean) => void;
}

export default function PromoCodeBlock({ shipment, onLoading }: Props) {
  const { mutate } = useGetAllSubscriptionsForCustomer();

  const [showPromoCode, setShowPromoCode] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<string>('');

  const onApply = async () => {
    try {
      onLoading(true);

      await applyPromoCode(shipment.stripeSubscriptionId, promoCode);
      await mutate();

      setPromoCode('');
      onLoading(false);
    } catch (error) {
      onLoading(false);
    }
  };

  const onRemove = async () => {
    try {
      onLoading(true);
      await removePromoCode(shipment.stripeSubscriptionId);
      await mutate();

      setPromoCode('');
      onLoading(false);
    } catch (error) {
      onLoading(false);
    }
  };

  if (!shipment.upcomingInvoice) return <></>;

  const { upcomingInvoice } = shipment;

  if (upcomingInvoice.promotionCode) {
    return (
      <div className='ship-discount-wrapper'>
        <div className='discount-content'>
          <button onClick={onRemove} className='discount-close-btn'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>

          <p className='discount-text'>Active Discount: {upcomingInvoice.promotionCode.code}</p>
        </div>
      </div>
    );
  }

  return (
    <div className='ship-discount-wrapper'>
      {showPromoCode ? (
        <div className='discount-content'>
          <button onClick={() => setShowPromoCode(false)} className='discount-close-btn'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>

          <div className='dicount-input-wrapper'>
            <Form>
              <TextField
                name='code'
                placeholder='Discount code'
                classes='discount-input'
                value={promoCode}
                onChange={(value) => setPromoCode(value.toUpperCase())}
              />

              <button className='discount-btn' onClick={onApply}>
                Apply
              </button>
            </Form>
          </div>
        </div>
      ) : (
        <button className='discount-link' onClick={() => setShowPromoCode(true)}>
          Have a Promo Code?
        </button>
      )}
    </div>
  );
}
