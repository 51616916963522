import { Redirect, Route } from 'react-router';

import { Customer } from 'client/dist/generated/alloy';

interface Props {
  component: any;
  basePath: string;
  path: string;
  onNext: () => void;
  onBack?: () => void;
  location: any;
  isAuthenticated: boolean;
  customer?: Customer;
}

const isAuthenticatedAndUserExists = (isAuthenticated: boolean, customer?: Customer) => {
  return isAuthenticated && !!customer;
};

const AuthFlowRoute = ({
  component: Component,
  basePath,
  path,
  onNext,
  onBack,
  location,
  isAuthenticated,
  customer,
}: Props) => {
  const isAuthAndExists = isAuthenticatedAndUserExists(isAuthenticated, customer);
  const url = basePath + path;

  if (!isAuthAndExists) {
    return <Route path={url} render={() => <Component onNext={onNext} onBack={onBack} />} />;
  } else {
    return (
      <Route path={url}>
        <Redirect
          to={{
            pathname: '/',
            search: location.search,
          }}
        />
      </Route>
    );
  }
};

const PrivateFlowRoute = ({
  component: Component,
  basePath,
  path,
  onNext,
  onBack,
  location,
  isAuthenticated,
  customer,
}: Props) => {
  const isAuthAndExists = isAuthenticatedAndUserExists(isAuthenticated, customer);
  const url = basePath + path;

  if (isAuthAndExists) {
    return <Route path={url} render={() => <Component onNext={onNext} onBack={onBack} />} />;
  } else {
    return (
      <Route
        path={url}
        render={() => {
          const searchParams = new URLSearchParams(location.search);

          const path = basePath.replaceAll('/', '');
          const paramsArray: string[][] = [];

          const nextPath = ['nextPath', path];

          paramsArray.push(nextPath);

          searchParams.forEach((value, key) => paramsArray.push([key, value]));

          const nextPathSearchParams = new URLSearchParams(paramsArray);

          return (
            <Redirect
              to={{
                pathname: '/login',
                ...(searchParams.toString() !== 'nextPath=' && {
                  search: nextPathSearchParams.toString(),
                }),
              }}
            />
          );
        }}
      />
    );
  }
};

const PublicFlowRoute = ({
  component: Component,
  basePath,
  path,
  onNext,
  onBack,
}: Omit<Props, 'location' | 'isAuthenticated' | 'customer'>) => {
  const url = basePath + path;

  return <Route path={url} render={() => <Component onNext={onNext} onBack={onBack} />} />;
};

export { AuthFlowRoute, PrivateFlowRoute, PublicFlowRoute, isAuthenticatedAndUserExists };
