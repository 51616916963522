import {
  PreidentifiedCustomer,
  sendTinuitiEvent,
  signUpCustomer,
} from 'client/dist/generated/alloy';
import { Dispatch } from 'redux';

import { postIdentification } from 'actions/core/app_actions';
import { CUSTOMER, IS_AUTHENTICATED } from 'actions/types';

import { RootState } from 'reducers';

import { sendExceptionToSentry } from 'lib/tracking/sentry';
import { trackFreshpaint } from 'lib/tracking/freshpaint';
import { transformDateString } from 'lib/shared/date';
import { signIn } from 'lib/core/awsAuth';
import sendAbandonEvent from 'lib/tracking/sendAbandonEvent';
import { retrieveCategoriesFromUrl } from 'lib/shared/experience';
import { getTinuitiId, getUtms } from 'client/dist/events/tinuiti';

/**
 * What to do after a registration is confirmed?
 *
 * @param email
 * @param password
 * @param code
 * @param isNewUser - whether they used "standalone verification" (new code? forgot pass?)
 */
export const confirmRegistration = (
  email: string,
  password: string,
  code: string,
  isNewUser: boolean
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { localPreCustomer } = getState().experience;

      // now create the customer and confirm cognito user at the same call for BE
      const unsavedCustomer: PreidentifiedCustomer = {
        email,
        ...(localPreCustomer.firstName && { firstName: localPreCustomer.firstName }),
        ...(localPreCustomer.state && { stateAbbr: localPreCustomer.state }),
        ...(localPreCustomer.dateOfBirth && {
          dateOfBirth: transformDateString(localPreCustomer.dateOfBirth, 'YYYY-MM-DD'),
        }),
      };

      const savedCustomer = await signUpCustomer({
        customer: unsavedCustomer,
        awsParams: { code },
      });

      await signIn(savedCustomer.email, password);

      await postIdentification(savedCustomer, localPreCustomer.alloySubmissionId);

      if (isNewUser) {
        trackFreshpaint('REGISTRATION_COMPLETE');

        // when we send the abandon event, we should just send the actual base url (without any children paths)
        // that way it stays consistent and there is no worry about which step to send them to (the code handles that)
        const retrievedCategories = retrieveCategoriesFromUrl(window.location);

        sendAbandonEvent({
          event: 'REGISTRATION_COMPLETE',
          categories: retrievedCategories,
          experience: 'checkout',
        });

        try {
          const utms = getUtms(window.location);
          const tinuitiId = getTinuitiId();
          sendTinuitiEvent({
            event: 'REGISTRATION',
            patientId: savedCustomer.patientId,
            prepurchaseIdentifier: tinuitiId,
            ...(utms.utm_campaign ? { utmCampaign: utms.utm_campaign } : {}),
            ...(utms.utm_source ? { utmSource: utms.utm_source } : {}),
            ...(utms.utm_medium ? { utmMedium: utms.utm_medium } : {}),
          });
        } catch (e) {
          sendExceptionToSentry(e as Error);
        }
      }

      dispatch({ type: CUSTOMER, payload: savedCustomer });

      dispatch({ type: IS_AUTHENTICATED, payload: true });
    } catch (error) {
      sendExceptionToSentry(error as Error);
      throw error;
    }
  };
};
