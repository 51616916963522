import { SubscriptionWithRenewal, TreatmentPlan } from 'client/dist/generated/alloy';

export const getSingleSupplyProducts = (
  subscriptions: SubscriptionWithRenewal[],
  treatmentPlan: TreatmentPlan | undefined
) => {
  if (!treatmentPlan) return [];

  const activeSubs = subscriptions.filter((sub) => sub.status === 'ACTIVE');

  // Filter 1: One time products that are in our prescription products
  // Filter 2: One time products by product id that are not in our subscriptions ie (pill [one time] is not in sub [recurring])
  return treatmentPlan!.prescriptionProducts
    .filter((p) => p.product.recurrenceType === 'ONE_TIME' && p.prescription?.fillsRemaining === 0)
    .filter(
      (p) =>
        !activeSubs
          .flatMap((sub) => sub.products.map((pfr) => pfr.product.productId))
          .includes(p.product.productId)
    );
};
