import { ProductAndFrequency } from 'client/dist/generated/alloy';
import { uniq } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { bindActionCreators } from 'redux';

import { clearCart } from 'actions/checkout-experience/cart_actions';

import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import { EmailUs } from 'components/core/Buttons';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';

import Layout from 'containers/Layout';

import { getPercentComplete } from 'lib/checkout-experience/progress';

interface Props {
  products: ProductAndFrequency[];
}

export default function Disqualified({ products }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const productNames = uniq(products.map((pf) => pf.name));

  const dispatchClearCart = bindActionCreators(clearCart, dispatch);

  const onViewTreatmentPlan = () => {
    dispatchClearCart();

    history.replace('/');
  };

  return (
    <Layout title='Intake - Alloy' desc='' noBars>
      <TopBannerWithProgress percentComplete={getPercentComplete(location)} />

      <CheckoutExperienceSection colClass='col-md-6'>
        <h1 className='content-title'>Looks like this isn't the best treatment for you</h1>
        <p className='content-text'>
          {productNames.length === 1
            ? productNames[0] + " isn't"
            : "Your requested treatments aren't"}{' '}
          a great fit for you based on your responses. If you have any questions, please reach out
          to <EmailUs btnClass='text-underline' />. Want to try something else? Head back to your
          dashboard.
        </p>

        <button className='primary-button full-width-button' onClick={onViewTreatmentPlan}>
          Back to your treatment plan
        </button>
      </CheckoutExperienceSection>
    </Layout>
  );
}
