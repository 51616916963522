import classNames from 'classnames';
import { createMhtDosageChangeReason, useGetTreatmentPlan } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { first, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import Loader from 'components/core/Loader';
import AlloyDrawer from 'components/core/drawers/AlloyDrawer';
import DosageBlock, { DoseForm } from '../containers/DosageBlock';
import TreatmentBlock from '../containers/TreatmentBlock';
import ConfirmChangeDrawer from './ConfirmChangeDrawer';

import {
  ProductFrequenciesWithHasPrescription,
  getFilteredMhtProducts,
} from 'lib/request-experience/mht';
import HeaderBlock, { MhtChangeType } from '../containers/HeaderBlock';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  product: GroupedContentfulProduct;
}

export default function RequestDrawer({ open, setOpen, product }: Props) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const [selectedType, setSelectedType] = useState<MhtChangeType | undefined>(undefined);

  const INIT_FORM: DoseForm = {
    reason: '',
    agreedTerms: false,
  };

  // since we only can change treatment for 'parent' mht products (pill, patch, evamist, estradiol gel), we need to be able to restrict that
  const canChangeTreatment = product.alloyProduct.parent.every((pf) => pf.isBundledParent);
  const canChangeDose = product.alloyProduct.parent.every(
    (pf) => !!pf.dosage && !isEmpty(pf.dosage)
  );

  const [doseForm, setDoseForm] = useState<DoseForm>(INIT_FORM);

  const [mhtProducts, setMhtProducts] = useState<ProductFrequenciesWithHasPrescription[]>([]);
  const [groupedMhtProducts, setGroupedMhtProducts] = useState<GroupedContentfulProduct[]>([]);

  const [selectedProduct, setSelectedProduct] = useState<GroupedContentfulProduct>(product);

  const { data: treatmentPlan, isLoading } = useGetTreatmentPlan();

  useEffect(() => {
    const getMhtForms = async () => {
      if (open) {
        const activePrescriptions = treatmentPlan?.prescriptionProducts ?? [];

        const requestingPfChange = first(product.alloyProduct.parent)!;
        const childrenPfs = product.alloyProduct.child ?? [];

        const alloyProducts = await ProductRegistry.get().alloyProducts;

        const filteredMhtProducts = await getFilteredMhtProducts(
          requestingPfChange,
          childrenPfs,
          activePrescriptions,
          alloyProducts
        );

        const groupedContentful = (
          await ProductRegistry.get().getRecurringProductsForV2(
            filteredMhtProducts.flatMap((fmp) => fmp.productFrequencies.map((p) => p.productId))
          )
        ).flat();

        setMhtProducts(filteredMhtProducts);
        setGroupedMhtProducts(groupedContentful);
      }
    };

    getMhtForms();
  }, [open]);

  const hasPrescriptionFor = (pfId: number) => {
    return mhtProducts.some(
      (pfp) => pfp.productFrequencies.some((pf) => pf.id === pfId) && pfp.hasPrescription
    );
  };

  const onOpenConfirm = (gcp: GroupedContentfulProduct) => {
    setSelectedProduct(gcp);
    setIsConfirmOpen(true);
  };

  const onRequestDose = async () => {
    await createMhtDosageChangeReason({
      reason: doseForm.reason,
    });

    setIsConfirmOpen(true);
  };

  return (
    <>
      <AlloyDrawer
        title='Request a change'
        drawerClass='request-drawer'
        open={open}
        setOpen={setOpen}
        disableClosing={isLoading}
      >
        {isLoading ? (
          <Loader size='lg' />
        ) : (
          <>
            <div
              className={classNames(
                'drawer-body',
                selectedType === 'CUSTOMER_DOSAGE_CHANGE' && 'dosage'
              )}
            >
              <HeaderBlock
                product={product}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
              />

              <div className='drawer-divider' />

              {selectedType === 'CUSTOMER_FORM_FACTOR_CHANGE' && (
                <TreatmentBlock
                  canChangeTreatment={canChangeTreatment}
                  groupedMhtProducts={groupedMhtProducts}
                  hasPrescriptionFor={hasPrescriptionFor}
                  onOpenConfirm={onOpenConfirm}
                />
              )}

              {selectedType === 'CUSTOMER_DOSAGE_CHANGE' && (
                <DosageBlock
                  canChangeDose={canChangeDose}
                  doseForm={doseForm}
                  setDoseForm={setDoseForm}
                />
              )}
            </div>

            {selectedType === 'CUSTOMER_DOSAGE_CHANGE' && canChangeDose && (
              <div className='drawer-footer'>
                <button
                  className='primary-button full-width-button'
                  onClick={onRequestDose}
                  disabled={doseForm.reason === '' || !doseForm.agreedTerms}
                >
                  Continue
                </button>
              </div>
            )}
          </>
        )}
      </AlloyDrawer>

      {selectedType && (
        <ConfirmChangeDrawer
          currentProduct={product}
          requestedProduct={selectedProduct}
          open={isConfirmOpen}
          setOpen={setIsConfirmOpen}
          selectedType={selectedType}
          hasPrescription={hasPrescriptionFor(first(selectedProduct.alloyProduct.parent)!.id)}
        />
      )}
    </>
  );
}
