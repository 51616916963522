import { SerializableNotification } from 'client/dist/generated/alloy';
import { EventType } from 'common/dist/models/event';
import { formatDistanceToNow } from 'date-fns';
import _ from 'lodash';

export interface TransformedNotification {
  id: number;
  title: string;
  message: string;
  timeFromNow: string;
  href: string;
  cta: string;
  count: number;
  eventType: EventType;
}

const getTimeFromNow = (date: string): string => {
  return formatDistanceToNow(new Date(date), { addSuffix: true });
};

export const getEventTypeDetails = (eventType: EventType) => {
  const eventTypeMap: Partial<Record<EventType, { href: string; cta: string }>> = {
    NEW_PRESCRIPTION: { href: '/treatment-plan', cta: 'View Prescription' },
    TREATMENT_PLAN_READY: { href: '/treatment-plan', cta: 'View Plan' },
    TREATMENT_PLAN_UPDATED: { href: '/treatment-plan', cta: 'View Plan' },
    RENEWAL_KICKOFF: { href: '/checkout-experience?categories[]=renewal', cta: 'Start Renewal' },
    PRESCRIPTION_AUTOPAUSED: { href: '/shipments', cta: 'View now' },
    ORDER_SHIPPED: { href: '/account/order-history', cta: 'View now' },
    FAILED_PAYMENT: { href: '/account/profile', cta: 'Update payment' },
    NEW_DOCTOR_MESSAGE: { href: '/messages', cta: 'Read Message' },
    NEW_CX_MESSAGE: { href: '/messages', cta: 'Read Message' },
    REFERRAL_REDEMPTION: { href: '/referrals', cta: 'View now' },
  };
  return eventTypeMap[eventType] || { href: '/', cta: 'View now' };
};

// aggregate notifications by eventType and return the most recent notification of that eventType
export const processNotifications = (
  notifications: SerializableNotification[],
  isMobile: boolean
): TransformedNotification[] => {
  return _(notifications)
    .groupBy('eventType')
    .map((group) => {
      const mostRecent = _.maxBy(group, 'updatedAt')!;
      const { href, cta } = getEventTypeDetails(mostRecent.eventType);
      return {
        id: mostRecent.id,
        title: mostRecent.title,
        message: isMobile ? mostRecent.shortBody : mostRecent.body,
        timeFromNow: getTimeFromNow(mostRecent.updatedAt),
        href,
        cta,
        count: group.length,
        eventType: mostRecent.eventType,
      };
    })
    .sortBy('id')
    .value();
};
