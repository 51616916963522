import classNames from 'classnames';
import {
  processMhtChange,
  submissionByCategoriesExists,
  useGetAllSubscriptionsForCustomer,
  useGetPendingMhtSwitch,
} from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { format } from 'date-fns';
import { first, minBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { bindActionCreators } from 'redux';

import { updateCart } from 'actions/checkout-experience/cart_actions';

import ViewableProduct from 'components/checkout-experience/products/ViewableProduct';
import Loader from 'components/core/Loader';
import AlloyDrawer from 'components/core/drawers/AlloyDrawer';

import { formatExperienceURL } from 'lib/core/url';
import { getGroupedContentfulProductsFrom, getProductFrequencyIdsFrom } from 'lib/shared/product';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  currentProduct: GroupedContentfulProduct;
  requestedProduct: GroupedContentfulProduct;
  selectedType: 'CUSTOMER_FORM_FACTOR_CHANGE' | 'CUSTOMER_DOSAGE_CHANGE';
  hasPrescription: boolean;
}

export default function ConfirmChangeDrawer({
  open,
  setOpen,
  currentProduct,
  requestedProduct,
  selectedType,
  hasPrescription,
}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { data: subscriptions = [], isLoading: isLoadingPlan } =
    useGetAllSubscriptionsForCustomer();
  const { mutate: mutatePendingMhtSwitch } = useGetPendingMhtSwitch();

  const nextShipment = minBy(subscriptions, (sub) => new Date(sub.nextRecurrenceOn));

  const [isLoading, setIsLoading] = useState(true);
  const [shipmentProducts, setShipmentProducts] = useState<GroupedContentfulProduct[]>([]);
  const [hasRecentSubmission, setHasRecentSubmission] = useState<boolean>(false);

  const isLoadingData = isLoading || isLoadingPlan;

  const dispatchUpdateCart = bindActionCreators(updateCart, dispatch);

  useEffect(() => {
    const fetchProducts = async () => {
      const products =
        nextShipment?.products
          .filter((pfr) => pfr.product.category !== 'mht')
          .map((pfr) => pfr.product) ?? [];

      const groupedContentful = await getGroupedContentfulProductsFrom(products);

      setShipmentProducts(groupedContentful);
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const checkRecent = async () => {
      const exists = await submissionByCategoriesExists({
        categories: ['mht'],
        timeAmount: 6,
        timeUnit: 'month',
        checkAllCategories: false,
      });

      setHasRecentSubmission(exists);
      setIsLoading(false);
    };

    checkRecent();
  }, []);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      dispatchUpdateCart({
        products: [requestedProduct],
        checkoutType: selectedType,
      });

      const currentMhtPfIds = getProductFrequencyIdsFrom(currentProduct);
      const requestedMhtPfIds = getProductFrequencyIdsFrom(requestedProduct);

      if (hasRecentSubmission) {
        await processMhtChange({
          checkoutType: selectedType,
          currentMhtPfIds,
          requestedMhtPfIds,
        });

        await mutatePendingMhtSwitch();

        const searchParams = new URLSearchParams(location.search);

        searchParams.append('hasPrescription', hasPrescription.toString());
        searchParams.append('checkoutType', selectedType);

        history.push({
          pathname: '/request-confirmation',
          search: searchParams.toString(),
        });
      } else {
        // as we do anywhere, just send the customer to the basic url and from there, the request experience
        // will handle where to place them and check and restrictions
        const experienceUrl = formatExperienceURL(
          '/request-experience',
          location,
          ['mht'],
          [
            ['currentMhtPfIds[]', currentMhtPfIds.join(',')],
            ['requestedMhtPfIds[]', requestedMhtPfIds.join(',')],
            ['hasPrescription', hasPrescription.toString()],
            ['checkoutType', selectedType],
          ]
        );

        history.push(experienceUrl);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getButtonCopy = () => {
    switch (true) {
      case hasPrescription:
        return 'Continue';
      case hasRecentSubmission:
        return 'Confirm request';
      default:
        return 'Start intake';
    }
  };

  return (
    <AlloyDrawer
      title='Confirm change'
      drawerClass={classNames('confirm-change-drawer', !isLoadingData && 'show-footer')}
      open={open}
      setOpen={setOpen}
      disableClosing={isLoadingData}
    >
      {isLoadingData ? (
        <Loader size='lg' />
      ) : (
        <>
          <div className='drawer-body'>
            <div className='request-content-wrapper'>
              <p className='content-blurb'>
                {hasRecentSubmission && selectedType === 'CUSTOMER_FORM_FACTOR_CHANGE'
                  ? 'Your new treatment will be included in your next shipment'
                  : `If your doctor approves your new ${
                      selectedType === 'CUSTOMER_DOSAGE_CHANGE' ? 'dosage' : 'treatment'
                    } it will be included in your next shipment`}
                {nextShipment &&
                  `, scheduled for ${format(
                    new Date(nextShipment.nextRecurrenceOn),
                    'MM/dd/yyyy'
                  )}`}
                .
              </p>

              <div className='request-products-wrapper'>
                <ViewableProduct
                  groupedProduct={requestedProduct}
                  showDosage={selectedType === 'CUSTOMER_FORM_FACTOR_CHANGE'}
                  hideProductInformation
                  hideDetails
                  hidePrice={selectedType === 'CUSTOMER_DOSAGE_CHANGE'}
                >
                  <span className='product-hug'>
                    {selectedType === 'CUSTOMER_DOSAGE_CHANGE'
                      ? 'New dose requested'
                      : 'New treatment'}
                  </span>
                </ViewableProduct>

                {shipmentProducts.length !== 0 && (
                  <>
                    <p className='request-text'>Included in your next shipment</p>

                    {shipmentProducts.map((gcp, index) => (
                      <ViewableProduct
                        key={index}
                        groupedProduct={gcp}
                        showDosage
                        hideProductInformation
                        hideDetails
                        hidePrice={selectedType === 'CUSTOMER_DOSAGE_CHANGE'}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className='drawer-footer'>
            <button className='primary-button full-width-button' onClick={onSubmit}>
              {getButtonCopy()}
            </button>
          </div>
        </>
      )}
    </AlloyDrawer>
  );
}
