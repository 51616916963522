import { useLocation } from 'react-router';

import BundledContent from 'components/checkout-experience/review/layout/BundledContent';
import DoctorConsultContent from 'components/checkout-experience/review/layout/DoctorConsultContent';
import IndividualContent from 'components/checkout-experience/review/layout/IndividualContent';

import Layout from 'containers/Layout';

import { isConsultCart } from 'lib/checkout-experience/checkout/cart';
import { retrieveCategoriesFromUrl } from 'lib/shared/experience';

import { ExperienceComponentModel } from 'models/alloy/experience';

import { useAppSelector } from 'reducers/alloy_reducer';

// TODO: Last piece around submission / suggestion to be handleded in the work after checkout ticket to tie things together!
export default function Review({ onNext, onBack }: ExperienceComponentModel) {
  const location = useLocation();

  const categories = retrieveCategoriesFromUrl(location);

  const cart = useAppSelector((state) => state.experience.alloyCart);

  const isConsult = isConsultCart(cart);

  // TODO: Add the suggestion here for skin health
  const bundledCategories = [['skin-health'], ['sexual-health', 'vaginal-health']];
  const isBundled = categories.every((c) => bundledCategories.some((bc) => bc.includes(c)));

  // This switch handles the hierchy of which review content to show, mht is always the top one and then from there we go down
  switch (true) {
    case isConsult: {
      return (
        <Layout title='Review - Alloy' desc='' noBars>
          <DoctorConsultContent onBack={onBack} onNext={onNext} />
        </Layout>
      );
    }

    case isBundled: {
      return (
        <Layout title='Review - Alloy' desc='' noBars>
          <BundledContent onBack={onBack} onNext={onNext} />
        </Layout>
      );
    }

    default: {
      return (
        <Layout title='Review - Alloy' desc='' noBars>
          <IndividualContent onBack={onBack} onNext={onNext} />
        </Layout>
      );
    }
  }
}
