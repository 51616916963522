import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import SectionWrapper from 'components/core/layout/SectionWrapper';
import { format } from 'date-fns';
import { minBy } from 'lodash';
import { useAppSelector } from 'reducers/alloy_reducer';
import ActionsWrapper from '../wrappers/ActionsWrapper';
import CarouselWrapper from '../wrappers/CarouselWrapper';

export default function HeaderSection() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  // no matter the status, the first subscription is when a customer became an actual member of alloy
  const firstSubscription = minBy(subscriptions, (sub) => new Date(sub.startedOn));

  // TODO: Notification fetching to be included here that way they can be integrated into the customer STATES

  return (
    <SectionWrapper sectionClasses='dashboard-home-header-section'>
      <div className='col-12'>
        <h1 className='header-title'>Hi, {customer.firstName}</h1>

        {customer.status === 'ACTIVE' && !!firstSubscription && (
          <p className='header-text'>
            Member since {format(new Date(firstSubscription.startedOn), 'MMMM yyyy')}
          </p>
        )}
      </div>

      <div className='col-12'>
        <CarouselWrapper />

        <ActionsWrapper />
      </div>
    </SectionWrapper>
  );
}
