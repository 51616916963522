/**
 * @function formatPhone
 * @description This function formats the phone to contain 10 digits in a string
 * @access public
 *
 * @param {string} phone Any kind of phone that can be in any format
 *
 * @return {string} Returns a formatted phone number as 10 characters
 */
export const formatPhone = (phone: string): string => {
  const number = phone.replace(/\D/g, ''); // Removes all non-int characters

  if (number.length === 10) {
    return number;
  } else if (number.length === 11 && number[0] === '1') {
    return number.substring(1);
  } else {
    return phone; // Just return the initial phone
  }
};

/**
 * Pretty print phone number back out.
 *
 * Expects a string of numbers like 6088675309. Clean first with the above method
 * if you're not sure that's what you have.
 *
 * Returns the pretty printed phone, or what was given if we don't match 10 digits numeric.
 *
 * @param phoneDigits
 */
export const prettyPrintPhone = (phoneDigits: string): string => {
  const match = phoneDigits.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? '(' + match[1] + ') ' + match[2] + '-' + match[3] : phoneDigits;
};
