import { useGetAllOrders } from 'client/dist/generated/alloy';

import EmptyState from '../../EmptyState';
import ContentBlock from './ContentBlock';
import HeaderBlock from './HeaderBlock';

import { Skeleton } from '@mui/material';
import { getTime } from 'date-fns';
import { useAppSelector } from 'reducers/alloy_reducer';

export default function OrdersWrapper() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const { data: orders = [], isLoading } = useGetAllOrders();

  orders.sort((a, b) => getTime(new Date(b.createdAt!)) - getTime(new Date(a.createdAt!)));

  const toggleOrder = (id: string) => {
    orders.forEach((order) => {
      const orderId = order.fulfillmentCorrelationId;
      const orderToClose = document.getElementById(orderId);

      !!orderToClose && id !== orderId && orderToClose.classList.remove('active');
    });

    const orderToOpen = document.getElementById(id);

    if (orderToOpen) {
      if (orderToOpen.classList.contains('active')) {
        orderToOpen.classList.remove('active');
      } else {
        orderToOpen.classList.add('active');
      }
    }
  };

  if (isLoading) {
    return (
      <>
        <Skeleton variant='rectangular' height={200} className='mb-3' />
        <Skeleton variant='rectangular' height={200} />
      </>
    );
  }

  if (orders.length === 0 && customer.status === 'NEW') {
    return <EmptyState type='NEW' />;
  }

  if (orders.length === 0 && customer.status === 'PENDING') {
    return <EmptyState type='PENDING_ORDER' />;
  }

  return (
    <div className='row'>
      <div className='col-12'>
        {orders.map((order, index) => (
          <div id={order.fulfillmentCorrelationId} className='order-wrapper' key={index}>
            <HeaderBlock order={order} setOpen={toggleOrder} />

            <ContentBlock order={order} />
          </div>
        ))}
      </div>
    </div>
  );
}
