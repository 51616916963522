var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { RecurrenceType } from "common/dist/products/productFrequency";
import { getAllProductFrequencies, getAllShippingMethods, getDiscountBundles, } from "../generated/alloy";
import { ESTRADIOL_GEL_1_PRODUCT_ID, ESTRADIOL_05_PATCH_PRODUCT_ID, ESTRADIOL_PILL_1_PRODUCT_ID, EVAMIST_PRODUCT_ID, LDBC_PRODUCT_ID, M4_PRODUCT_ID, OMAZING_PRODUCT_ID, PAROXETINE_PRODUCT_ID, ProductRegistry, PROGESTERONE_100_PRODUCT_ID, SYNBIOTIC_PRODUCT_ID, TRETINOIN_03_ID, VAGINAL_CREAM_PRODUCT_ID, } from "common/dist/products/productRegistry";
import { getContentfulProducts } from "../contentful/client";
import _, { partition } from "lodash";
export const productFrequencyTranslator = (pf) => {
    return Object.assign(Object.assign({}, pf), { createdAt: new Date(pf.createdAt), updatedAt: new Date(pf.updatedAt), recurrenceType: pf.recurrenceType });
};
export const shippingMethodTranslator = (sm) => {
    // Spreading and recombining the properties here so I can cast the stripePrice
    // as Stripe.Price. Something seems to get lost with the Stripe types when
    // generating the client that this works around.
    const { stripePrice } = sm, shippingMethod = __rest(sm, ["stripePrice"]);
    return Object.assign(Object.assign({}, shippingMethod), { stripePrice: stripePrice });
};
export class DomProductRegistry extends ProductRegistry {
    constructor(fetcher) {
        super(fetcher);
        /**
         * @param type 'specific' | 'mht' | 'upsell'
         *    specific - allows for passing product ids that can be used to fetch a parent / contentful product
         * @param specificProductIds if we want to filter for specific products ids
         * @returns ParentContentfulProduct[]
         */
        this.getIndividualGroupedContentfulProductsFor = (type, specificProductIds = []) => __awaiter(this, void 0, void 0, function* () {
            const typeWithProductIds = {
                specific: specificProductIds,
                mht: [
                    ESTRADIOL_PILL_1_PRODUCT_ID,
                    ESTRADIOL_05_PATCH_PRODUCT_ID,
                    EVAMIST_PRODUCT_ID,
                    PROGESTERONE_100_PRODUCT_ID,
                    ESTRADIOL_GEL_1_PRODUCT_ID,
                    LDBC_PRODUCT_ID,
                    PAROXETINE_PRODUCT_ID,
                ],
                upsell: [
                    M4_PRODUCT_ID,
                    TRETINOIN_03_ID,
                    OMAZING_PRODUCT_ID,
                    SYNBIOTIC_PRODUCT_ID,
                    VAGINAL_CREAM_PRODUCT_ID,
                ],
            };
            const productIds = typeWithProductIds[type];
            // makes sure it gets unbundled price for every product
            return this.sortGroupedContentfulProducts((yield Promise.all(productIds.map((productId) => this.getRecurringProductsForV2([productId])))).flat());
        });
        /**
         * Given some grouped products, this function finds the best contentful product for them and creates a grouped contentful item which is outputted as an array.
         * The contentful product will always be 1-1 if the grouped product is not a combination product otherwise we will find the contentful product that matches
         * the parent and child combination (applicableMhtParent) and (applicableMhtChild). this will allow us to best display more information to the user using the
         * contentful product all while keeping true the grouped product data to send to the backend!
         *
         * @param groupedProducts
         * @returns GroupedContentfulProduct[]
         */
        this.getContentfulProductsFor = (groupedProducts) => __awaiter(this, void 0, void 0, function* () {
            const contentfulProducts = yield this.contentfulProducts;
            return groupedProducts.map((gp) => {
                if (gp.child && gp.child.length > 0) {
                    const foundCombinedProduct = contentfulProducts
                        .filter((cp) => cp.fields.groupingProductIds)
                        .find((cp) => {
                        const grouped = cp.fields.groupingProductIds;
                        return (
                        /**
                         * We need to consider new contentful products with applicableBundledParent
                         * but there can be old products with applicableMhtParent instead.
                         */
                        (grouped["applicableMhtParent"] ||
                            grouped["applicableBundledParent"]).some((id) => gp.parent.map((pf) => pf.productId).includes(id)) &&
                            (grouped["applicableMhtChild"] ||
                                grouped["applicableBundledChild"]).some((id) => gp.child.map((pf) => pf.productId).includes(id)));
                    });
                    return {
                        alloyProduct: gp,
                        contentfulProduct: foundCombinedProduct,
                    };
                }
                else {
                    const contentfulIds = gp.parent.map((pf) => pf.contentfulProductId);
                    const foundProduct = contentfulProducts.find((cp) => contentfulIds.includes(cp.sys.id));
                    return {
                        alloyProduct: gp,
                        contentfulProduct: foundProduct,
                    };
                }
            });
        });
        /**
         * Filters products that are not mht and returns those for using them as upsell products for the customer
         *
         * @returns ContentfulProduct[]
         */
        this.getUpsellContentfulProducts = () => __awaiter(this, void 0, void 0, function* () {
            return (yield this.contentfulProducts).filter((cp) => !cp.fields.isMht);
        });
        /**
         * Given purchase product ids, not purchased product ids and the upsell product ids coming from FE
         * transform and decide which products should we show in the "Round out your routine" section
         * also, check if parent of bundled product is in the context of purchased products
         * and get the discounted price to show there if that's the case.
         *
         * @param purchasedProductIds
         * @param notPurchasedProductIds
         * @param upsellProductIds
         * @returns GroupedContentfulProduct[]
         */
        this.getNewUpsellContentfulProducts = (purchasedProductIds, notPurchasedProductIds, upsellProductIds) => __awaiter(this, void 0, void 0, function* () {
            const allUpsellProductIds = _.uniq([
                ...upsellProductIds,
                ...purchasedProductIds,
                ...notPurchasedProductIds,
            ]);
            const allUpsellProducts = (yield this.getRecurringProductsForV2(allUpsellProductIds)).flat();
            const upsellProductsContentfulIds = (yield this.getUpsellContentfulProducts()).map((ucp) => ucp.sys.id);
            return allUpsellProducts
                .filter((aup) => {
                const isNotPurchased = aup.alloyProduct.parent.some((p) => !purchasedProductIds.includes(p.productId));
                const isUpsellContentfulProduct = aup.contentfulProduct
                    ? upsellProductsContentfulIds.includes(aup.contentfulProduct.sys.id)
                    : false;
                return isNotPurchased && isUpsellContentfulProduct;
            })
                .sort((a, b) => a.contentfulProduct.fields.order - b.contentfulProduct.fields.order);
        });
        this.contentfulProducts = fetcher.fetchContentfulProducts();
    }
    /**
     * Given a list of product frequencies from a bundle, it returns if the list
     * can be split in different GroupedContentfulProducts or not.
     * Currently, the condition for it to be splittable is to not be from MHT category.
     *
     * @param productFrequencies
     * @returns boolean
     */
    isBundleSplittable(productFrequencies) {
        return !productFrequencies.every((pf) => pf.category === "mht");
    }
    /**
     * Given a GroupedProduct, this function find its ContentfulProduct equivalent,
     * if the product is non splitabble.
     *
     * @param contentfulProducts
     * @param alloyProduct
     * @returns ContentfulProduct
     */
    getNonSplittableContentfulProduct(contentfulProducts, alloyProduct) {
        return contentfulProducts
            .filter((cp) => cp.fields.groupingProductIds)
            .find((cp) => {
            var _a, _b;
            const grouped = cp.fields.groupingProductIds;
            return (((_a = grouped["applicableMhtParent"]) === null || _a === void 0 ? void 0 : _a.some((id) => alloyProduct.parent.map((pf) => pf.productId).includes(id))) &&
                ((_b = grouped["applicableMhtChild"]) === null || _b === void 0 ? void 0 : _b.some((id) => (alloyProduct.child || []).map((pf) => pf.productId).includes(id))));
        });
    }
    /**
     * Given a GroupedContentfultProduct 2D array,
     * this function returns the same array
     * but ordered by its Contentful order.
     *
     * @param groupedContentfulProducts
     * @returns GroupedContentfulProduct[][]
     */
    sortGroupedContentfulProducts(groupedContentfulProducts) {
        return groupedContentfulProducts
            .map((gcpList) => gcpList.sort((a, b) => a.contentfulProduct.fields.order - b.contentfulProduct.fields.order))
            .sort((a, b) => a[0].contentfulProduct.fields.order -
            b[0].contentfulProduct.fields.order);
    }
    /**
     * Given a list of productIds, it returns their equivalent GroupedContentfulProduct array.
     * The selected product frequencies will be determined by their prices,
     * which may vary if a product is part of a bundle.
     * If any of the products are present in a bundle that can't be split, they will be together
     * in a single GroupedContentfulProduct with children.
     *
     * @param productIds
     * @returns Promise<GroupedContentfulProduct[]>
     */
    getRecurringProductsForV2(productIds, recurrenceType = RecurrenceType.RECURRING) {
        return __awaiter(this, void 0, void 0, function* () {
            const [bundles, contentfulProducts, prices] = yield Promise.all([
                this.discountBundles,
                this.contentfulProducts,
                this.getPricesFor(productIds, recurrenceType),
            ]);
            const groupedContentfulProducts = prices.flatMap((bundle) => {
                const [child, parent] = partition(bundle, (b) => bundles[b.productId]);
                const hasNonSplittableProducts = !this.isBundleSplittable(bundle) && parent.length && child.length;
                if (hasNonSplittableProducts) {
                    return parent.map((pf) => {
                        const alloyProduct = { parent: [pf], child };
                        const contentfulProduct = this.getNonSplittableContentfulProduct(contentfulProducts, alloyProduct);
                        return [{ alloyProduct, contentfulProduct }];
                    });
                }
                return [
                    bundle.map((pf) => ({
                        alloyProduct: { parent: [pf] },
                        contentfulProduct: contentfulProducts.find((cp) => cp.sys.id === pf.contentfulProductId),
                    })),
                ];
            });
            return this.sortGroupedContentfulProducts(groupedContentfulProducts);
        });
    }
    static getInstance(fetcher) {
        if (!DomProductRegistry.domInstance) {
            DomProductRegistry.domInstance = new DomProductRegistry(fetcher);
        }
        return DomProductRegistry.domInstance;
    }
}
/**
 * Use this primarily, because it's way easier to mock with sinon
 */
const get = () => DomProductRegistry.getInstance({
    fetch: () => __awaiter(void 0, void 0, void 0, function* () { return (yield getAllProductFrequencies()).map(productFrequencyTranslator); }),
    fetchShippingMethods: () => __awaiter(void 0, void 0, void 0, function* () { return (yield getAllShippingMethods()).map(shippingMethodTranslator); }),
    fetchContentfulProducts: () => __awaiter(void 0, void 0, void 0, function* () {
        return yield getContentfulProducts();
    }),
    fetchDiscountBundles: () => __awaiter(void 0, void 0, void 0, function* () {
        return yield getDiscountBundles();
    }),
});
export default { get };
