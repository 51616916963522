import { capitalize } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { bindActionCreators } from 'redux';
import { Form } from 'rsuite';

import { updateLocalPreCustomer } from 'actions/core/app_actions';

import BottomBar from 'components/checkout-experience/BottomBar';
import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import { SelectField } from 'components/core/fields/SelectField';
import { TextField } from 'components/core/fields/TextField';
import SectionWrapper from 'components/core/layout/SectionWrapper';

import Layout from 'containers/Layout';

import states from 'data/basics/states.json';

import { getPercentComplete } from 'lib/checkout-experience/progress';
import { brazeSetPartial } from 'lib/tracking/braze';

import { ExperienceComponentModel } from 'models/alloy/experience';

import { shouldSkipCategorySelect } from 'lib/core/url';
import { useAppSelector } from 'reducers/alloy_reducer';

type CustomerForm = {
  firstName: string;
  state: string;
};

const HOW_IT_WORKS_THUMBNAIL_URL =
  'https://images.ctfassets.net/md0kv0ejg0xf/3VN6yfHTr5FaCR5fTSJFh4/a2fb5b26131f984f38dfb91ff660e3f3/how_it_works.jpeg';
const HOW_IT_WORKS_VIDEO_URL =
  'https://videos.ctfassets.net/md0kv0ejg0xf/5CUqBpNO1XEQKr1D4lbfHt/7c52c3f743bf6ea3f6a096d51d3b9925/Dr_Menn_Consult_Video.mp4';

export default function Name({ onNext }: Omit<ExperienceComponentModel, 'onBack'>) {
  const dispatch = useDispatch();
  const location = useLocation();

  const customer = useAppSelector((state) => state.alloy.customer);
  const localPreCustomer = useAppSelector((state) => state.experience.localPreCustomer);

  const skipCategorySelect = shouldSkipCategorySelect(location);

  const defaultFirstName: string =
    (customer ? customer.firstName : localPreCustomer.firstName) ?? '';

  const defaultState: string = (customer ? customer.stateAbbr : localPreCustomer.state) ?? '';

  const INIT_FORM = {
    firstName: defaultFirstName,
    state: defaultState,
  };

  const [customerForm, setCustomerForm] = useState<CustomerForm>(INIT_FORM);

  const dispatchUpdateLocalPreCustomer = bindActionCreators(updateLocalPreCustomer, dispatch);

  const onSubmit = async () => {
    dispatchUpdateLocalPreCustomer(customerForm);

    brazeSetPartial({
      ...localPreCustomer,
      ...customerForm,
    });

    onNext();
  };

  return (
    <Layout title='Name - Alloy' desc='' noBars>
      <TopBannerWithProgress percentComplete={getPercentComplete(location)} />

      <Form>
        <SectionWrapper
          sectionClasses='checkout-experience-section'
          rowClasses='justify-content-center'
        >
          <div className='col-12 col-md-6'>
            <div className='checkout-experience-content-wrapper'>
              <h1 className='content-title'>Tell us about you</h1>

              <p className='content-text'>
                We'll use this to personalize your experience and match you with a doctor licensed
                in your state.
              </p>

              <TextField
                label='First name'
                name='firstName'
                placeholder='First name'
                value={customerForm.firstName}
                onChange={(value) =>
                  setCustomerForm({ ...customerForm, firstName: capitalize(value.trim()) })
                }
              />

              <SelectField
                label='Your state'
                name='state'
                placeholder='Select state'
                value={customerForm.state}
                data={states.States}
                onChange={(value) => setCustomerForm({ ...customerForm, state: value ?? '' })}
              />
            </div>
          </div>

          {!skipCategorySelect && (
            <div className='col-12 col-md-4'>
              <div className='content-video-wrapper'>
                <video controls preload='metadata' poster={HOW_IT_WORKS_THUMBNAIL_URL}>
                  <source src={HOW_IT_WORKS_VIDEO_URL} type='video/mp4' />
                  Your browser does not support videos
                </video>
              </div>
            </div>
          )}
        </SectionWrapper>

        <BottomBar>
          <button
            className='primary-button'
            type='submit'
            disabled={Object.values(customerForm).some((val) => val === '')}
            onClick={onSubmit}
          >
            Next
          </button>
        </BottomBar>
      </Form>
    </Layout>
  );
}
