import { Question as AlloyQuestion } from 'common/dist/models/questionnaire';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { Form } from 'rsuite';

import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';
import BottomBar from '../BottomBar';
import TopBannerWithProgress from '../TopBannerWithProgress';
import { Question } from './Question';

import Layout from 'containers/Layout';

import { getPercentComplete } from 'lib/checkout-experience/progress';
import { validDob } from 'lib/shared/date';
import { showErrorNotification } from 'components/core/Notification';

import { RelevantProduct } from 'models/alloy/experience';
import { QuestionnaireAnswers } from 'models/components/questionnaire';

interface Props {
  questions: AlloyQuestion[];
  allAnswers: QuestionnaireAnswers;
  handleNext: (newAnswers: QuestionnaireAnswers) => void;
  handleBack?: () => void;
  hideProgressBar?: boolean;
  metaTitle?: string;
  relevantProducts?: RelevantProduct[];
  introduction?: JSX.Element;
  type?: 'survey' | 'intake';
  isLastQuestion?: boolean;
}

/**
 * This component handles one question at a time to allow one question per page
 */
export default function Questionnaire({
  questions,
  allAnswers,
  handleNext,
  handleBack,
  hideProgressBar,
  metaTitle = 'Intake - Alloy',
  relevantProducts = [],
  introduction,
  type = 'intake',
  isLastQuestion = false,
}: Props) {
  const location = useLocation();

  const [answers, setAnswers] = useState<QuestionnaireAnswers>(allAnswers);
  const [disableButton, setDisableButton] = useState<boolean>(true);

  const questionnaireWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fadeQuestions('fade-in-wrapper');
  });

  const fadeQuestions = (fade: 'fade-in-wrapper' | 'fade-out-wrapper') => {
    const questionnaireWrapperNode = questionnaireWrapper.current;

    questionnaireWrapperNode !== null && questionnaireWrapperNode.classList.add(fade);
  };

  const handleUpdateAnswers = (updatedAnswers: QuestionnaireAnswers) => {
    // TODO: Most likely in parent handleNext/onNext, take care of children answers related to changed parent answers,
    // this is deleting answers that are no longer necessary as the parent ones have been changed

    questions.forEach((question) => {
      if (updatedAnswers[question.slug] === '') {
        delete updatedAnswers[question.slug];
      }
    });

    setAnswers(updatedAnswers);

    if (
      questions.every(
        (question) => updatedAnswers[question.slug] && question.type === 'SingleChoice'
      )
    ) {
      fadeQuestions('fade-out-wrapper');

      setTimeout(() => handleNext(updatedAnswers), 400);
    }
  };

  const anyRequiredAnswersEmpty: () => boolean = () => {
    return questions.some((question) => {
      const answer: string | string[] | undefined = answers[question.slug];

      return (
        question.isRequired &&
        (!answer || answer === '' || answer.length === 0) &&
        question.type !== 'Information' &&
        question.type !== 'Hidden'
      );
    });
  };

  const onNext = () => {
    const questionsValidated = questions.some((q) => {
      if (q.type === 'Date' && answers[q.slug]) {
        const { error } = validDob(answers[q.slug] as string);

        if (error) {
          showErrorNotification(error);
        }

        return !error;
      } else {
        return true;
      }
    });

    if (!questionsValidated) return;

    setDisableButton(true);

    fadeQuestions('fade-out-wrapper');

    setTimeout(() => handleNext(answers), 400);
  };
  return (
    <Layout title={metaTitle} desc='' noBars>
      <TopBannerWithProgress
        onBack={handleBack}
        percentComplete={hideProgressBar ? undefined : getPercentComplete(location)}
      />

      <div className='questionnaire-wrapper' ref={questionnaireWrapper}>
        <CheckoutExperienceSection>
          <Form>
            {questions.map((question, index) => (
              <Question
                key={index}
                question={question}
                answers={answers}
                setAnswers={handleUpdateAnswers}
                relevantProducts={relevantProducts}
                introduction={introduction}
              />
            ))}
          </Form>
        </CheckoutExperienceSection>

        <BottomBar>
          <button
            className='primary-button'
            type='submit'
            disabled={anyRequiredAnswersEmpty() && disableButton}
            onClick={onNext}
          >
            {type === 'survey' && isLastQuestion ? 'Submit' : 'Next'}
          </button>
        </BottomBar>
      </div>
    </Layout>
  );
}
