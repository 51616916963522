import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';
import { format } from 'date-fns';
import { useHistory } from 'react-router';

import chevronLeft from 'assets/images/chevronLeft.png';

interface Props {
  shipment: SubscriptionWithRenewal;
  onReschedule: () => void;
  onShipNow: () => void;
}

export default function HeaderBlock({ shipment, onReschedule, onShipNow }: Props) {
  const history = useHistory();

  return (
    <div className='row'>
      <div className='col-12'>
        <div className='shipment-back-btn-wrapper'>
          <button className='shipment-back-btn' onClick={() => history.goBack()}>
            <img src={chevronLeft} alt='Back icon' className='back-icon' />
            <span className='back-text'>Back</span>
          </button>
        </div>
      </div>

      <div className='col-12'>
        <div className='shipment-header-wrapper'>
          <div className='shipment-header-content'>
            <h1 className='shipment-title'>Upcoming Shipments</h1>
            <p className='shipment-text'>
              <span>Next bill date: </span>
              {format(new Date(shipment.nextRecurrenceOn), 'MMMM dd, yyyy')}
            </p>
          </div>

          <div className='shipment-btns-wrapper'>
            <button onClick={() => onReschedule()} className='btn btn-secondary'>
              Reschedule
            </button>

            <button onClick={() => onShipNow()} className='btn btn-primary'>
              Ship now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
