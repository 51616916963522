import { getCustomer, licenseExistsByCustomer } from 'client/dist/generated/alloy';
import { RootState } from 'reducers';
import { Dispatch } from 'redux';
import { mutate } from 'swr';

import { postIdentification } from 'actions/core/app_actions';
import { CUSTOMER, IS_AUTHENTICATED, LICENSE_EXISTS } from 'actions/types';
import { handleNextPath } from 'lib/checkout-experience/handlePath';

import { AppLocation } from 'components';

import { localSubmissionExists } from 'lib/checkout-experience/authentication/localSubmission';
import { validateCategories } from 'lib/checkout-experience/validation';
import { signIn } from 'lib/core/awsAuth';
import { sendExceptionToSentry } from 'lib/tracking/sentry';

export const login = (email: string, password: string, history: any, location: AppLocation) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      let submissionId: string | undefined;

      const searchParams = new URLSearchParams(location.search);

      // check for search params to have both categories and checkout experience (under next path)
      // to understand whether we should be handling an update on the submission id for customer
      const hasCategories = searchParams.has('categories[]');
      const hasCheckoutExperience =
        searchParams.has('nextPath') && searchParams.get('nextPath') === 'checkout-experience';

      /**
       * check whether the customer is on a next path to continue in checkout experience,
       * perhaps they needed to login mid flow or something like that!
       */
      if (hasCategories && hasCheckoutExperience) {
        // we need the categories in order to validate against the local customer data
        // that way we know whether or not we need to update said submission in local data so that
        // if the customer is logging in mid flow, we do not put them at the start of the intake again

        // validation for our flow categories, split is since search params are a string (break into array)
        const categories = hasCategories
          ? validateCategories(searchParams.get('categories[]')!.split(','))
          : [];

        const { localPreCustomer } = getState().experience;

        // check whether the submission exists and if it does then attach for updating customer
        const submissionExists = localSubmissionExists(categories, localPreCustomer);

        if (submissionExists) {
          submissionId = localPreCustomer.alloySubmissionId;
        }
      }

      // lgray - this clears the local useswr cache
      await mutate(() => true, undefined, false);

      // sign the customer in through auth system
      await signIn(email, password);

      // fetch the customer from our db
      const customer = await getCustomer();

      // handle any post identification work for the customer
      await postIdentification(customer, submissionId);

      // fetch whether their license exists from before or not
      const licenseExists = await licenseExistsByCustomer();

      dispatch({ type: LICENSE_EXISTS, payload: licenseExists });
      dispatch({ type: CUSTOMER, payload: customer });
      dispatch({ type: IS_AUTHENTICATED, payload: true });

      handleNextPath(dispatch, history, location);
    } catch (error) {
      sendExceptionToSentry(error as Error);
      throw error;
    }
  };
};
