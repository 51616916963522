import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

/**
 * Shim that reports react-router changes to posthog. Needs to sit directly under
 * BrowserRouter so it has access to the useLocation hook data.
 *
 * https://posthog.com/tutorials/single-page-app-pageviews
 */
const PosthogShim: React.FC = ({ children }) => {
  const location = useLocation();
  const posthog = usePostHog();
  useEffect(() => {
    posthog?.capture('$pageview');
  }, [posthog, location]);

  return <>{children}</>;
};

export default PosthogShim;
