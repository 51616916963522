import DoctorIcon from 'assets/svg/checkout-experience/product/Doctor.svg';
import InfiniteIcon from 'assets/svg/checkout-experience/product/Infinite.svg';
import MoleculesIcon from 'assets/svg/checkout-experience/product/Molecules.svg';
import ShippingIcon from 'assets/svg/checkout-experience/product/Shipping.svg';
import { retrieveCategoriesFromUrl } from 'lib/shared/experience';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export default function SinglePageProductPerksWrapper() {
  const location = useLocation();
  const categories = retrieveCategoriesFromUrl(location);
  const isSynbioticOnly = categories.every((c) => c === 'gut-health');
  const defaultPerks = [
    {
      icon: MoleculesIcon,
      text: 'Science-backed ingredients',
    },
    {
      icon: ShippingIcon,
      text: 'Fast, Free Shipping',
    },
    {
      icon: InfiniteIcon,
      text: 'Ongoing care and education',
    },
  ];
  const [perks, setPerks] = useState(defaultPerks);

  useEffect(() => {
    if (!isSynbioticOnly) {
      setPerks([
        {
          icon: DoctorIcon,
          text: 'Doctor-led care',
        },
        ...defaultPerks,
      ]);
    }
  }, [isSynbioticOnly]);

  return (
    <div className='ce-single-page-product-perks-wrapper'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8 offset-md-2'>
            <div className='perks-list'>
              {perks.map((perk, i) => (
                <div key={`perk-item-${i}`} className='product-perk'>
                  <img className='icon' src={perk.icon} alt={perk.text} />
                  <span className='perk-text'>{perk.text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
