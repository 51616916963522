import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { bindActionCreators } from 'redux';

import { updateRequestingProducts } from 'actions/checkout-experience/requesting_actions';
import { filterProductsByCategories, getProductIdsFromGroupedProducts } from 'lib/shared/product';

import EditDrawer from '../review/EditDrawer';

import { retrieveCategoriesFromUrl } from 'lib/shared/experience';

import { useAppSelector } from 'reducers/alloy_reducer';
import { uniq } from 'lodash';

interface Props {
  topImg: string;
  title: string;
  subtitle: string;
}

export default function DoctorConsultViewableProduct({ topImg, title, subtitle }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const categories = retrieveCategoriesFromUrl(location);

  const requestingProductIds = useAppSelector((state) => state.experience.requestingProductIds);

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [mhtProducts, setMhtProducts] = useState<GroupedContentfulProduct[][]>([]);
  const [upsellProducts, setUpsellProducts] = useState<GroupedContentfulProduct[][]>([]);
  const [requestedProducts, setRequestedProducts] = useState<GroupedContentfulProduct[]>([]);

  const dispatchUpdateRequesting = bindActionCreators(updateRequestingProducts, dispatch);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setIsLoading(true);

    const [fetchedRequested, fetchedMht, fetchedUpsell] = await Promise.all([
      ProductRegistry.get().getIndividualGroupedContentfulProductsFor(
        'specific',
        requestingProductIds
      ),
      ProductRegistry.get().getIndividualGroupedContentfulProductsFor('mht'),
      ProductRegistry.get().getIndividualGroupedContentfulProductsFor('upsell'),
    ]);

    // at this point, the customer has already filled out the intake, we need to only show them
    // non mht products that would line up with the categories filled out otherwise
    // we don't have submission data on upsell products not selected
    // we also want gut health no matter what
    const filteredUpsellProducts = filterProductsByCategories(
      fetchedUpsell,
      uniq(categories.concat('gut-health'))
    );

    setRequestedProducts(fetchedRequested.flat());
    setMhtProducts(fetchedMht);
    setUpsellProducts(filteredUpsellProducts);

    setIsLoading(false);
  };

  const onSaveRequesting = (products: GroupedContentfulProduct[][]) => {
    const requestedProducts = ProductRegistry.get().sortGroupedContentfulProducts(products);

    setRequestedProducts(requestedProducts.flat());

    dispatchUpdateRequesting(getProductIdsFromGroupedProducts(requestedProducts.flat()));

    setIsEditing(false);
  };

  return (
    <div>
      {!isLoading && (
        <div className='text-left text-md-right mb-3'>
          <button className='secondary-border-button' onClick={() => setIsEditing(true)}>
            Edit selections
          </button>
        </div>
      )}

      <div className='doctor-consult-viewable-product'>
        <div className='product-img-wrapper'>
          <img src={topImg} alt='product' className='product-img' />
        </div>

        <div className='product-content-wrapper'>
          <p className='product-title'>{title}</p>
          <p className='product-subtitle'>{subtitle}</p>

          {requestedProducts.length !== 0 && (
            <>
              <p className='product-text'>Requested Treatments</p>

              <ul className='product-list-wrapper'>
                {requestedProducts.map((gcp, index) => (
                  <li className='list-text' key={index}>
                    {gcp.contentfulProduct.fields.title}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>

      {!isLoading && (
        <EditDrawer
          open={isEditing}
          setOpen={setIsEditing}
          onSave={onSaveRequesting}
          productsWithType={{
            mht: mhtProducts,
            upsell: upsellProducts,
          }}
          prevSelectedProducts={requestedProducts}
        />
      )}
    </div>
  );
}
