import { Redirect, Route, useLocation } from 'react-router-dom';
import { useAppSelector } from 'reducers/alloy_reducer';

interface Props {
  comp: any;
  path: string;
  exact?: boolean;
}

const AuthRoute = ({ comp: Component, path, exact, ...rest }: Props) => {
  const location = useLocation();

  const isAuthenticated = useAppSelector((state) => state.alloy.isAuthenticated);
  const customer = useAppSelector((state) => state.alloy.customer);

  if (isAuthenticated === false || typeof customer === null) {
    return <Route exact {...rest} render={(props) => <Component {...props} />} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: '/',
          search: location.search,
        }}
      />
    );
  }
};

const PrivateRoute = ({ comp: Component, path, exact, ...rest }: Props) => {
  const location = useLocation();

  const isAuthenticated = useAppSelector((state) => state.alloy.isAuthenticated);
  const customer = useAppSelector((state) => state.alloy.customer);

  if (isAuthenticated === false || typeof customer === null) {
    return (
      <Route
        exact
        {...rest}
        render={(props) => {
          const searchParams = new URLSearchParams(location.search);

          if (path !== '/') {
            searchParams.set('nextPath', location.pathname.replace('/', ''));
          }

          return (
            <Redirect
              to={{
                pathname: '/login',
                search: searchParams.toString(),
              }}
            />
          );
        }}
      />
    );
  } else {
    return <Route exact {...rest} render={(props) => <Component {...props} />} />;
  }
};

const PublicRoute = ({ comp: Component, path, exact, ...rest }: Props) => {
  return <Route exact {...rest} render={(props) => <Component {...props} />} />;
};

export { AuthRoute, PrivateRoute, PublicRoute };
