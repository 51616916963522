import {
  SubscriptionWithRenewal,
  applyPromoCode,
  removePromoCode,
  startRenewalAlert,
  useGetAllSubscriptionsForCustomer,
  useIsProcessing,
} from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { updateToNewCheckoutExperienceFlow } from 'actions/checkout-experience/flow_actions';

import SectionLoader from 'components/core/Loader';
import { showDashboardAlert } from 'components/core/Notification';
import SingleShipmentBlock from 'components/dashboard/shipments/SingleShipmentBlock';
import DashboardContainer from 'containers/dashboard/DashboardContainer';

import { isAllPrescriptionsActive, isSomeNeedRenew } from 'lib/dashboard/prescription';
import { getExpiringPrescriptionsFromShipment } from 'lib/dashboard/subscription';

export default function SingleShipment() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ id?: string }>();

  const [loading, setLoading] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<SubscriptionWithRenewal | null>(null);

  const {
    data: subscriptions = [],
    isLoading: isLoadingSubscriptions,
    mutate: mutateSubscriptions,
  } = useGetAllSubscriptionsForCustomer();
  const { data: isProcessing, mutate: mutateIsProcessing, error } = useIsProcessing(params.id!!);

  const mutate = async () => await Promise.all([mutateSubscriptions(), mutateIsProcessing()]);

  const dispatchUpdateToNewCheckoutExperience = bindActionCreators(
    updateToNewCheckoutExperienceFlow,
    dispatch
  );

  useEffect(() => {
    setLoading(true);
    refreshSubscriptionSearch();
  }, [JSON.stringify(subscriptions), isLoadingSubscriptions]);

  useEffect(() => {
    if (isProcessing || error) {
      history.replace('/shipments/upcoming');
    }
  }, [isProcessing, error]);

  const refreshSubscriptionSearch = () => {
    if (params.id) {
      let subscriptionId = params.id;

      const shipmentFound = subscriptions.find(
        (item) => item.stripeSubscriptionId === subscriptionId
      );

      if (shipmentFound) {
        if (shipmentFound.status === 'ACTIVE') {
          const allPrescriptionsActive = isAllPrescriptionsActive(shipmentFound);
          const somePrescriptionsNeedRenewal = isSomeNeedRenew(shipmentFound);

          if (!allPrescriptionsActive || somePrescriptionsNeedRenewal) {
            const productFrequencies = getExpiringPrescriptionsFromShipment(shipmentFound);

            const productNames = productFrequencies.map((pf) => pf.name);

            startRenewalAlert({ productNames });

            dispatchUpdateToNewCheckoutExperience(['renewal'], history);
          } else {
            setSubscription(shipmentFound);
            setLoading(false);
          }
        } else {
          history.replace('/shipments/upcoming');
        }
      }
    }
  };

  const onApplyDiscount = async (code: string) => {
    if (subscription) {
      await applyPromoCode(subscription.stripeSubscriptionId, code);

      await mutate();

      showDashboardAlert('Promo code successfully applied to the next shipment', 'success');
    }
  };

  const onRemoveDiscount = async () => {
    if (subscription) {
      await removePromoCode(subscription.stripeSubscriptionId);
      await mutate();

      showDashboardAlert('Promo code successfully removed from the next shipment', 'success');
    }
  };

  return (
    <DashboardContainer
      title='Upcoming Shipment | Alloy'
      desc='Upcoming shipment details and management'
      currentPage='shipments'
    >
      {loading ? (
        <SectionLoader />
      ) : (
        <>
          {subscription && (
            <section className='dashboard-section light-grey-bg'>
              <div className='container dashboard-page-container'>
                <SingleShipmentBlock
                  shipment={subscription}
                  onApplyDiscount={onApplyDiscount}
                  onRemoveDiscount={onRemoveDiscount}
                />
              </div>
            </section>
          )}
        </>
      )}
    </DashboardContainer>
  );
}
