import { StripePaymentMethod } from 'client/dist/generated/alloy';
import TPWidget from 'components/core/TPWidget';
import { useAppSelector } from 'reducers/alloy_reducer';
import AccountWrapper from '../layout/AccountWrapper';
import BlurbWrapper from '../layout/BlurbWrapper';
import PaymentWrapper from '../layout/PaymentWrapper';
import ShippingWrapper from '../layout/ShippingWrapper';
import SummaryWrapper from '../layout/SummaryWrapper';

interface Props {
  onPlaceOrder: () => void;
  onSelectPaymentMethod: (pm: StripePaymentMethod | null, isAllInputsComplete: boolean) => void;
  isPaymentError: boolean;
  disableContinue: boolean;
}

export default function SingleStepWrapper({
  onPlaceOrder,
  onSelectPaymentMethod,
  isPaymentError,
  disableContinue,
}: Props) {
  const { isEditingShipping } = useAppSelector((state) => state.experience);

  return (
    <>
      <BlurbWrapper />
      <AccountWrapper />
      <ShippingWrapper />

      {!isEditingShipping && (
        <>
          <PaymentWrapper onSelectPaymentMethod={onSelectPaymentMethod} isError={isPaymentError} />

          <SummaryWrapper onPlaceOrder={onPlaceOrder} shouldDisableContinue={disableContinue} />
        </>
      )}

      <div className='d-md-none'>
        <TPWidget
          withBackground
          title='95% of women'
          text='will see improvement in symptoms within 2 weeks of starting treatment'
          theme='dark'
        />
      </div>
    </>
  );
}
