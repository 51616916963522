import { AppHistory } from 'components';
import { isStepValidated } from 'lib/checkout-experience/validation';
import { formatExperienceURL } from 'lib/core/url';
import { getBuiltFlowFromCategories } from 'lib/request-experience/flow';

import { ExperienceCategory } from 'common/dist/models/experience';

import { createCart } from 'actions/checkout-experience/cart_actions';
import { sendExceptionToSentry } from 'lib/tracking/sentry';

/**
 * for our request experience, this function handles building out that url and throwing the user into the right place
 * it is different from checkout experience in terms of what steps go where and how it doesn't use all of the
 * same validation as so. the url is also different and we need to be proper about that and which components to call.
 *
 * @param url string
 * @param categories ExperienceCategory[]
 * @param history AppHistory
 * @returns void | redirects user to correct page within flow
 */
const buildRequestExperience = (
  url: string,
  categories: ExperienceCategory[],
  productIds: number[],
  history: AppHistory
) => {
  return async (dispatch: any) => {
    try {
      const selectedFlow = getBuiltFlowFromCategories(categories);

      const splitUrl = url.split('/').filter((s) => s !== '');

      // v1 have no check for submission exists just throw them in there to take assessment, maybe
      // we could just make a func to check if submission exists in 24 hr so refreshing doesn't take them back to intake
      // if they are on checkout page...
      let submissionExists: boolean = false;

      // const intakeCategories = getIntakeCategories(categories);

      // submissionExists = await submissionByCategoriesExists({ categories: intakeCategories, timeAmount: 2, timeUnit: 'week' });

      let stepIndex =
        selectedFlow.steps.findIndex(
          (sf) => !isStepValidated(sf.validationKey, submissionExists, categories)
        ) ?? 0;

      // /**
      //  * Here we take the end of the url (intake, register, etc) and we check if the user can
      //  * go to that page based on path
      //  */

      if (splitUrl.length >= 2) {
        if (!selectedFlow.steps.some((step) => splitUrl.includes(step.path))) {
          history.push('/404');
          return;
        }

        let nextNeededStepIndex =
          selectedFlow.steps.findIndex(
            (sf) => !isStepValidated(sf.validationKey, submissionExists, categories)
          ) ?? 0;

        stepIndex = selectedFlow.steps.findIndex((step) => splitUrl.includes(step.path)) ?? 0;

        /**
         * We are checking if the next needed step that requires data to be filled it out
         * is before the page the user tried going to. If it is, then we redirect them there!
         */
        if (nextNeededStepIndex < stepIndex) {
          stepIndex = nextNeededStepIndex;
        }
      }

      const path = selectedFlow.steps[stepIndex].path;
      const experienceUrl = formatExperienceURL(
        `/request-experience/${path}`,
        window.location,
        categories
      );

      // search params used for validation of mht request experience
      const searchParams = new URLSearchParams(window.location.search);

      // valid products used for any flow that has restricted products ie skin-health, etc
      const validatedProductIds =
        productIds.length !== 0 && productIds.every((pid) => selectedFlow.productIds.includes(pid));

      // validate products only for flows that require product ids which would be everything but
      // mht since mht has so many various products ids it would be a mess to maintain
      if (!validatedProductIds && selectedFlow.productIds.length !== 0) {
        history.replace('/');
        return;
      }

      // for mht we do not care for product ids, instead we need to know what product the customer had and which one
      // they potentially want
      if (
        selectedFlow.productIds.length === 0 &&
        (!searchParams.has('currentMhtPfIds[]') ||
          !searchParams.has('requestedMhtPfIds[]') ||
          !searchParams.has('checkoutType'))
      ) {
        history.replace('/');
        return;
      }

      // only create a cart for non mht flow since mht we create the cart before going in
      if (!categories.every((c) => c === 'mht')) {
        await dispatch(createCart(productIds, categories, submissionExists));
      }

      history.replace(experienceUrl);
    } catch (error) {
      sendExceptionToSentry(error as Error);
    }
  };
};

export { buildRequestExperience };
