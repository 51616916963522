import { Dispatch } from 'redux';

import { REQUESTING_PRODUCTS } from 'actions/types';
import { getItem, setItem } from 'client/dist/localstorage';

const get = () => {
  return (dispatch: Dispatch) => {
    const requestingProductIds = getItem('requestingProductIds', true);

    dispatch({
      type: REQUESTING_PRODUCTS,
      payload: requestingProductIds,
    });
  };
};

const update = (productIds: number[]) => {
  return (dispatch: Dispatch) => {
    setItem('requestingProductIds', productIds, true);

    dispatch({
      type: REQUESTING_PRODUCTS,
      payload: productIds,
    });
  };
};

export { get as getRequestingProducts, update as updateRequestingProducts };
