import { useAppSelector } from 'reducers/alloy_reducer';

export default function Banner() {
  const topAlertBanner = useAppSelector((state) => state.alloy.topAlertBanner);

  if (!topAlertBanner || !topAlertBanner.fields.isDashboardOn) return <></>;

  return (
    <section className='banner-section'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 text-center'>
            <p className='banner-text'>{topAlertBanner.fields.dashboardText}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
