import { useHistory, useLocation } from 'react-router-dom';

import { StandaloneVerificationProps } from 'models/alloy/experience';

import Verification from 'pages/checkout-experience/shared/auth/Verification';
import { useDispatch } from 'react-redux';
import { handleNextPath } from 'lib/checkout-experience/handlePath';

export default function StandaloneVerification() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const state = location.state as StandaloneVerificationProps | undefined;

  return (
    <Verification
      onNext={() => handleNextPath(dispatch, history, location)}
      onBack={() => history.goBack()}
      standaloneVerificationParams={state}
    />
  );
}
