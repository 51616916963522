import { SubscriptionStatus } from 'client/dist/generated/alloy';
import { format } from 'date-fns';
import { ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { updateToNewCheckoutExperienceFlow } from 'actions/checkout-experience/flow_actions';

import arrowRightIcon from 'assets/svg/core/arrows/arrow-right.svg';

import ProductInfoDrawer from 'components/core/drawers/ProductInfoDrawer';

import { RecurrenceType } from 'common/dist/products/productFrequency';
import ProductNameDosage from 'components/checkout-experience/products/ProductNameDosage';
import RequestDrawer from 'components/dashboard/request-mht/drawers/RequestDrawer';
import { getProductNameWithDosage } from 'lib/shared/product/productNameWithDosage';
import { DeepProduct } from 'models/alloy/product';

interface Props {
  product: DeepProduct;
  nextRecurrenceOn?: string;
  subStatus?: SubscriptionStatus;
  isProcessing?: boolean;
  showDosage?: boolean;
  children?: ReactNode;
}

export default function ActiveProduct({
  product,
  nextRecurrenceOn,
  subStatus = 'INACTIVE',
  isProcessing = false,
  showDosage = true,
  children,
}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  // TODO: This is a flag for allowing mht change, we can remove once deployed +1 week
  const ALLOW_MHT_CHANGE =
    process.env.REACT_APP_ALLOW_MHT_CHANGE && process.env.REACT_APP_ALLOW_MHT_CHANGE === 'true';

  const { prescription, alloyProduct, contentfulProduct, renewal: renewalInfo } = product;

  const [isProdInfoOpen, setIsProdInfoOpen] = useState(false);
  const [isRequestOpen, setIsRequestOpen] = useState(false);

  const dispatchUpdateToNewCheckoutExperience = bindActionCreators(
    updateToNewCheckoutExperienceFlow,
    dispatch
  );

  const openProdInfo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsProdInfoOpen(true);
  };

  const openRequest = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsRequestOpen(true);
  };

  const onRenew = () => {
    dispatchUpdateToNewCheckoutExperience(['renewal'], history);
  };

  const productsCleanNameAndDosage = showDosage
    ? getProductNameWithDosage(product)
    : [{ cleanName: contentfulProduct.fields.title }];
  /**
   * Variables used to identify items to show for renewal, one time product, and rx
   */
  const isSynbioticProduct = alloyProduct.parent.every((pf) => pf.category === 'gut-health');
  const isMhtProduct = alloyProduct.parent.every(
    (pf) => pf.recurrenceType === RecurrenceType.RECURRING && pf.category === 'mht'
  );
  const needsRenewed = renewalInfo?.needsRenewed ?? false;
  const renewalCompletedDate = !!renewalInfo?.completedDate ?? false;
  const isOneTimeProduct = alloyProduct.parent.every((pf) => pf.recurrenceType === 'ONE_TIME');
  const isRx = alloyProduct.parent.every((pf) => pf.type === 'RX');

  const showMhtChangeAction = isMhtProduct && !needsRenewed && !isProcessing && ALLOW_MHT_CHANGE;

  const showFills = !isSynbioticProduct && !isProcessing;
  const showNextShipment =
    subStatus === 'ACTIVE' && !needsRenewed && nextRecurrenceOn && !isProcessing;
  const showExpiration =
    (needsRenewed || isOneTimeProduct) && !renewalInfo?.hasExpired && !renewalCompletedDate;

  return (
    <div className='active-product'>
      {isRx && <span className='product-rx-tag'>Rx</span>}

      <div className='product-header'>
        <div className='header-content'>
          <img
            src={contentfulProduct?.fields.photo.fields.file.url}
            alt={contentfulProduct?.fields.photo.fields.title}
            className='header-img'
          />

          <div className='header-text-wrapper'>
            {productsCleanNameAndDosage.map(({ cleanName, dosage }, index) => (
              <ProductNameDosage key={index} cleanName={cleanName} dosage={dosage} />
            ))}

            <p className='header-text mt-2'>
              {showFills && (
                <span>
                  {prescription?.fillsRemaining === 0
                    ? 'No refills'
                    : `Refills: ${prescription?.fillsRemaining}`}
                </span>
              )}

              {showNextShipment && (
                <Link to='/shipments/upcoming' className='product-link'>
                  <span className='link-underline'>
                    Next {isSynbioticProduct ? 'shipment' : 'refill'}:{' '}
                    {format(new Date(nextRecurrenceOn), 'MM/dd/yyyy')}
                  </span>

                  <img src={arrowRightIcon} alt='arrow right' className='link-arrow' />
                </Link>
              )}

              {showExpiration && (
                <span className='header-tag'>
                  Expires in {renewalInfo?.daysRemaining} day
                  {renewalInfo?.daysRemaining !== 1 && 's'}
                </span>
              )}

              {renewalCompletedDate && (
                <div className='header-tag d-sm-block d-md-block d-lg-inline d-xl-inline'>
                  Renewal Completed{' '}
                  {format(new Date(renewalInfo?.completedDate as string), 'MM/dd/yy')}
                </div>
              )}

              {renewalInfo?.hasExpired && <span className='header-tag'>Expired</span>}
            </p>

            {!isProcessing && (
              <button className='product-link mt-2' onClick={openProdInfo}>
                <span className='link-underline'>Product information</span>
              </button>
            )}
          </div>
        </div>

        {showMhtChangeAction && (
          <div className='header-action d-none d-md-flex'>
            <button className='product-link' onClick={openRequest}>
              <span className='link-underline'>Request a change</span>
            </button>
          </div>
        )}
      </div>

      {isOneTimeProduct && (
        <>
          <p className='product-special-blurb'>
            Next step: Get an updated mammogram & message your doctor
          </p>

          <Link to='/messages' className='secondary-border-button mt-3'>
            Message your doctor
          </Link>
        </>
      )}

      {needsRenewed && !renewalCompletedDate && !isOneTimeProduct && (
        <button className='primary-button mt-3' onClick={onRenew}>
          Renew your prescription
        </button>
      )}

      {showMhtChangeAction && (
        <div className='header-action d-block d-md-none'>
          <button className='product-link' onClick={openRequest}>
            <span className='link-underline'>Request a change</span>
          </button>
        </div>
      )}

      <div>{children}</div>

      <ProductInfoDrawer
        open={isProdInfoOpen}
        setOpen={setIsProdInfoOpen}
        product={contentfulProduct}
      />

      {ALLOW_MHT_CHANGE && (
        <RequestDrawer open={isRequestOpen} setOpen={setIsRequestOpen} product={product} />
      )}
    </div>
  );
}
