import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import Layout from 'containers/Layout';
import { useHistory } from 'react-router';

export default function DoctorSurveyConfirmation() {
  const history = useHistory();

  const onComplete = () => {
    history.push('/');
  };
  return (
    <Layout title='Confirmation | MyAlloy' desc='' noBars>
      <TopBannerWithProgress />
      <section className='start-section'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-7'>
              <div className='text-center'>
                <h5 className='pb-2 mt-6'>Thank you for taking the time to complete the survey!</h5>
                <p>Your feedback helps us improve future doctor interactions through Alloy.</p>
              </div>
            </div>
          </div>
          <div className='row btn-row'>
            <div className='col-12 text-center mt-4'>
              <button className='btn btn-primary' onClick={onComplete}>
                Back to your dashboard
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
