import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { GetShippingMethods200Item, SubscriptionWithRenewal } from 'client/dist/generated/alloy';
import { format } from 'date-fns';

import { getFormattedDateRange } from 'lib/core/day';
import { getMaxDateForRescheduling } from 'lib/dashboard/prescription';
import { getNumberOfDays, maxDaysFromShipping, minDaysFromShipping } from 'lib/shared/shipping';

interface Props {
  shipment: SubscriptionWithRenewal;
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
  shippingMethod: GetShippingMethods200Item | undefined;
}

export default function CalendarBlock({
  shipment,
  selectedDate,
  setSelectedDate,
  shippingMethod,
}: Props) {
  const maxDate = getMaxDateForRescheduling(shipment);

  return (
    <>
      <p className='reschedule-date-info'>
        Your shipment will arrive {getNumberOfDays(shippingMethod)} after your selected bill date.
      </p>

      <div className='reschedule-calendar'>
        <p className='calendar-title'>
          Next bill date: {selectedDate ? format(selectedDate, 'M/d') : '-'}
        </p>

        <p className='calendar-text'>
          Expected shipment delivery:{' '}
          {selectedDate
            ? getFormattedDateRange(
                selectedDate,
                minDaysFromShipping(shippingMethod),
                maxDaysFromShipping(shippingMethod)
              )
            : '-'}
        </p>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateCalendar
            showDaysOutsideCurrentMonth
            disablePast
            maxDate={maxDate}
            onChange={setSelectedDate}
            focusedView='day'
            views={['day']}
            defaultValue={new Date(shipment.nextRecurrenceOn)}
          />
        </LocalizationProvider>
      </div>
    </>
  );
}
