import { useGetCustomerReferralCreditBalance } from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'reducers/alloy_reducer';
import { bindActionCreators } from 'redux';

import { updateCart } from 'actions/checkout-experience/cart_actions';

import { isConsultCart } from 'lib/checkout-experience/checkout/cart';
import { buildDataForCalculation, calculateTaxes } from 'lib/checkout-experience/checkout/tax';
import { getCartTotals } from 'lib/shared/cart';
import { getPriceForCombinedProducts } from 'lib/shared/product';

/**
 * Component that handles showing the totals and handling the place order button, used within <OrderSummaryBlock />
 */
export default function TotalsBlock() {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { data: referralCreditBalance } = useGetCustomerReferralCreditBalance();

  const cart = useAppSelector((state) => state.experience.alloyCart);
  const customer = useAppSelector((state) => state.alloy.customer!!);

  const isConsult = isConsultCart(cart);

  const { total, subtotal, discount, referralCreditDiscount, tax, shipping } = getCartTotals(cart);

  const { shippingAddressLineOne, city, zip } = customer;
  const isShippingComplete = [shippingAddressLineOne, city, zip].every(
    (val) => val && val !== '' && !val.includes('_')
  );

  const dispatchUpdateCart = bindActionCreators(updateCart, dispatch);

  // Just need to update whenever promotion code is added/removed or shipping
  // is added/updated
  useEffect(() => {
    getTax();
  }, [JSON.stringify(cart.promotionCode), isShippingComplete]);

  useEffect(() => {
    // < 0 b/c the balance comes back as a negative number from stripe.
    // We tack the Abs value when we calculate the discount
    if (referralCreditBalance && referralCreditBalance < 0) {
      dispatchUpdateCart({ referralCreditBalance });
    }
  }, [referralCreditBalance]);

  /**
   * Update the carts tax anytime there is a change to the cart (product select, promo code, shipping, etc)
   */
  const getTax = async () => {
    if (cart.products.length !== 0 && !isConsult && isShippingComplete) {
      setIsLoading(true);

      const productsPriceInCents = cart.products.map((gcp) => getPriceForCombinedProducts(gcp));

      const taxesShipping = buildDataForCalculation(
        discount,
        shipping,
        productsPriceInCents,
        customer
      );

      const taxAmountToCollect = await calculateTaxes(taxesShipping);

      dispatchUpdateCart({
        taxAmountToCollect,
      });

      setIsLoading(false);
    }
  };

  return (
    <div className='ce-totals-block'>
      <div className='ce-total-summary-wrapper'>
        <div className='total-summary-row'>
          <p className='total-summary-title-bold'>
            Subtotal <span>(3-month supply)</span>
          </p>
          <p className='total-summary-content-title'>${subtotal.toFixed(2)}</p>
        </div>

        <div className='total-summary-row'>
          <p className='total-summary-title'>
            Discounts{discount !== 0 ? ` (${cart.promotionCode.name})` : ''}
          </p>
          <p className='total-summary-content'>
            {discount !== 0 ? `-$${discount.toFixed(2)}` : '-'}
          </p>
        </div>

        {referralCreditDiscount > 0 && (
          <div className='total-summary-row'>
            <p className='total-summary-title'>Referral Credits</p>
            <p className='total-summary-content'>-${referralCreditDiscount.toFixed(2)}</p>
          </div>
        )}

        <div className='total-summary-row'>
          <p className='total-summary-title'>Tax</p>
          <p className='total-summary-content-tax'>
            {!isShippingComplete || isLoading ? '-' : isConsult ? '$0.00' : `$${tax.toFixed(2)}`}
          </p>
        </div>

        <div className='total-summary-row'>
          <p className='total-summary-title'>Shipping</p>
          <p className='total-summary-content'>
            {isConsult || isLoading ? '-' : shipping === 0 ? 'FREE' : `$${shipping.toFixed(2)}`}
          </p>
        </div>
      </div>

      <div className='ce-total-wrapper'>
        <p className='total-title'>Order Total</p>
        <p className='total-amount'>${total.toFixed(2)}</p>
      </div>
    </div>
  );
}
