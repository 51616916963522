import {
  getCustomer,
  IntakeCategory,
  processCheckoutV2,
  sendTinuitiEvent,
} from 'client/dist/generated/alloy';
import { Dispatch } from 'redux';

import {
  ALLOY_CART,
  BILLING_ADDRESS,
  CHECKOUT,
  CHECKOUT_EDITING_SHIPPING,
  CUSTOMER,
} from 'actions/types';

import { AlloyCartWithTerms } from 'models/alloy/cart';

import ProductRegistry from 'client/dist/product/productRegistry';
import productRegistry from 'client/dist/product/productRegistry';
import { ExperienceCategory } from 'common/dist/models/experience';
import { getCheckoutType, isConsultCart } from 'lib/checkout-experience/checkout/cart';
import { getProductFrequencyIdsFrom, translateToDomProduct } from 'lib/shared/product';
import { brazeSetPartial } from 'lib/tracking/braze';
import {
  getFromFreshpaintCookie,
  trackCheckoutConfirmationFreshpaint,
} from 'lib/tracking/freshpaint';
import { Address } from 'pages/checkout-experience/shared/Checkout';
import { RootState } from 'reducers';
import { sumBy } from 'lodash';
import { getTinuitiId, getUtms } from 'client/dist/events/tinuiti';
import * as Sentry from '@sentry/react';
import { removeItem } from 'client/dist/localstorage';
import { updateRequestedDosageBasedOnSubmission } from 'lib/checkout-experience/checkout/product';

/**
 *
 * Handles the ability to checkout and fetch recent orders and updates to the user
 *
 * @param cart AlloyCartWithTerms
 * @param checkoutCategories ExperienceCategory[]
 * @param experienceType - checkout is new customer, request is upsell
 *
 * @returns Promise<void>
 */
const processCheckout = (
  cart: AlloyCartWithTerms,
  checkoutCategories: ExperienceCategory[],
  experienceType: 'checkout' | 'request'
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { alloyCart, requestingProductIds, billingAddress } = getState().experience;

    let productFrequencyIds: number[] = [];

    const isConsult = isConsultCart(alloyCart);

    // TODO: Discussion around this below CE category (gut health) | intake category (no gut health)
    const intakeCategories = checkoutCategories.filter(
      (c) => c !== 'gut-health'
    ) as IntakeCategory[];

    // just setup a simple way of understanding which flow the customer is in by throwing
    // in this flag
    const checkoutType =
      experienceType === 'request'
        ? 'CUSTOMER_ADDON_REQUEST'
        : getCheckoutType(cart, intakeCategories);

    // set the product frequencies to send to BE from cart
    // ie consult in cart we would add the consult pf id here,
    // omazing in cart we would add the omazing pf id here, etc
    productFrequencyIds = alloyCart.products.flatMap((gcp) => getProductFrequencyIdsFrom(gcp));

    if (isConsult) {
      // when a customer has some products selected that they want to request, we might need to swap out the dose
      // for tret because they might need a higher one than the default one we have. for this, we just grab the requested
      // products and then pass along into a func that can handle checking whether we should swap the dose out for
      // tret there! if we don't need to then nothing happens basically :)
      const products = await ProductRegistry.get().getRecurringProductsForV2(requestingProductIds);
      const pfs = products.flatMap((gcpList) =>
        gcpList
          .flatMap((gcp) => [...gcp.alloyProduct.parent, ...(gcp.alloyProduct.child || [])])
          .map((commonPf) => translateToDomProduct(commonPf))
      );

      const updatedRequestProductIds = await updateRequestedDosageBasedOnSubmission(
        intakeCategories,
        pfs
      );

      // grab the right pf to send in our payload for requesting products ONLY for consult!
      const requestingProductFrequencyIds = (
        await ProductRegistry.get().getPricesFor(updatedRequestProductIds)
      ).flatMap((pfs) => pfs.flatMap((pf) => pf.id));

      productFrequencyIds = productFrequencyIds.concat(requestingProductFrequencyIds);
    }

    const billingCleaned = {
      line1: billingAddress.shippingAddressLineOne!,
      line2: billingAddress.shippingAddressLineTwo,
      city: billingAddress.city!,
      state: billingAddress.stateAbbr!,
      zip: billingAddress.zip!,
    };

    const fpParams = getFromFreshpaintCookie();

    const checkout = await processCheckoutV2({
      billingAddress: billingCleaned,
      checkoutType,
      productFrequencyIds,
      promotionCodeId: cart.promotionCode.id !== '' ? cart.promotionCode.id : undefined,
      intakeCategories: intakeCategories,
      freshpaintParameters: fpParams,
      shippingMethodId: cart?.shipping?.id,
      ...(experienceType === 'checkout' && {
        crmConsent: {
          marketing: cart.agreement.isOptedSmsMarketing,
        },
      }),
      ...(experienceType === 'request' && {
        shipNow: cart.shipNow,
      }),
    });

    dispatch({ type: CHECKOUT, payload: checkout });
    dispatch({ type: ALLOY_CART, payload: { ...cart, checkoutType } });

    const customer = await getCustomer();

    await brazeSetPartial(customer);
    trackCheckoutConfirmationFreshpaint(
      customer,
      alloyCart,
      checkout,
      checkoutCategories,
      experienceType
    );

    if (experienceType === 'checkout') {
      try {
        const alloyProducts = await productRegistry.get().alloyProducts;

        // already appropriately priced (if bundling applies)
        const productsInScope = productFrequencyIds.map(
          (pf) => alloyProducts.find((ap) => ap.id === pf)!
        );

        const priceInCents = sumBy(productsInScope, (p) => p.priceInCents);

        const utms = getUtms(window.location);
        sendTinuitiEvent({
          event: isConsult ? 'CONSULT_PLACED' : 'ORDER_PLACED',
          prepurchaseIdentifier: getTinuitiId(),
          patientId: customer.patientId,
          ...(utms.utm_campaign ? { utmCampaign: utms.utm_campaign } : {}),
          ...(utms.utm_source ? { utmSource: utms.utm_source } : {}),
          ...(utms.utm_medium ? { utmMedium: utms.utm_medium } : {}),
          orderValueInCents: priceInCents,
          checkoutId: checkout.id,
        }).catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        });
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
      }
    }

    removeItem('localPreCustomer');
    removeItem('requestingProductIds');
    removeItem('recentIntakeAnswers');

    dispatch({ type: CUSTOMER, payload: customer });
    dispatch({ type: ALLOY_CART, payload: { ...alloyCart, isPurchased: true } });
  };
};

const updateIsEditingShipping = (isEditing: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: CHECKOUT_EDITING_SHIPPING, payload: isEditing });
  };
};

const updateBillingAddress = (updatedBilling: Partial<Address>) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const { billingAddress } = getState().experience;

    const collated = {
      ...billingAddress,
      ...updatedBilling,
    };

    dispatch({ type: BILLING_ADDRESS, payload: collated });
  };
};

export { processCheckout, updateBillingAddress, updateIsEditingShipping };
