import { v4 as uuidv4 } from "uuid";
import { getUniversalCookie, setUniversalCookie } from "../cookies";
export const PREPURCHASE_ID = "PREPURCHASE_ID";
export function getUtms(location) {
    const params = new URLSearchParams(location.search);
    return {
        utm_source: params.get("utm_source"),
        utm_campaign: params.get("utm_campaign"),
        utm_content: params.get("utm_content"),
        utm_medium: params.get("utm_medium"),
        utm_term: params.get("utm_term"),
    };
}
/**
 * Tinuiti expects an identifier, but our current prepurchase identifiers arent stable or generalizable enough.
 *
 * Plus this frees tinuiti from any dependencies.
 *
 * Make up an ID for this device. This won't track through an abandon into a new device, but that's okay
 */
export function getTinuitiId() {
    const maybeTinuitiId = getUniversalCookie(PREPURCHASE_ID);
    if (maybeTinuitiId) {
        // TODO remove these after this passes QA
        console.log("Found prepurchase id in cookie storage", maybeTinuitiId);
        return maybeTinuitiId;
    }
    else {
        const tinuitiId = uuidv4();
        console.log("Didnt find prepurchase id in cookie storage, saving now", tinuitiId);
        setUniversalCookie(PREPURCHASE_ID, tinuitiId);
        return tinuitiId;
    }
}
