import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import BundleSelectableProduct from 'components/checkout-experience/products/BundleSelectableProduct';
import SelectableProduct from 'components/checkout-experience/products/SelectableProduct';
import SelectableOptionProduct from '../products/SelectableOptionProduct';
import {
  filterMhtProductsFrom,
  filterNonMhtProductsFrom,
  sortMhtProducts,
} from 'lib/dashboard/treatmentPlan';
import { useEffect, useState } from 'react';
import { getProductToBeBundledWith } from 'lib/shared/product';

interface ListSelectableProductsProps {
  products: GroupedContentfulProduct[][];
  selectedProducts: GroupedContentfulProduct[];
  onSelect: (p: GroupedContentfulProduct) => void;
  onSwitch: (selected: GroupedContentfulProduct, gcp: GroupedContentfulProduct) => void;
  hideDetails?: boolean;
}

export default function ListSelectableProducts({
  products,
  selectedProducts,
  onSelect,
  onSwitch,
  hideDetails = false,
}: ListSelectableProductsProps) {
  // Option products mean they can be used in the optional product select (mht select)
  const optionProducts = sortMhtProducts(filterMhtProductsFrom(products.flat()));

  // Non option products mean they are single card products that can be selected
  const nonOptionsProducts =
    optionProducts.length === 1 ? products : filterNonMhtProductsFrom(products);

  const sortedNonOptionProducts = nonOptionsProducts.sort(
    (a, b) => a[0].contentfulProduct.fields.order - b[0].contentfulProduct.fields.order
  );

  // if the customer has any mht, they will have a 'previously selected' product which is just for the ui! otherwise we would always default to
  // the first element if they unselected the mht card and we don't want that
  const [prevSelectMht, setPrevSelectedMht] = useState<GroupedContentfulProduct | null>(
    optionProducts.length > 0 ? optionProducts[0] : null
  );

  // if select products change then update the previously select product for display
  useEffect(() => {
    const foundMht = selectedProducts.find((gcp) =>
      gcp.alloyProduct.parent.some((pf) => pf.category === 'mht')
    );

    if (foundMht) {
      setPrevSelectedMht(foundMht);
    }
  }, [JSON.stringify(selectedProducts)]);

  const isParentSelected = (parent?: GroupedContentfulProduct) => {
    return (
      !!parent &&
      selectedProducts.some((gcp) =>
        gcp.alloyProduct.parent.every((pf) =>
          parent.alloyProduct.parent.every((parent) => parent.productId === pf.productId)
        )
      )
    );
  };

  return (
    <>
      {optionProducts.length > 1 && prevSelectMht && (
        <SelectableOptionProduct
          groupedProducts={optionProducts}
          prevSelectedProduct={prevSelectMht}
          onSelect={onSelect}
          onSwitch={onSwitch}
          isSelected={selectedProducts.some((gcp) =>
            gcp.alloyProduct.parent.some((pf) => pf.category === 'mht')
          )}
        />
      )}

      {sortedNonOptionProducts.map((gcpList, listIndex) => {
        return gcpList.map((gcp, index) => {
          const isSelected = selectedProducts.some(
            (selected) => selected.contentfulProduct.sys.id === gcp.contentfulProduct.sys.id
          );
          const parent = getProductToBeBundledWith(gcp, sortedNonOptionProducts);

          return !!parent ? (
            <BundleSelectableProduct
              key={`${listIndex}-${index}`}
              groupedProduct={gcp}
              isSelected={isSelected}
              onSelect={() => onSelect(gcp)}
              showDosage
              isParentSelected={isParentSelected(parent)}
              parent={parent}
              hideDetails
            />
          ) : (
            <SelectableProduct
              key={`${listIndex}-${index}`}
              groupedProduct={gcp}
              isSelected={selectedProducts.some(
                (selected) => selected.contentfulProduct.sys.id === gcp.contentfulProduct.sys.id
              )}
              onSelect={() => onSelect(gcp)}
              showDosage
              multiSelect
              hideDetails
            />
          );
        });
      })}
    </>
  );
}
