import Layout from 'containers/Layout';

export default function NotFound() {
  return (
    <Layout
      title='Not Found | Alloy'
      desc='Uh oh, it looks you went to a page that does not exist!'
    >
      <section className='intro-section bg-primary'>
        <div className='container align-items-center'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-8'>
              <h1 className='mt-5 font-weight-light'>
                Page not <span className='font-weight-bolder text-dark'>found</span>
              </h1>
              <p className='lead my-4'>
                Oops! Looks like you followed a bad link. If you think this is a problem with us,
                please let us know.
              </p>
              <a className='btn btn-primary' href='/'>
                Go home
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
