import {
  IntakeCategory,
  ProductAndFrequency,
  SuggestionProduct,
  runSuggestions,
} from 'client/dist/generated/alloy';
import {
  TRETINOIN_03_ID,
  TRETINOIN_06_ID,
  TRETINOIN_09_ID,
} from 'common/dist/products/productRegistry';
import suggestionsClient from './suggestionsClient';

const TRETINOIN_PRODUCT_IDS = [TRETINOIN_03_ID, TRETINOIN_06_ID, TRETINOIN_09_ID];

// TODO: Later with other versions of diff doses potentially of other products, will need to update this.
// for now since this is what is in prod, moved this into a func and added some tests to confirm working
// as expected

/**
 * for customers in consult, we need to clean some products such as tret and pass the right pf id to process
 * for checkout. this function checks recent submission and swaps out tret for the right dosage from
 * our suggestions
 *
 * @param intakeCategories IntakeCategory[]
 * @param requestingProducts GroupedContentfulProduct[]
 * @returns ProductAndFrequency['id'][]
 */
export const updateRequestedDosageBasedOnSubmission = async (
  intakeCategories: IntakeCategory[],
  requestingProducts: ProductAndFrequency[]
): Promise<ProductAndFrequency['productId'][]> => {
  const foundRequestedTret = requestingProducts.find((pf) =>
    TRETINOIN_PRODUCT_IDS.some((pid) => pf.productId === pid)
  );

  // if we are not in skin health for consult or no tret is found then we just get outta here right away
  if (!intakeCategories.includes('skin-health') || !foundRequestedTret) {
    return requestingProducts.map((pf) => pf.productId);
  }

  // send the user's submission to get recommended tret dosage based on submission answers
  const suggestedTret = await getRecommendedTretinoinDosage(intakeCategories);

  // if we found a recommended dosage, reset requesting products to exclude the original
  // otherwise, just use the original requesting products
  const requestingProductsWithoutTretinoin = suggestedTret?.product.id
    ? requestingProducts.filter((pf) => foundRequestedTret.id !== pf.id)
    : requestingProducts;

  return [
    ...requestingProductsWithoutTretinoin.map((pf) => pf.productId),
    // splice in the recommended dosage product id here - if it wasn't found, do nothing
    ...(suggestedTret ? [suggestedTret.product.productId] : []),
  ];
};

const getRecommendedTretinoinDosage = async (
  categories: IntakeCategory[]
): Promise<SuggestionProduct | undefined> => {
  const suggestions = await suggestionsClient.runSuggestionsByRecentSubmission(categories);

  return suggestions.qualified.find((q) => TRETINOIN_PRODUCT_IDS.includes(q.product.productId));
};
