import posthog from 'posthog-js';

export const shouldAddExpectationsStep = () => {
  const addExpectations = posthog.getFeatureFlag('top-of-funnel-multivariate');

  console.log('Retrieved result of top-of-funnel-multivariate is ' + addExpectations);

  if (!addExpectations) {
    console.warn('Couldnt determine status of add expectations ab test. Will never show variant');
  }
  return typeof addExpectations === 'string'
    ? addExpectations.indexOf('expectations') > -1
    : addExpectations;
};
