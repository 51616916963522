import { ReactNode } from 'react';

interface Props {
  title?: String;
  fancyTitle?: String;
  desc?: ReactNode;
}

export default function TitleBlock({ title, fancyTitle, desc }: Props) {
  return (
    <div className='row dashboard-title-block-wrapper'>
      <div className='col-12'>
        <h1 className='dashboard-section-title'>
          {title}
          {fancyTitle && (
            <>
              <br />
              <span>{fancyTitle}</span>
            </>
          )}
        </h1>
      </div>

      {desc && (
        <div className='col-12 col-md-6'>
          <p className='dashboard-page-text'>{desc}</p>
        </div>
      )}
    </div>
  );
}
