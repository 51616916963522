import { ProductFrequencyRenewal } from 'client/dist/generated/alloy';
import { RecurrenceType, ProductAndFrequency } from 'common/dist/products/productFrequency';
import {
  ESTRADIOL_GEL_1_PRODUCT_ID,
  ESTRADIOL_05_PATCH_PRODUCT_ID,
  ESTRADIOL_PILL_1_PRODUCT_ID,
  EVAMIST_PRODUCT_ID,
  PROGESTERONE_100_PRODUCT_ID,
} from 'common/dist/products/productRegistry';
import { sub } from 'date-fns';
import { maxBy } from 'lodash';

export type ProductFrequenciesWithHasPrescription = {
  productFrequencies: ProductAndFrequency[];
  hasPrescription: boolean;
};

/**
 * For mht form factor change, we need to display mht products that a customer could either have a prescription
 * for or they may not. we handle the default products initially and then if we find pfs in their prescriptions with
 * different dosage we overwrite those as the ones to display. as for if they don't have it then we just display the normal
 * dosage for them to select.
 *
 * ex:
 * * - customer has pill 1mg and prog 100 in a subscription
 * * - in their prescriptions they have patch 0.1mg
 * * - after going through this func, it would return the following:
 * * --- patch 0.1mg & prog with has prescription = true
 * * --- evamist & prog with has prescription = false
 * * --- estradiol gel & prog with has prescription = true
 * then we can use these to display as needed!
 *
 * @param requestingChangePF ProductAndFrequency
 * @param childrenPfs ProductAndFrequency[]
 * @param activePrescriptions ProductFrequencyRenewal[]
 * @param alloyProducts ProductAndFrequency[]
 * @returns ProductFrequenciesWithHasPrescription[]
 */
export const getFilteredMhtProducts = async (
  requestingChangePF: ProductAndFrequency,
  childrenPfs: ProductAndFrequency[],
  activePrescriptions: ProductFrequencyRenewal[],
  alloyProducts: ProductAndFrequency[]
): Promise<ProductFrequenciesWithHasPrescription[]> => {
  const DEFAULT_PRODUCT_IDS = [
    ESTRADIOL_PILL_1_PRODUCT_ID,
    ESTRADIOL_05_PATCH_PRODUCT_ID,
    EVAMIST_PRODUCT_ID,
    ESTRADIOL_GEL_1_PRODUCT_ID,
  ];

  // filter the products and get the defaulted versions
  const defaultProductFrequencies = alloyProducts.filter(
    (pf) =>
      DEFAULT_PRODUCT_IDS.includes(pf.productId) && pf.recurrenceType === RecurrenceType.RECURRING
  );

  // filter the defaulted versions by removing the product the customer wants to request to change,
  // doing by cleanName since there could be different doses/versions of the same mht product
  // and also we only want parent products here
  const filteredProductFrequencies = defaultProductFrequencies.filter(
    (pf) => pf.cleanName !== requestingChangePF.cleanName
  );

  return filteredProductFrequencies.map((pf) => {
    // find any prescriptions that are equal to the clean name (same version of a pf [pill, patch, etc])
    // that way we can get the most recent version (no matter dosage) and display that accordingly
    const filteredByPf = activePrescriptions.filter(
      (pfr) => pfr.product.cleanName === pf.cleanName && !!pfr.prescription
    );

    // grab the most recently updated pf to display to the customer
    const mostRecentPf = maxBy(filteredByPf, (pfr) => pfr.prescription?.createdAt);

    const sixMonthsBeforeNow = sub(new Date(), { months: 6 });

    // customer has prescription which is used to display different UI (since they have it)
    // checking for prescription includes:
    // - does it exist
    // - was it created within the last 6 months
    // - does it have at least 2 fills remaining
    // with this we can determine whether to allow them to swap right away or create an encounter
    // with a doctor!
    const hasPrescription = filteredByPf.some(
      (pf) =>
        !!pf.prescription &&
        sixMonthsBeforeNow <= new Date(pf.prescription.createdAt) &&
        pf.prescription.fillsRemaining >= 2
    );

    // create the pfs to get converted into grouped contentful products later to help with display!
    const productFrequencies = [!!mostRecentPf ? mostRecentPf.product : pf].concat(childrenPfs);

    return {
      productFrequencies: productFrequencies.map((pf) => ({
        ...pf,
        createdAt: new Date(pf.createdAt),
        updatedAt: new Date(pf.updatedAt),
        recurrenceType: pf.recurrenceType as RecurrenceType,
      })),
      hasPrescription,
    };
  });
};
