import { RecurrenceType } from 'common/dist/products/productFrequency';
import ProductRegistry from 'client/dist/product/productRegistry';

/**
 * the FE mainly uses this to get the right product price, this allows for the cart/product cards to display the right bundled/unbundled price when needed.
 * mainly used for bundling since we have the other prices correctly
 *
 * @param productId number
 * @param recurrenceType one time or recurring
 * @param isBundled boolean
 * @returns number
 */
export const getProductPrice = async (
  productId: number,
  recurrenceType: RecurrenceType = RecurrenceType.RECURRING,
  isBundled: boolean = false
): Promise<number> => {
  return (
    await ProductRegistry.get().getProductFrequencyByRecurrence(
      productId,
      recurrenceType,
      isBundled
    )
  ).priceInCents;
};
